import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { Button } from 'ui/atoms/Button'
import { ArchiveIcon, PlusIcon } from 'ui/icons'
import { Pagination, Typography } from '@frontend/design_system'
import { Table } from 'ui/molecules/Table'
import { MENU_NAMES } from 'texts/menuNames'
import { profileApi, ProfileApiTypes } from 'api'
import { ActionItems } from 'ui/atoms/ActionItems'
import { useAppDispatch } from 'redux/hooks'
import { Autocomplete } from 'ui/atoms/Autocomplete'
import { AutocompleteItem } from 'ui/atoms/Autocomplete/types'
import { Container } from 'ui/molecules/Container'
import { Confirmation } from 'ui/molecules/Confirmation'
import { ErrorResponse } from 'api/types'
import { headItems } from './constants'
import { PROFILE_LIST, PROFILE_LIST_CONFIRMATION } from 'texts/profileList'
import { setNotificationMessage } from 'redux/notifications/slice'
import { Company } from 'api/profile/types'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { AUTOCOMPLETE_LABEL, PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { useApiRequest } from 'hooks/useApiRequest'
import { deactivateProfileSubtitle } from 'texts/profileConfirmModal'
import styles from './styles.module.scss'
import { joinClasses } from 'utils/joinClasses'
import { useFilterSettings } from 'hooks/useFilterSettings'
import { FILTER_KEYS, PROFILE_LIST_FILTER_KEY } from 'constants/filters'
import { sortingSelector } from 'redux/sorting/selectors'
import { useSelector } from 'react-redux'
import { setFilterItems } from 'redux/filters/slice'
import { prepareFiltersForReq, prepareSortingForReq } from 'utils/table'
import {
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { useNavigationItems } from 'ui/hooks/useNavigationItems'
import { resetSectionStatuses } from 'redux/login/slice'

export const ProfileList = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [companyList, setCompanyList] =
    useState<ProfileApiTypes.GetCompanyListResponse | null>(null)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState<Company>()
  const [filtredCompanies, setFiltredCompanies] = useState<AutocompleteItem[]>(
    []
  )
  const sortingData = useSelector(sortingSelector)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const getFilterSettings = useFilterSettings(
    PROFILE_LIST_FILTER_KEY,
    setPage,
    PROFILE_LIST_FILTER_KEY
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const navigationItems = useNavigationItems()
  const { getCompanyInfo } = useCompanyInfo(false)
  const { apiRequest } = useApiRequest(
    (id) => profileApi.closeCompany(id),
    undefined,
    true
  )

  const getAccountStatusesRequest = useApiRequest(() =>
    profileApi.getAccountStatuses()
  )

  const fetchProfileList = async () => {
    const companyListResponse = await profileApi.getCompanyList(
      page - 1,
      Number(pageSize),
      false,
      prepareFiltersForReq(
        selectedFilters.profileList,
        selectedFiltersCount.profileList
      ),
      prepareSortingForReq(sortingData.profileList)
    )
    setCompanyList(companyListResponse.data)
  }

  useEffect(() => {
    fetchProfileList()
  }, [
    page,
    pageSize,
    isConfirmationModalOpen,
    sortingData,
    selectedFiltersCount.profileList,
  ])

  useEffect(() => {
    dispatch(resetSectionStatuses())
    const fetchAccountStatuses = async () => {
      const res = await getAccountStatusesRequest.apiRequest()

      if (res) {
        dispatch(
          setFilterItems({
            items: {
              accountStatus: res.data.statuses.map((accountStatus) => ({
                value: accountStatus,
                selected: false,
              })),
            },
            key: PROFILE_LIST_FILTER_KEY,
          })
        )
      }
    }

    fetchAccountStatuses()
  }, [])

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleArchiveCompany = async (company: Company) => {
    setIsConfirmationModalOpen(true)
    setSelectedCompany(company)
  }

  const handleConfirm = async (id: number) => {
    const response = await apiRequest(id)
    if (!response) {
      setIsConfirmationModalOpen(false)
    } else {
      return true
    }
  }

  const handleAddProfile = () => {
    navigate('add')
  }

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleRedirectToArchive = () => {
    navigate(`..${URLS.ARCHIVE}`)
  }

  const handleRowClick = (id: number) => {
    navigate(`${id}/edit`)
  }

  const handleActionItemClick = (company: Company, url: string) => {
    navigate(url)
  }

  const handleSearch = async (value: string) => {
    try {
      const companyListResponse = await profileApi.getCompanyList(0, 0, false, [
        { name: 'name', type: 'ILike', value },
      ])
      const preparedData = companyListResponse.data.companies.pageItems.map(
        (company) => ({ name: company.name, value: String(company.id) })
      )
      setFiltredCompanies(preparedData)
    } catch (e) {
      dispatch(
        setNotificationMessage({
          notificationMessage: (e as ErrorResponse).responseStatus.message,
          type: 'error',
        })
      )
    }
  }

  const formattedHeadItems = headItems.map((item) =>
    item === 'Status'
      ? {
          value: item,
          filterSettings: getFilterSettings(FILTER_KEYS.accountStatus),
        }
      : { value: item }
  )

  const formattedBodyItems = companyList?.companies.pageItems?.map(
    (company) => {
      const isStatusRegistered =
        company.accountStatus === 'Registered' ||
        company.accountStatus === 'Pending'
      return {
        items: [
          company.id,
          company.name,
          company.country,
          <Typography
            name="caption1WBold"
            className={joinClasses(
              styles.status,
              styles[company.accountStatus]
            )}
          >
            {company.accountStatus}
          </Typography>,
          <div className={styles['action-items']}>
            <ActionItems
              items={navigationItems.map(({ label, getPath, isDisabled }) => ({
                value: label,
                onClick: () =>
                  handleActionItemClick(company, getPath(String(company.id))),
                isDisabled: isStatusRegistered ? isDisabled : false,
              }))}
              listItemWidth="200px"
              waitPreparation={() =>
                isStatusRegistered ? getCompanyInfo(company.id) : null
              }
            />
            <ArchiveIcon
              color="blue700"
              className={styles['archive-icon']}
              onClick={(e) => {
                e.stopPropagation()
                handleArchiveCompany(company)
              }}
            />
          </div>,
        ],
        onClick: () => handleRowClick(company.id),
      }
    }
  )

  const handleSelect = async (id: string) => {
    const companyListResponse = await profileApi.getCompanyList(0, 0, false, [
      { name: 'id', type: 'Eq', value: id },
    ])
    setCompanyList(companyListResponse.data)
  }

  return (
    <div>
      {isConfirmationModalOpen && selectedCompany && (
        <Confirmation
          confirmModalProps={{
            question: `${PROFILE_LIST_CONFIRMATION.CONFIRMATION_MODAL.QUESTION} ${selectedCompany?.name}?`,
            onConfirm: () => handleConfirm(selectedCompany.id),
            onClose: handleModalClose,
            title: `${PROFILE_LIST.ARCHIVE} ${selectedCompany.name}?`,
          }}
          successModalProps={{
            onClose: handleModalClose,
            title: PROFILE_LIST_CONFIRMATION.SUCCESS_MODAL.TITLE,
            buttonLabel: PROFILE_LIST_CONFIRMATION.SUCCESS_MODAL.BUTTON_LABEL,
            onConfirm: handleRedirectToArchive,
            subTitle: deactivateProfileSubtitle(selectedCompany?.name),
          }}
        />
      )}
      <Container
        title={MENU_NAMES.PROFILE_LIST.NAME}
        tooltip={PROFILE_LIST.TOOLTIP_CONTENT}
        withCompanyName={false}
        rightBlock={
          <div className={styles['autocomplete-wrapper']}>
            <Autocomplete
              dropdownEmptyText={PROFILE_LIST.SEARCH_DROPDOWN_EMPTY_VALUE}
              handleSelect={handleSelect}
              placeholder={PROFILE_LIST.SEARCH_INPUT_PLACEHOLDER}
              data={filtredCompanies}
              setData={setFiltredCompanies}
              onIconClick={fetchProfileList}
              ofLabel={AUTOCOMPLETE_LABEL}
              handleSearch={(value) => handleSearch(value)}
            />
          </div>
        }
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.button}>
              <Button
                size="small"
                typographyName="Button2"
                variant="outline"
                onClick={handleAddProfile}
                label={PROFILE_LIST.BUTTON_LABEL}
                iconPosition="right"
                icon={<PlusIcon color="blue700" />}
              />
            </div>
          </div>
          <div className={styles.table}>
            {formattedBodyItems && (
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
              />
            )}
          </div>
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={companyList?.companies.pageItems.length}
            total={companyList?.companies?.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </div>
  )
}
