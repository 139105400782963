import { forwardRef } from 'react'
import { TableHeadProps } from './types'
import { Typography } from 'ui/atoms/Typography'
import { Filter } from 'ui/atoms/Filter'
import styles from './styles.module.scss'

export const TableHead = forwardRef<HTMLTableCellElement, TableHeadProps>(
  (
    {
      item,
      mouseDown,
      containerWidth,
      itemsCount,
      isLastChild,
      isEmptyTable,
      initItemsWidth,
      index,
    },
    ref
  ) => {
    const initItemWidth = initItemsWidth
      ? initItemsWidth[index]
      : `${containerWidth / itemsCount}px`
    return (
      <Typography
        Tag="th"
        ref={ref}
        name="Subtitle3"
        className={styles.head}
        style={{ minWidth: initItemWidth }}
      >
        <div className={styles.item}>
          {!isLastChild && (
            <div
              className={styles['item-left-border']}
              onMouseDown={mouseDown}
            />
          )}
          {item.value}
          {(item.filterSettings?.withFiltering ||
            item.filterSettings?.withSorting) && (
            <div className={styles.filter}>
              <Filter
                {...item.filterSettings}
                isLastChild={isLastChild}
                isEmptyTable={isEmptyTable}
              />
            </div>
          )}
        </div>
      </Typography>
    )
  }
)
