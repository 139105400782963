export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const MONTH_LENGTH = 31
export const ONE_GROUP = 1
export const TWO_GROUP = 2
export const EMPTY_GROUP = 0

export const AFTER_DOT = 2

export const INIT_FINANCIAL_PERIOD = 1
export const INIT_CAR_RENTAL_PERIOD = 2

export const CURRENCY_ID = 'EUR'
