import { ReactNode } from 'react'
import styles from './styles.module.scss'
import { joinClasses } from 'utils/joinClasses'

interface IRowProps {
  children: ReactNode
  isOffset?: boolean
}

export const Row = ({ children, isOffset }: IRowProps) => (
  <div className={joinClasses(styles.row, [styles['bottom-offset'], isOffset])}>
    {children}
  </div>
)
