import { Typography, Button, LazyImage } from '@frontend/design_system'
import { Block } from '../Block'
import {
  DELETE_MODAL_DESCRIPTION,
  DELETE_MODAL_TITLE,
  DELETED_SUCCESS,
  HISTORY_LIST_ACTION,
  HISTORY_LIST_TITLE,
} from 'texts/termsAndConditions'
import styles from './styles.module.scss'
import trash from 'assets/icons/red600/red600_trash_20x20.webp'
import { useApiRequest } from 'hooks/useApiRequest'
import { termsApi } from 'api'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { useEffect, useState } from 'react'
import { ITermsAndConditionsItem } from 'api/terms/types'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { Confirmation } from 'ui/molecules/Confirmation'
import { scrollTop } from 'utils/scrollTop'
import { useCompanyId } from 'hooks/useCompanyId'

interface IHistoryListProps {
  termConditionTypesId: TERM_CONDITIONS_TYPES
  setEditData: (data?: ITermsAndConditionsItem) => void
  editDataId?: number
}

interface IConfirmationData {
  label: string
  id: number
}

export const HistoryList = ({
  termConditionTypesId,
  setEditData,
  editDataId,
}: IHistoryListProps) => {
  const dispatch = useDispatch()
  const companyId = useCompanyId()
  const [list, setList] = useState<ITermsAndConditionsItem[]>([])
  const [confirmationData, setConfirmationData] =
    useState<IConfirmationData | null>(null)
  const newTermConditionRequest = useApiRequest(
    () => termsApi.getTermConditionListRequest(companyId, termConditionTypesId),
    undefined,
    true
  )
  const deleteTermConditionRequest = useApiRequest(
    (id: number) => termsApi.deleteTermConditionRequest(id),
    undefined,
    true
  )

  const getList = async () => {
    const response = await newTermConditionRequest.apiRequest()

    if (response) {
      setList(response.data.termConditions)
    }
  }

  useEffect(() => {
    getList()
  }, [])

  const toggleConfirmationModal = (data: IConfirmationData | null) =>
    setConfirmationData(data)

  const onDelete = async (id: number) => {
    await deleteTermConditionRequest.apiRequest(id)

    if (id === editDataId) {
      setEditData()
    }

    getList()
    dispatch(
      setNotificationMessage({
        notificationMessage: DELETED_SUCCESS,
      })
    )
    toggleConfirmationModal(null)
  }

  const onEdit = (data: ITermsAndConditionsItem) => {
    setEditData(data)
    scrollTop()
  }

  if (newTermConditionRequest.loading || !list.length) {
    return null
  }

  return (
    <Block title={HISTORY_LIST_TITLE}>
      {list.map((item) => {
        const cors = item.countriesOfResidence.join(', ')

        return (
          <div key={cors} className={styles.row}>
            <Typography name="body1WMedium" Tag="div" className={styles.cors}>
              {cors}
            </Typography>
            <div className={styles.actions}>
              <Button
                label={HISTORY_LIST_ACTION}
                typographyName="body1WMedium"
                variant="link"
                onClick={() => onEdit(item)}
              />
              <LazyImage
                src={trash}
                width={20}
                height={20}
                onClick={() =>
                  toggleConfirmationModal({ label: cors, id: item.id })
                }
              />
            </div>
          </div>
        )
      })}
      {confirmationData && (
        <Confirmation
          confirmModalProps={{
            title: DELETE_MODAL_TITLE,
            onConfirm: () => onDelete(confirmationData.id),
            onClose: () => toggleConfirmationModal(null),
            question: DELETE_MODAL_DESCRIPTION,
          }}
        />
      )}
    </Block>
  )
}
