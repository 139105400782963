import { ErrorResponse } from 'api/types'
import { request } from 'api/utils'
import { addQueryString } from 'utils/addQueryString'
import { PATH } from './constants'
import { GetBookingHistoryListResponse } from './types'

class Api {
  public baseUrl: string = process.env.REACT_APP_API_URL || ''

  async getBookingHistoryList(
    pageSize: number,
    pageIndex: number,
    companyId = ''
  ) {
    return request<GetBookingHistoryListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.BOOKING_HISTORY_LIST, {
        companyId,
        pageSize,
        pageIndex,
      })
    )
  }
}

export const bookingHistoryApi = new Api()
