export const VEHICLES = {
  ADD_BUTTON: 'Add car',
  DELETE: 'Delete',
  MODEL: 'model?',
  TOOLTIP_CONTENT: 'Here you can add cars to your fleet.',
}

export const VEHICLES_DELETE_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    QUESTION: 'Are you sure that you want to delete',
  },
  SUCCESS: 'The car has been successfully deleted',
}
