import { SelectValue } from 'types/form'
import countryOfResidence from 'constants/countryOfResidence'

export const prepareResponseItems = <R = string>(items: SelectValue<R>[]) =>
  items.map(({ value }) => value) as R[]

const CODE_NAMES = countryOfResidence.reduce<{ [key: string]: string }>(
  (res, { code, name }) => {
    res[code] = name
    return res
  },
  {}
)

export const prepareCorForSelect = (codes: string[] = []) =>
  codes.map((code) => ({ label: CODE_NAMES[code], value: code }))
