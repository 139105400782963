import { DRIVER_REQUIREMENTS_FORM_VALUES } from 'texts/termsAndConditions'
import { getRequiredMessage } from 'utils/getRequiredMessage'

export const LICENSE_YEARS_RULES = [
  {
    type: 'required',
    message: getRequiredMessage(
      DRIVER_REQUIREMENTS_FORM_VALUES.LICENSE_YEARS.LABEL
    ),
  },
]

export const LOCATION_RULES = [
  {
    type: 'required',
    message: getRequiredMessage(DRIVER_REQUIREMENTS_FORM_VALUES.LOCATION.LABEL),
  },
]

export const RADIO_BUTTONS = [
  {
    id: DRIVER_REQUIREMENTS_FORM_VALUES.PER_RENTAL.ID,
    label: DRIVER_REQUIREMENTS_FORM_VALUES.PER_RENTAL.LABEL,
  },
  {
    id: DRIVER_REQUIREMENTS_FORM_VALUES.PER_DAY.ID,
    label: DRIVER_REQUIREMENTS_FORM_VALUES.PER_DAY.LABEL,
  },
]
