import { RootState } from 'redux/store'

export const shortSupplierDataSelector = (state: RootState) =>
  state.profileReducer.shortSupplierData

export const supplierCompanyNameSelector = (state: RootState) =>
  state.profileReducer.supplierCompanyName

export const checkedStatusesSelector = (id: string) => (state: RootState) =>
  state.profileReducer.checkedStatuses[id] || {}
