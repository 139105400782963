import { useEffect, useMemo, useState } from 'react'
import { referencesApi, ReferencesApiTypes } from 'api'
import { useAppSelector } from 'redux/hooks'
import { UserRole } from 'modules/Profile/types'
import { userDataSelector } from 'redux/login/selectors'
import {
  CONTACT_INFORMATION_FORM_VALUES,
  PROFILE_DETAILS,
} from 'texts/profileDetails'
import { Button } from 'ui/atoms/Button'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { Typography } from 'ui/atoms/Typography'
import { UploadFile } from 'ui/atoms/UploadFile/UploadFile'
import StarSrc from 'assets/img/Star.svg'
import { FormItem, LazyImage } from '@frontend/design_system'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { Badge } from 'ui/atoms/Badge'
import { Modal } from 'ui/molecules/Modal'
import { CANCEL, CONFIRM } from 'texts/uiTexts'
import { PlusIcon } from 'ui/icons'
import { formats } from './constants'
import { ProfileDetailsFormProps } from './types'
import { ContactInformationForm } from './components/ContactInformationForm'
import { PaymentInformationForm } from './components/PaymentInformationForm'
import { FormItemChildren } from 'types/form'
import styles from './styles.module.scss'

export const ProfileDetailsForm = ({
  values,
  errors,
  readOnly,
}: ProfileDetailsFormProps) => {
  const [currencies, setCurrencies] =
    useState<ReferencesApiTypes.CurrenciesResponse>({ items: [] })
  const [countries, setCountries] =
    useState<ReferencesApiTypes.CountriesResponse>({ items: [] })
  const [isLoading, setLoading] = useState(false)
  const role = useAppSelector(
    userDataSelector
  ).roles[0]?.toLowerCase() as UserRole

  const { companyCurrencyName, companyCountryID } = values

  useEffect(() => {
    setLoading(true)
    const fetchCurrencies = async () => {
      const fetchCurrenciesResponse = await referencesApi.getCurrencies()
      setCurrencies(fetchCurrenciesResponse.data)
      if (companyCurrencyName === '') {
        values.companyCurrencyName = fetchCurrenciesResponse.data.items[0].name
      }
    }
    const fetchCountries = async () => {
      const fetchCountriesResponse = await referencesApi.getCountries()
      setCountries(fetchCountriesResponse.data)
      if (!companyCountryID) {
        values.companyCountryID = ''
      }
    }
    setLoading(false)
    fetchCurrencies()
    fetchCountries()
  }, [companyCurrencyName, companyCountryID])

  const logoRule: Rule[] = useMemo(
    () => [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.LOGO.REQUIRED_MESSAGE,
      },
    ],
    []
  )

  const renderHeaderBlock = (error?: string) => (
    <>
      <ContentWrapper
        className={styles.form}
        title={PROFILE_DETAILS.UPLOAD_FILE.TITLE}
      >
        <div className={styles['upload-files']}>
          <FormItem
            id={CONTACT_INFORMATION_FORM_VALUES.LOGO.ID}
            className={styles['form-item']}
            rules={logoRule}
          >
            {({ value, onChange, error }: FormItemChildren) => (
              <div className={styles['upload-files-content']}>
                <UploadFile
                  renderConfirmationModal={(onClose, onConfirm) => (
                    <Modal
                      onClose={onClose}
                      position="center-horizontal"
                      title={PROFILE_DETAILS.UPLOAD_FILE.DELETE_MODAL_TITLE}
                      headerSize="large"
                      isDelimiter
                    >
                      <div className={styles.modal}>
                        <Typography
                          name="Button1"
                          color="grey500"
                          Tag="div"
                          className={styles.description}
                        >
                          {PROFILE_DETAILS.UPLOAD_FILE.DELETE_CONFIRMATION}
                        </Typography>
                        <div className={styles.buttons}>
                          <Button
                            label={CANCEL}
                            size="large"
                            variant="outline"
                            onClick={onClose}
                          />
                          <Button
                            label={CONFIRM}
                            size="large"
                            onClick={onConfirm}
                          />
                        </div>
                      </div>
                    </Modal>
                  )}
                  initFiles={value}
                  onUpload={onChange}
                  error={error}
                  label={<PlusIcon size="medium" />}
                  formats={formats}
                  disabled={readOnly}
                />
                {!!values?.rating && !!values?.reviewCount && (
                  <div className={styles['review-block']}>
                    <div className={styles.rating}>
                      <LazyImage
                        src={StarSrc}
                        className={styles['rating-icon']}
                        width={16}
                        height={16}
                      />
                      <Typography name="Subtitle3">{values.rating}</Typography>
                    </div>
                    <Typography name="Subtitle3" color="grey500">
                      {values.reviewCount} {PROFILE_DETAILS.REVIEWS}
                    </Typography>
                  </div>
                )}
                {!(readOnly || value.length) && (
                  <div className={styles['file-format-block']}>
                    <Typography
                      name="Button2"
                      color="grey500"
                      html={PROFILE_DETAILS.UPLOAD_FILE.SIZE}
                    />
                    <Typography
                      name="Button2"
                      color="grey500"
                      html={PROFILE_DETAILS.UPLOAD_FILE.FORMAT}
                    />
                  </div>
                )}
              </div>
            )}
          </FormItem>
          {error && (
            <Badge
              message={error}
              type="error"
              iconSize="small"
              typographyName="body2WMedium"
              className={styles['logo-error']}
            />
          )}
        </div>
      </ContentWrapper>
    </>
  )

  return !isLoading ? (
    <>
      {renderHeaderBlock(errors?.logo)}
      <ContentWrapper
        className={styles.form}
        title={PROFILE_DETAILS.FORM.CONTACT_FORM_TITLE}
      >
        <ContactInformationForm
          countries={countries}
          role={role}
          readOnly={readOnly}
          selectedCountryId={Number(values?.companyCountryID)}
        />
      </ContentWrapper>
      <ContentWrapper
        className={styles.form}
        title={PROFILE_DETAILS.FORM.PAYMENT_FORM_TITLE}
      >
        <PaymentInformationForm
          currencies={currencies}
          role={role}
          readOnly={readOnly}
        />
      </ContentWrapper>
    </>
  ) : null
}
