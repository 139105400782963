import { useCallback, useEffect, useState, useRef } from 'react'
import { AutocompleteProps } from './types'
import { Input } from '@frontend/design_system'
import { Dropdown } from './components/Dropdown/Dropdown'
import { debounce } from 'utils/debounce'
import styles from './styles.module.scss'

export const Autocomplete = ({
  placeholder,
  innerLabel,
  data,
  setData,
  dropdownEmptyText,
  ofLabel,
  handleSearch,
  size = 'medium',
  borderRadius = '8px',
  typographyName = 'body2WMedium',
  handleSelect,
  onIconClick,
}: AutocompleteProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [searchValue, setSearchValue] = useState('')
  const [active, setActive] = useState(false)
  const [isSelected, setIsSelected] = useState(false)

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Element)) {
        setActive(false)
        setIsSelected(false)
        setData([])
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [active])

  const openDropdown = () => {
    setActive(true)
  }

  const onSearchHandler = async (value?: string) => {
    if (value) handleSearch(value)
    openDropdown()

    return true
  }

  const handler = useCallback(debounce(onSearchHandler), [handleSearch])

  const handleChange = (value: string) => {
    handler(value)
    setSearchValue(value)
  }

  const handleClearValue = () => {
    setSearchValue('')
    setData([])
    setActive(false)
    setIsSelected(false)
    onIconClick?.()
  }

  const handleClick = () => {
    setIsSelected(true)
    if (data.length > 0) {
      setActive(true)
    }
  }

  const handleDropdownValueSelect = (selectedItemData: string) => {
    handleSelect(selectedItemData)
    setActive(false)
  }

  useEffect(() => {
    if (data.length) setActive(true)
  }, [data.length])

  return (
    <div ref={ref} className={styles.autocomplete}>
      <Input
        placeholder={isSelected ? '' : placeholder}
        iconRight={searchValue ? 'ModalCloseIcon' : 'SearchIcon'}
        iconOnClickRight={handleClearValue}
        iconSize="small"
        size={size}
        iconColor={searchValue ? 'grey400' : 'blue700'}
        onChange={handleChange}
        onClick={handleClick}
        value={searchValue}
        borderRadius={borderRadius}
        innerLabel={innerLabel}
        typographyName={typographyName}
        placeholderColorVariant="primary"
        isAlertBottomOffset={false}
        spaceForError="none"
      />
      {active && (
        <Dropdown
          data={data}
          value={searchValue}
          onSelect={handleDropdownValueSelect}
          style={{
            width: ref.current?.offsetWidth,
          }}
          ofLabel={ofLabel}
          dropdownEmptyText={dropdownEmptyText}
        />
      )}
    </div>
  )
}
