import { DailyRange, SeasonRange } from 'api/fleet/types'
import { INFINITY } from './components/Ranges/constants'
import { PRICE_LIST_DETAILS_FORM_VALUES } from 'texts/priceListDetails'

export const seasonRangeOverlapValidation = (value: SeasonRange[]) => {
  for (let i = 0; i < value.length - 1; i++) {
    for (let j = i + 1; j < value.length; j++) {
      const firstStart = new Date(value[i].start)
      const firstEnd = new Date(value[i].end)
      const secondStart = new Date(value[j].start)
      const secondEnd = new Date(value[j].end)

      if (!(firstEnd < secondStart || secondEnd < firstStart)) {
        return {
          message: PRICE_LIST_DETAILS_FORM_VALUES.SEASON_RANGES.OVERLAP_MESSAGE,
        }
      }
    }
  }
  return false
}

export const dailyRangeEmptyValidation = (value: DailyRange[]) => {
  for (let i = 0; i < value.length; i++) {
    const { rangeStart } = value[i]
    if (!rangeStart) {
      return {
        message: PRICE_LIST_DETAILS_FORM_VALUES.DAILY_RANGES.EMPTY_MESSAGE,
      }
    }
  }
  return false
}

export const dailyRangeOverlapValidation = (value: DailyRange[]) => {
  const sorted = value.sort(
    (a, b) => Number(a.rangeStart) - Number(b.rangeStart)
  )

  for (let i = 0; i < sorted.length - 1; i++) {
    for (let j = i + 1; j < sorted.length; j++) {
      const { rangeEnd: firstEnd } = sorted[i]
      const { rangeStart: secondStart, rangeEnd: secondEnd } = sorted[j]
      if (
        !(Number(firstEnd) < Number(secondStart)) ||
        ((!firstEnd || firstEnd === INFINITY) &&
          (!secondEnd || secondEnd === INFINITY))
      ) {
        return {
          message: PRICE_LIST_DETAILS_FORM_VALUES.DAILY_RANGES.OVERLAP_MESSAGE,
        }
      }
    }
  }
  return false
}

export const dailyRangeGapsValidation = (ranges: DailyRange[]) => {
  const sorted = ranges.sort(
    (a, b) => Number(a.rangeStart) - Number(b.rangeStart)
  )

  for (let i = 0; i < sorted.length - 1; i++) {
    const currentEnd = sorted[i].rangeEnd
    const nextStart = sorted[i + 1].rangeStart

    if (currentEnd !== INFINITY && Number(currentEnd) + 1 < Number(nextStart)) {
      return {
        message: PRICE_LIST_DETAILS_FORM_VALUES.DAILY_RANGES.GAP_RANGE_MESSAGE,
      }
    }
  }

  return false
}

export const seasonRangeDateValidation = (value: SeasonRange[]) =>
  !!value.filter((el) => new Date(el.start) > new Date(el.end)).length && {
    message: PRICE_LIST_DETAILS_FORM_VALUES.SEASON_RANGES.COMPARE_DATE_MESSAGE,
  }

export const seasonRangeEmptyDateValidation = (value: SeasonRange[]) =>
  value.some((el) => !el.start || !el.end) && {
    message: PRICE_LIST_DETAILS_FORM_VALUES.SEASON_RANGES.REQUIRED_MESSAGE,
  }

export const dailyRangeFirstRangeValidation = (value: DailyRange[]) => {
  if (value.length) {
    const firstRange = value[0]
    return (
      Number(firstRange.rangeStart) !== 1 && {
        message:
          PRICE_LIST_DETAILS_FORM_VALUES.DAILY_RANGES.FIRST_RANGE_MESSAGE,
      }
    )
  }
  return false
}

export const seasonRangeDifferentYearValidation = (value: SeasonRange[]) =>
  value.some(
    (el) => new Date(el.end).getFullYear() > new Date(el.start).getFullYear()
  ) && {
    message:
      PRICE_LIST_DETAILS_FORM_VALUES.SEASON_RANGES.DIFFERENT_YEAR_MESSAGE,
  }
