import { useEffect, useState } from 'react'
import { STOP_SALES_DATE_FORMAT } from 'constants/date'
import { dashboardApi, DashboardApiTypes } from 'api'
import { startOfMonth, endOfMonth, subMonths, format } from 'date-fns'
import { useApiRequest } from 'hooks/useApiRequest'
import { INIT_CAR_RENTAL_PERIOD } from '../DashboardList/constants'

export const useCarRentalData = (companyId: string | number) => {
  const today = new Date()
  const startCarRentalDate = startOfMonth(
    subMonths(today, INIT_CAR_RENTAL_PERIOD)
  )
  const endCarRentalDate = endOfMonth(today)
  const [carRentalDaysData, setCarRentalDaysData] =
    useState<DashboardApiTypes.CarRentalDaysResponse | null>(null)
  const [initialCarRentalDate, setInitialCartRentalDate] = useState({
    start: startCarRentalDate,
    end: endCarRentalDate,
  })
  const [selectedCarRentalRange, setSelectedCarRentalRange] = useState({
    from: startCarRentalDate,
    to: endCarRentalDate,
  })

  const carRentalDaysRequest = useApiRequest((companyId, dateStart, dateEnd) =>
    dashboardApi.getCarRentalDays({
      companyId: String(companyId),
      dateStart,
      dateEnd,
    })
  )

  useEffect(() => {
    if (selectedCarRentalRange.from && selectedCarRentalRange.to && companyId) {
      const getCarRentalDays = async () => {
        const response = await carRentalDaysRequest.apiRequest(
          companyId,
          format(new Date(selectedCarRentalRange.from), STOP_SALES_DATE_FORMAT),
          format(new Date(selectedCarRentalRange.to), STOP_SALES_DATE_FORMAT)
        )
        if (response && response.data) {
          setCarRentalDaysData(response.data)
        }
      }
      getCarRentalDays()
    }
  }, [companyId, selectedCarRentalRange.from, selectedCarRentalRange.to])

  return {
    selectedCarRentalRange,
    setSelectedCarRentalRange,
    initialCarRentalDate,
    setInitialCartRentalDate,
    carRentalDaysData,
  }
}
