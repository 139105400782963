import { createSlice } from '@reduxjs/toolkit'
import { ApplicationsState } from './types'

const initialState: ApplicationsState = {
  selectedApplicationsRange: {
    from: '',
    to: '',
  },
}

export const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setSelectedApplicationsRange(state, action) {
      state.selectedApplicationsRange = action.payload
    },
  },
})

export const applicationsReducer = applicationsSlice.reducer
export const { setSelectedApplicationsRange } = applicationsSlice.actions
