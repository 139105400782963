import { RENTAL_INCLUDES_FORM_VALUES } from 'texts/termsAndConditions'
import { getRequiredMessage } from 'utils/getRequiredMessage'

export const PICKUP_COUNTRY_RULES = [
  {
    type: 'required',
    message: getRequiredMessage(
      RENTAL_INCLUDES_FORM_VALUES.PICKUP_COUNTRY.LABEL
    ),
  },
]
export const DISTANCE_RULES = [
  {
    type: 'required',
    message: getRequiredMessage(RENTAL_INCLUDES_FORM_VALUES.DISTANCE.LABEL),
  },
]
export const UNIT_RULES = [
  {
    type: 'required',
    message: getRequiredMessage(RENTAL_INCLUDES_FORM_VALUES.UNIT.LABEL),
  },
]
export const PERIOD_RULES = [
  {
    type: 'required',
    message: getRequiredMessage(RENTAL_INCLUDES_FORM_VALUES.PERIOD.LABEL),
  },
]

export const RADIO_BUTTONS = [
  {
    id: RENTAL_INCLUDES_FORM_VALUES.UNLIMITED.ID,
    label: RENTAL_INCLUDES_FORM_VALUES.UNLIMITED.LABEL,
  },
  {
    id: RENTAL_INCLUDES_FORM_VALUES.LIMITED.ID,
    label: RENTAL_INCLUDES_FORM_VALUES.LIMITED.LABEL,
  },
]
