export const CAR_TYPE_SUV = 'SUV'
export const CAR_TYPE_ELECTRIC = 'Electric'
export const CAR_TYPE_MINI = 'Mini'
export const CAR_TYPE_ECONOMY = 'Economy'
export const CAR_TYPE_ECONOMY_ELITE = 'Economy Elite'
export const CAR_TYPE_COMPACT = 'Compact'
export const CAR_TYPE_COMPACT_ELITE = 'Compact Elite'
export const CAR_TYPE_INTERMEDIATE = 'Intermediate'
export const CAR_TYPE_FULLSIZE = 'Fullsize'
export const CAR_TYPE_FULLSIZE_ELITE = 'Fullsize Elite'
export const CAR_TYPE_SPECIAL = 'Special'
export const CAR_TYPE_LUXURY = 'Luxury'
export const CAR_TYPE_LUXURY_ELITE = 'Luxury Elite'
export const CAR_TYPE_VAN = 'Van'
export const CAR_TYPE_OTHER = 'Other'
export const CAR_TYPE_ESTATE = 'Estate'
export const CAR_TYPE_CONVERTIBLE = 'Convertible'
