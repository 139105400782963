import { DRIVER_AGE_RANGE_VALIDATIONS } from 'texts/driverAgeRange'
import { CONTACT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { MIN_MAX_AGE_ERROR } from 'texts/termsAndConditions'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'

export const EMPTY_RULES = []

export const phoneNumberRules: Rule[] = [
  {
    type: 'required',
    message: CONTACT_INFORMATION_FORM_VALUES.PHONE.REQUIRED_MESSAGE,
  },
  {
    type: 'min',
    value: 3,
    message: CONTACT_INFORMATION_FORM_VALUES.PHONE.VALIDATION_MESSAGE_MIN,
  },
  {
    type: 'max',
    value: 20,
    message: CONTACT_INFORMATION_FORM_VALUES.PHONE.VALIDATION_MESSAGE_MAX,
  },
]

export const MIN_AGE = 21
export const MAX_AGE = 99

export const MIN_AGE_RULES = [
  {
    type: 'required',
    message: DRIVER_AGE_RANGE_VALIDATIONS.MIN_AGE,
  },
  {
    type: 'custom',
    value: (value: string) =>
      +value < MIN_AGE
        ? { message: DRIVER_AGE_RANGE_VALIDATIONS.MIN_AGE }
        : false,
  },
  {
    type: 'custom',
    value: (value: string, { maxAge }: { maxAge: string }) =>
      +maxAge < +value ? { message: MIN_MAX_AGE_ERROR } : false,
  },
]

export const MAX_AGE_RULES = [
  {
    type: 'required',
    message: DRIVER_AGE_RANGE_VALIDATIONS.MAX_AGE,
  },
  {
    type: 'custom',
    value: (value: string) =>
      !value || +value > MAX_AGE
        ? { message: DRIVER_AGE_RANGE_VALIDATIONS.MAX_AGE }
        : false,
  },
  {
    type: 'custom',
    value: (value: string, values: { minDrivedAge: number }) =>
      +values.minDrivedAge > +value
        ? { message: DRIVER_AGE_RANGE_VALIDATIONS.END_MORE_START }
        : false,
  },
]
