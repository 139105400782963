import {
  AirConditioning,
  DriverAgeRange,
  DriverAgeRangeType,
} from 'api/fleet/types'
import {
  allSymbolsAndEnglishLettersRegex,
  lettersWithNumbersRegex,
} from 'constants/regex'
import { getRequiredRule } from 'utils/getValidationRules'
import { NOT_AVAILABLE } from '../../constants'
import { VEHICLE_DETAILS_FORM_VALUES } from 'texts/vehicleDetails'
import { MultiselectItem } from 'ui/atoms/Multiselect/types'

export const initialFormValues = {
  car: '',
  carType: '',
  sippCode: '',
  companyClass: '',
  carCategoryId: '',
  transmission: '',
  airConditioning: AirConditioning.NA,
  fuel: '',
  hirePoints: [] as MultiselectItem[],
  sits: NOT_AVAILABLE,
  doors: NOT_AVAILABLE,
  driverAgeRange: {
    items: [],
    rangeType: DriverAgeRangeType.PerRental,
  } as DriverAgeRange,
  carModelName: '',
  quantity: '',
}

export const VEHICLE_DETAILS_VALIDATION_RULES = {
  CAR: getRequiredRule(VEHICLE_DETAILS_FORM_VALUES.CAR.REQUIRED_MESSAGE),
  CAR_TYPE: getRequiredRule(
    VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.REQUIRED_MESSAGE
  ),
  CAR_CATEGORY: getRequiredRule(
    VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.REQUIRED_MESSAGE
  ),
  HIRE_POINTS: getRequiredRule(
    VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.REQUIRED_MESSAGE
  ),
  COMPANY_CLASS: [
    ...getRequiredRule(
      VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.REQUIRED_MESSAGE
    ),
    {
      type: 'pattern',
      value: allSymbolsAndEnglishLettersRegex,
      message: VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.VALIDATION_MESSAGE,
    },
  ],
  SIPP: [
    ...getRequiredRule(VEHICLE_DETAILS_FORM_VALUES.SIPP.REQUIRED_MESSAGE),

    {
      type: 'max',
      value: 8,
      message: VEHICLE_DETAILS_FORM_VALUES.SIPP.VALIDATION_MESSAGE,
    },
    {
      type: 'min',
      value: 2,
      message: VEHICLE_DETAILS_FORM_VALUES.SIPP.VALIDATION_MESSAGE,
    },
    {
      type: 'pattern',
      value: lettersWithNumbersRegex,
      message: VEHICLE_DETAILS_FORM_VALUES.SIPP.VALIDATION_MESSAGE,
    },
  ],
  QUANTITY: [
    ...getRequiredRule(VEHICLE_DETAILS_FORM_VALUES.QUANTITY.REQUIRED_MESSAGE),
    {
      type: 'custom',
      value: (value: string) =>
        Number(value) < 1 && {
          message: VEHICLE_DETAILS_FORM_VALUES.QUANTITY.VALIDATION_MESSAGE,
        },
    },
  ],
  CAR_MODEL_NAME: [
    {
      type: 'pattern',
      value: allSymbolsAndEnglishLettersRegex,
      message: VEHICLE_DETAILS_FORM_VALUES.CAR_MODEL_NAME.VALIDATION_MESSAGE,
    },
  ],
  TRANSMISSION: [
    ...getRequiredRule(
      VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.REQUIRED_MESSAGE
    ),
  ],
  FUEL: [...getRequiredRule(VEHICLE_DETAILS_FORM_VALUES.FUEL.REQUIRED_MESSAGE)],
}

export const SIPP_CODE_LENGTH = 4

export const VEHICLE_DETAILS_FIELDS = {
  [VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.ID]:
    VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.ID]:
    VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.ID]:
    VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.SIPP.ID]: VEHICLE_DETAILS_FORM_VALUES.SIPP.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.CAR.ID]: VEHICLE_DETAILS_FORM_VALUES.CAR.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.ID]:
    VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.ID]:
    VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.ID]:
    VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.FUEL.ID]: VEHICLE_DETAILS_FORM_VALUES.FUEL.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.SEATS.ID]:
    VEHICLE_DETAILS_FORM_VALUES.SEATS.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.DOORS.ID]:
    VEHICLE_DETAILS_FORM_VALUES.DOORS.LABEL,
  [VEHICLE_DETAILS_FORM_VALUES.QUANTITY.ID]:
    VEHICLE_DETAILS_FORM_VALUES.QUANTITY.LABEL,
}

export const DRIVER_AGE_RANGE_FIELDS = {
  [VEHICLE_DETAILS_FORM_VALUES.DRIVER_AGE_RANGE.ID]:
    VEHICLE_DETAILS_FORM_VALUES.DRIVER_AGE_RANGE.LABEL,
}

export const REQUIRED_FIELDS = [
  VEHICLE_DETAILS_FORM_VALUES.CAR.ID,
  VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.ID,
  VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.ID,
  VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.ID,
  VEHICLE_DETAILS_FORM_VALUES.FUEL.ID,
  VEHICLE_DETAILS_FORM_VALUES.SEATS.ID,
  VEHICLE_DETAILS_FORM_VALUES.DOORS.ID,
  VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.ID,
  VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.ID,
  VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.ID,
  VEHICLE_DETAILS_FORM_VALUES.QUANTITY.ID,
]
