import { useNavigate } from 'react-router-dom'
import { Form, OriginButton } from '@frontend/design_system'
import {
  ProfileFormValues,
  ProfileDetailsFormProps,
} from '../../../../../ui/components/ProfileDetailsForm/types'
import { initialFormValues } from 'ui/components/ProfileDetailsForm/constants'
import styles from './styles.module.scss'
import { ProfileDetailsFormWrapperProps } from './types'
import { PROFILE_DETAILS } from 'texts/profileDetails'
import { ProfileDetailsForm } from 'ui/components/ProfileDetailsForm'
import { FormChildren } from 'types/form'
import { Confirmation } from 'ui/molecules/Confirmation'
import { useState } from 'react'
import { COMPLETE_CONFIRMATION } from 'texts/common'

export const ProfileDetailsFormWrapper = ({
  handleSubmit,
  initialValues = initialFormValues,
  withActionButtons,
  readOnly,
  isLoading,
  isUserApproved,
}: ProfileDetailsFormWrapperProps) => {
  const navigate = useNavigate()
  const [isCompleteModalOpen, setCompleteModalOpen] = useState(false)
  const [formValues, setFormValues] = useState<Partial<ProfileFormValues>>({})

  const handleBack = () => navigate(-1)

  const onSubmit = (data: Partial<ProfileFormValues>, validate: boolean) => {
    if (validate) {
      if (!isUserApproved) {
        setCompleteModalOpen(true)
        setFormValues(data)
      } else if (handleSubmit) {
        handleSubmit({
          ...data,
          phoneAreaCode: data.phoneCor?.code
            ? String(data.phoneCor?.phoneCode)
            : '',
          phoneCountryCode: data.phoneCor?.code
            ? String(data.phoneCor?.code)
            : '',
        })
      }
    }
  }

  const handleConfirm = () => {
    if (handleSubmit && formValues) {
      setCompleteModalOpen(false)
      handleSubmit({
        ...formValues,
        phoneAreaCode: formValues.phoneCor?.code
          ? String(formValues.phoneCor?.phoneCode)
          : '',
        phoneCountryCode: formValues.phoneCor?.code
          ? String(formValues.phoneCor?.code)
          : '',
      })
    }
  }

  const renderButtons = (isDirty?: boolean) => {
    if (readOnly) {
      return null
    }
    if (withActionButtons) {
      return (
        <div className={styles.buttons}>
          <div className={styles['button-wrapper']}>
            <OriginButton
              variant="secondary-border"
              onClick={handleBack}
              label={PROFILE_DETAILS.BUTTONS.CANCEL_BUTTON_LABEL}
            />
          </div>
          <div className={styles['button-wrapper']}>
            <OriginButton
              htmlType="submit"
              disabled={!isDirty}
              label={PROFILE_DETAILS.BUTTONS.SAVE_BUTTON_LABEL}
              loading={isLoading}
            />
          </div>
        </div>
      )
    } else {
      return (
        <div className={styles['complete-button']}>
          <OriginButton
            htmlType="submit"
            label={COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.BUTTON_LABEL}
            variant="green"
            size="large"
          />
        </div>
      )
    }
  }

  return (
    <Form
      initValues={initialValues}
      onSubmit={onSubmit}
      className={styles['form-wrapper']}
      formId="profile-details-form"
    >
      {({ isDirty, errors, values }: FormChildren) => (
        <>
          {isCompleteModalOpen && (
            <Confirmation
              confirmModalProps={{
                question: COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.QUESTION,
                onClose: () => setCompleteModalOpen(false),
                onConfirm: handleConfirm,
                title: COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.TITLE,
                confirmLabel:
                  COMPLETE_CONFIRMATION.CONFIRMATION_MODAL.BUTTON_LABEL,
              }}
            />
          )}
          <ProfileDetailsForm
            errors={errors}
            values={values as ProfileDetailsFormProps['values']}
            readOnly={readOnly}
          />
          {renderButtons(isDirty)}
        </>
      )}
    </Form>
  )
}
