import { addQueryString, Filter } from 'utils/addQueryString'
import { EmptyResponse, ErrorResponse, ContentType } from '../types'
import { request } from '../utils'
import { PATH } from './constants'
import {
  GetAccountStatusesResponse,
  GetCompanyInfoResponse,
  GetCompanyListResponse,
  GetCompanyShortInfoResponse,
  UpdateCompanyInfoRequest,
} from './types'
import { EnvironmentVariablesService } from 'utils/env'

class Api {
  public baseUrl = EnvironmentVariablesService.getEnv('REACT_APP_API_URL') || ''

  async getCompanyList(
    pageIndex: number,
    pageSize: number,
    archived = false,
    filters?: Filter[],
    sorting?: string
  ) {
    return request<GetCompanyListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(
        PATH.COMPANY_LIST,
        {
          archived,
          pageSize,
          pageIndex,
        },
        filters,
        sorting
      ),
      { method: 'GET' }
    )
  }

  async closeCompany(companyId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.COMPANY}${companyId}`,
      { method: 'DELETE' }
    )
  }

  async getCompanyInfo(companyId: number) {
    return request<GetCompanyInfoResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.COMPANY}${companyId}`,
      { method: 'GET' }
    )
  }

  async createNewCompany(companyData: UpdateCompanyInfoRequest) {
    return request<GetCompanyInfoResponse, ErrorResponse>(
      this.baseUrl,
      PATH.NEW_COMPANY,
      { method: 'POST' },
      companyData,
      'json',
      ContentType.FORM_DATA
    )
  }

  async updateCompany(id: number, companyData: UpdateCompanyInfoRequest) {
    return request<GetCompanyInfoResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.COMPANY}${id}`,
      { method: 'PUT' },
      companyData,
      'json',
      ContentType.FORM_DATA
    )
  }

  async restoreCompany(companyId: number) {
    return request<GetCompanyInfoResponse, ErrorResponse>(
      this.baseUrl,
      `company/${companyId}/restore`,
      { method: 'POST' }
    )
  }

  async getCompanyShortInfo(companyId: number) {
    return request<GetCompanyShortInfoResponse, ErrorResponse>(
      this.baseUrl,
      `company/${companyId}/shortInfo`
    )
  }

  async getAccountStatuses() {
    return request<GetAccountStatusesResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.FILTERS_DATA, {
        archived: false,
      }),
      { method: 'GET' }
    )
  }

  async approveCompany(companyId: string) {
    return request<void, ErrorResponse>(
      this.baseUrl,
      `company/${companyId}/approve`,
      { method: 'POST' }
    )
  }

  async completeProfile(companyId: string) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.COMPLETE_PROFILE, {
        companyId,
      }),
      { method: 'POST' }
    )
  }
}

export const profileApi = new Api()
