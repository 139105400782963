import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const DashboardIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 20 20">
    <path
      d="M2.85697 9.4048H7.61887C7.94761 9.4048 8.21411 9.6713 8.21411 10V17.1429C8.21411 17.4716 7.94761 17.7381 7.61887 17.7381H2.85697C2.52828 17.7381 2.26173 17.4716 2.26173 17.1429V10C2.26173 9.6713 2.52828 9.4048 2.85697 9.4048Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M2.85697 2.26186H7.61887C7.94761 2.26186 8.21411 2.52835 8.21411 2.85709V5.24995C8.21411 5.57869 7.94761 5.84519 7.61887 5.84519H2.85697C2.52828 5.84519 2.26173 5.57869 2.26173 5.24995V2.85709C2.26173 2.52835 2.52828 2.26186 2.85697 2.26186Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.3809 2.26186H17.1428C17.4715 2.26186 17.738 2.52835 17.738 2.85709V9.99995C17.738 10.3287 17.4715 10.5952 17.1428 10.5952H12.3809C12.0522 10.5952 11.7857 10.3287 11.7857 9.99995V2.85709C11.7857 2.52835 12.0522 2.26186 12.3809 2.26186Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M12.3809 14.1547H17.1428C17.4715 14.1547 17.738 14.4211 17.738 14.7499V17.1428C17.738 17.4714 17.4715 17.738 17.1428 17.738H12.3809C12.0522 17.738 11.7857 17.4714 11.7857 17.1428V14.7499C11.7857 14.4211 12.0522 14.1547 12.3809 14.1547Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
