export const HEAD_ITEMS = {
  RESERVATION_NUMBER: 'Reservation number',
  PARTNER_NUMBER: 'Partner number',
  STATUS: 'Status',
  RESERVED_DATE: 'Reserved date',
  CONFIRMATION_DATE: 'Confirmation date',
  PICKUP_LOCATION: 'Pick-up location',
  PICKUP_DATE: 'Pick-up date',
  DROPOFF_LOCATION: 'Drop-off location',
  DROPOFF_DATE: 'Drop-off date',
  DURATION_IN_DAYS: 'Duration (in days)',
  CAR_PRICE: 'Car price',
  COMPANY_CLASS: 'Company class',
  SIPP_CODE: 'SIPP code',
  PREPAID_EXTRAS: 'Prepaid extras',
  PAY_ON_ARRIVAL_EXTRAS: 'Pay on arrival extras',
  CLIENT_NAME_AND_SURNAME: 'Client name and surname',
}
