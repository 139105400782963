import { getRequiredMessage } from 'utils/getRequiredMessage'

export const FORM_FIELDS_VALUES = {
  EMAIL: {
    ID: 'email',
    PLACEHOLDER: 'Please enter your email',
    LABEL: 'Email',
  },
  PASSWORD: {
    ID: 'password',
    PLACEHOLDER: 'Please enter your password',
    LABEL: 'Password',
  },
  CONFIRM_PASSWORD: {
    ID: 'confirmPassword',
    PLACEHOLDER: 'Please confirm your password',
    LABEL: 'Confirm password',
  },
  CODE: {
    ID: 'verificationCode',
    PLACEHOLDER: 'Please enter a verification code',
    LABEL: 'Verification code',
    REQUIRED_MESSAGE: getRequiredMessage('validation code'),
  },
}

export const FIELDS = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm password',
  CODE: 'code',
}

export const FORM_FIELDS_RULES = {
  PASSWORD: {
    MATCH: 'Passwords should match',
    INVALID: 'Password does not meet the requirements',
  },
  EMAIL: 'Your email is incorrect',
}
