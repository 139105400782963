import { CheckedIcon } from 'ui/icons'
import { Typography } from '@frontend/design_system'
import { VEHICLE_BLOCK_FIELDS } from './constants'
import { NOT_AVAILABLE } from 'modules/Fleet/components/Vehicles/constants'
import { VehicleInformationBlockProps } from './types'
import styles from './styles.module.scss'

export const VehicleInformationBlock = ({
  values,
}: VehicleInformationBlockProps) => {
  const renderItem = (id: string, value?: string) => {
    const formatValue = value === NOT_AVAILABLE ? '' : value

    return (
      <div
        className={styles['vehicle-item-info']}
        key={VEHICLE_BLOCK_FIELDS[id]}
      >
        <div className={styles['vehicle-item-info-icon']}>
          <CheckedIcon
            color={formatValue ? 'green700Master' : 'grey500'}
            size="medium"
          />
        </div>

        <Typography
          name="body1WBold"
          className={styles['vehicle-item-info-block']}
        >
          {VEHICLE_BLOCK_FIELDS[id]}:
          <Typography
            name="body1WMedium"
            className={styles['vehicle-item-info-value']}
          >
            {formatValue || '-'}
          </Typography>
        </Typography>
      </div>
    )
  }

  return (
    <div>
      {Object.keys(VEHICLE_BLOCK_FIELDS).map((key: string) => {
        const value = values[key as keyof typeof values]
        return renderItem(key, value as string)
      })}
    </div>
  )
}
