import { MENU_NAMES } from 'texts/menuNames'
import { Container } from 'ui/molecules/Container'
import { Table } from 'ui/molecules/Table'
import { TABLE_HEAD_ITEMS } from './constants'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { useEffect, useMemo, useState } from 'react'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { Pagination, Typography } from '@frontend/design_system'
import { Modal } from 'ui/molecules/Modal'
import { MaximizeIcon, ModalCloseIcon } from 'ui/icons'
import { useApiRequest } from 'hooks/useApiRequest'
import { bookingHistoryApi, BookingHistoryTypes } from 'api'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector } from 'redux/login/selectors'
import { prepareColumnsWidth } from './utils'
import styles from './styles.module.scss'

export const BookingHistoryList = () => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [bookingHistory, setBookingHistory] = useState<
    BookingHistoryTypes.GetBookingHistoryListResponse['reservations'] | null
  >(null)
  const isOperator = useAppSelector(isOperatorSelector)

  const { companyId } = useCompanyInfo(isOperator)

  const getBookingHistoryListRequest = useApiRequest((companyId: string) =>
    bookingHistoryApi.getBookingHistoryList(
      Number(pageSize),
      page - 1,
      companyId
    )
  )

  useEffect(() => {
    if (companyId) {
      const fetchBookingHistory = async () => {
        const response = await getBookingHistoryListRequest.apiRequest(
          companyId
        )
        if (response) {
          setBookingHistory(response.data.reservations)
        }
      }
      fetchBookingHistory()
    }
  }, [companyId])

  const handleChangePage = (pageNumber: number) => {
    setPage(pageNumber)
  }
  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const onOpenModal = () => {
    setIsFullScreen(true)
  }

  const onCloseModal = () => {
    setIsFullScreen(false)
  }

  const formattedBodyItems = useMemo(
    () =>
      bookingHistory?.pageItems.map((booking) => ({
        items: [
          booking.reservationNumber,
          booking.partnerNumber,
          booking.reservationStatus,
          booking.reservedDate,
          booking.partnerApproveDate,
          booking.pickUpLocationName,
          booking.pickUpDate,
          booking.dropOffLocationName,
          booking.dropOffDate,
          booking.duration,
          booking.netPrice,
          booking.companyCarClass,
          booking.sippCode,
          booking.prepaidExtras.map((el) => (
            <Typography
              name="Subtitle7"
              className={styles.extra}
            >{`${el.price} ${el.currencyCode} - ${el.extraName} (x${el.quantity})`}</Typography>
          )),
          booking.supplierExtras.map((el) => (
            <Typography name="Subtitle7" Tag="p" className={styles.extra}>
              {`${el.price} ${el.currencyCode}`}&nbsp;-&nbsp;
              {`${el.extraName} (x${el.quantity})`}
            </Typography>
          )),
          booking.clientFirstName + ' ' + booking.clientLastName,
        ],
      })),
    [bookingHistory]
  )

  const renderTable = () => (
    <>
      <div className={styles.list}>
        <div className={styles.header}>
          {isFullScreen ? (
            <ModalCloseIcon
              size="medium"
              color="blue700"
              onClick={onCloseModal}
            />
          ) : (
            <MaximizeIcon
              color={
                bookingHistory?.pageItems.length === 0
                  ? 'blueSecondary'
                  : 'blue700'
              }
              size="large"
              onClick={onOpenModal}
              disabled={bookingHistory?.pageItems.length === 0}
            />
          )}
        </div>
        <Table
          headItems={TABLE_HEAD_ITEMS}
          bodyItems={formattedBodyItems}
          initItemsWidth={prepareColumnsWidth()}
        />
      </div>
      <footer className={styles.pagination}>
        <Pagination
          current={page}
          pageItemCount={bookingHistory?.pageItems}
          total={bookingHistory?.totalItems}
          pageSize={pageSize}
          changePageSize={changePageSize}
          changePage={handleChangePage}
          pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
        />
      </footer>
    </>
  )

  return (
    <>
      <Container title={MENU_NAMES.BOOKING_HISTORY}>{renderTable()}</Container>
      {isFullScreen && (
        <Modal
          position="center-horizontal"
          onClose={onCloseModal}
          offsetVariant="none"
          invisibleTitle
          invisibleHeader
          invisibleClose
          headerSize="large"
        >
          <div className={styles['full-screen-modal']}>{renderTable()}</div>
        </Modal>
      )}
    </>
  )
}
