import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'

const restrictedItems = [
  MENU_NAMES.EQUIPMENTS,
  MENU_NAMES.PRICE_LISTS,
  MENU_NAMES.STOP_SALES,
  MENU_NAMES.TERMS_AND_CONDITIONS,
  MENU_NAMES.DROP_OFF_FEE,
  MENU_NAMES.DASHBOARD,
  MENU_NAMES.BOOKING_HISTORY,
]

export const useDisableMenuItems = () => {
  const { accountStatus } = useAppSelector(userDataSelector)
  const isOperator = useAppSelector(isOperatorSelector)

  const disableMenuItem = (name: string) =>
    !isOperator &&
    !!accountStatus &&
    accountStatus !== 'Approved' &&
    restrictedItems.includes(name)

  return { disableMenuItem }
}
