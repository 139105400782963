export const DIAGRAMS = {
  CAR_RENTAL_DAYS: 'Car rental days',
  REVENUE: 'Revenue',
  REVENUE_PER_DAY: 'Revenue per day',
  REVENUE_PER_INIT: 'Revenue per reservation',
  RESERVATIONS: 'Reservations',
  RENTAL_DAYS: 'Rental days',
  TOTAL_REVENUE: 'Total revenue',
}
export const CAR_RENTAL_DAYS_FIELDS = {
  DATE: 'Date',
  COMPLETED: 'Completed',
  SCHEDULED: 'Scheduled',
}

export const TOTAL = 'TOTAL'
export const TOOLTIP = 'Tooltip'

export const REVENUE_PER_DAY_TABLE_ITEMS = [
  CAR_RENTAL_DAYS_FIELDS.DATE,
  DIAGRAMS.REVENUE_PER_DAY,
  DIAGRAMS.REVENUE,
  DIAGRAMS.RENTAL_DAYS,
]

export const DASHBOARD_TABS = {
  CAR_RENTAL_DAY: DIAGRAMS.CAR_RENTAL_DAYS,
  FINANCIAL_INFO: 'Financial info',
}

export const DASHBOARD_SUB_TABS = {
  PER_DAY: 'Revenue per day',
  PER_UNIT: 'Revenue per unit',
  REVENUE: 'Total revenue',
}

export const CAR_RENTAL_TABLE_ITEMS = [
  CAR_RENTAL_DAYS_FIELDS.DATE,
  CAR_RENTAL_DAYS_FIELDS.COMPLETED,
  CAR_RENTAL_DAYS_FIELDS.SCHEDULED,
]
export const REVENUE_TABLE_ITEMS = ['Car type', 'Revenue', 'Reservations']
export const REVENUE_PER_UNIT_TABLE_ITEMS = [
  'Car type',
  'Revenue per reservation',
  'Revenue',
  'Reservations',
]
