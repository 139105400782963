import { profileApi } from 'api'
import { useCompanyId } from 'hooks/useCompanyId'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  setAccountStatus,
  setSectionStatuses,
  setSupplierCurrency,
} from 'redux/login/slice'
import { supplierCompanyNameSelector } from 'redux/profile/selectors'
import { setCompanyName } from 'redux/profile/slice'

export const useCompanyInfo = (isOperator: boolean) => {
  const dispatch = useDispatch()
  const companyName = useSelector(supplierCompanyNameSelector)
  const params = useParams()
  const supplierCompanyId = useCompanyId()

  const companyId = supplierCompanyId || params.companyId

  const getCompanyInfo = async (id: number) => {
    const { data } = await profileApi.getCompanyShortInfo(id)
    dispatch(setCompanyName(data.companyName || ''))
    dispatch(setAccountStatus(data.accountStatus))
    dispatch(setSectionStatuses(data.sectionsStatuses))
    dispatch(setSupplierCurrency(data.accountCurrency))
  }

  useEffect(() => {
    if (isOperator && companyId) {
      getCompanyInfo(Number(companyId))
    }
  }, [isOperator, companyId])

  return { companyName, companyId, getCompanyInfo }
}
