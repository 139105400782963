import { Typography } from '@frontend/design_system'
import { ArrowUpRightIcon, CheckedIcon } from 'ui/icons'
import { joinClasses } from 'utils/joinClasses'
import { useNavigate } from 'react-router-dom'
import { PendingCardProps } from './types'
import styles from './styles.module.scss'

export const PendingCard = ({
  Icon,
  title,
  description,
  isDone,
  path,
}: PendingCardProps) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (path && !isDone) {
      navigate(path)
    }
  }

  return (
    <div
      className={joinClasses(styles.container, [styles.done, isDone])}
      onClick={handleClick}
    >
      <div className={styles.header}>
        {Icon}
        <Typography name="body2WMedium" className={styles.title}>
          {title}
        </Typography>
        {isDone ? (
          <div className={styles.checked}>
            <CheckedIcon size="medium" />
          </div>
        ) : (
          <ArrowUpRightIcon size="medium" color="blue700" />
        )}
      </div>
      {!isDone && (
        <div className={styles.description}>
          <Typography name="body2WMedium" color="grey500">
            {description}
          </Typography>
        </div>
      )}
    </div>
  )
}
