import { ContextType } from 'ui/molecules/Form/types'
import { Context, useContext } from 'react'

export const useCheckRequiredFields = (
  requiredFields: string[],
  context: Context<ContextType>
) => {
  const { values } = useContext<ContextType>(context)

  const checkFields = () =>
    requiredFields.every(
      (key) => values[key] !== 'undefined' && values[key] !== ''
    )

  return {
    isDisabledFormButton: !checkFields(),
  }
}
