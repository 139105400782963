import { PriceListType } from 'api/fleet/types'
import { FormValues } from './types'

export const initialFormValues: FormValues = {
  hirePoints: [],
  carGroup: '',
  sipp: '',
  seasonRanges: [],
  dailyRanges: [],
  year: '',
  comment: '',
  priceListType: PriceListType.MapBySipp,
  isActive: false,
  countriesOfResidence: [],
}

export const APPLY_BY = {
  [PriceListType.MapBySipp]: 'SIPP code',
  [PriceListType.MapByCompanyClass]: 'Company class',
}
