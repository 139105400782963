import { useEffect, useMemo, useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { fleetApi } from 'api'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useReadOnlyMode } from '../../hooks/useReadOnlyMode'
import { Container } from 'ui/molecules/Container'
import { isOperatorSelector } from 'redux/login/selectors'
import { PriceListDetailsForm } from '../PriceListDetailsForm'
import { generateSimpleUniqueId } from 'utils/generateUniqueId'
import { FormValues } from '../PriceListDetailsForm/types'
import { DailyRange, FullPriceList, PriceListType } from 'api/fleet/types'
import {
  PRICE_LIST_DETAILS,
  PRICE_LIST_DETAILS_BREADCRUMB,
  PRICE_LIST_TITLE,
} from 'texts/priceListDetails'
import { replaceLastPath } from 'utils/replaceLastPath'
import { formatSeasonRange } from '../../utils/formatSeasonRange'
import { useApiRequest } from 'hooks/useApiRequest'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { SAVE } from 'texts/uiTexts'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { prepareResponseItems, prepareCorForSelect } from 'utils/form'

export const PriceListDetails = () => {
  const [priceListInfo, setPriceListInfo] = useState<Partial<FullPriceList>>({})
  const { priceListId } = useParams()
  const location = useLocation()
  const isReadOnlyMode = useReadOnlyMode(priceListInfo.year)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isOperator = useAppSelector(isOperatorSelector)

  const { companyId } = useCompanyInfo(isOperator)

  const priceListDataRequest = useApiRequest((priceListId) =>
    fleetApi.getPriceListDetails(priceListId)
  )
  const updatePriceListRequest = useApiRequest(
    (values) =>
      fleetApi.updatePriceList(
        priceListId as string,
        companyId as string,
        values
      ),
    undefined,
    true,
    undefined,
    false
  )

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'priceLists'
  )

  const navigateToRates = () => {
    navigate(replaceLastPath(location.pathname, '/rates'))
  }

  useEffect(() => {
    if (priceListId) {
      const getPriceListDetails = async () => {
        const response = await priceListDataRequest.apiRequest(priceListId)
        if (response) {
          setPriceListInfo(response.data.priceListInfo)
        }
      }
      getPriceListDetails()
    }
  }, [priceListId])

  const handleSubmit = async (data: Partial<FormValues>) => {
    const values = {
      ...data,
      priceListType: data.priceListType as PriceListType,
      isActive: priceListInfo.isActive,
      hirePoints:
        data.hirePoints?.map((hirePoint) => Number(hirePoint.value)) || [],
      seasonRanges: data.seasonRanges
        ? formatSeasonRange(data.seasonRanges)
        : [],
      countriesOfResidence: prepareResponseItems(
        data.countriesOfResidence || []
      ),
    }

    const response = await updatePriceListRequest.apiRequest(values)
    if (response) {
      dispatch(
        setNotificationMessage({
          notificationMessage: PRICE_LIST_DETAILS.SUCCESS_UPDATE,
        })
      )
    }
  }

  const prepareDailyRanges = (dailyRanges: DailyRange[]) => {
    const copiedDailyRanges = structuredClone(dailyRanges)
    const lastIndex = copiedDailyRanges.length - 1
    if (!copiedDailyRanges[lastIndex].rangeEnd) {
      copiedDailyRanges[lastIndex].rangeEnd = '+'
    }
    return copiedDailyRanges
  }

  const initValues = useMemo(
    () => ({
      hirePoints:
        priceListInfo.hirePoints?.map((el) => ({
          label: String(el),
          value: String(el),
        })) || [],
      year: priceListInfo.year,
      dailyRanges: priceListInfo.dailyRanges
        ? prepareDailyRanges(priceListInfo.dailyRanges).map((item) => ({
            ...item,
            uniqueId: generateSimpleUniqueId(),
          }))
        : [],
      seasonRanges:
        priceListInfo?.seasonRanges?.map((item) => ({
          ...item,
          uniqueId: generateSimpleUniqueId(),
        })) || [],
      priceListType: priceListInfo.priceListType,
      comment: priceListInfo.comment || '',
      countriesOfResidence: prepareCorForSelect(
        priceListInfo.countriesOfResidence || []
      ),
    }),
    [priceListInfo]
  )

  return !priceListDataRequest.loading ? (
    <Container
      title={PRICE_LIST_TITLE}
      breadcrumbList={breadcrumbList}
      currentPageLabel={PRICE_LIST_DETAILS_BREADCRUMB}
      withNavigation={isOperator}
    >
      <PriceListDetailsForm
        readOnly={isReadOnlyMode}
        handleSubmit={handleSubmit}
        initialValues={initValues}
        isLoading={updatePriceListRequest.loading}
        submitButtonLabel={SAVE}
        navigateToRates={navigateToRates}
      />
    </Container>
  ) : null
}
