import { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LazyImage } from '@frontend/design_system'
import lockIconSrc from 'assets/icons/grey400/grey400_lock_20x20.webp'
import { Props } from './types'
import { Typography } from 'ui/atoms/Typography'
import { joinClasses } from 'utils/joinClasses'
import { ArrowBottomIcon } from 'ui/icons'
import styles from './styles.module.scss'

export const MenuItem = memo(
  ({
    iconSrc,
    name,
    activeItem,
    onMenuItemClick,
    path,
    children,
    withSubmenu = false,
    disabled,
    openedMenuItem,
  }: Props) => {
    const navigate = useNavigate()
    const [isSubmenuVisible, setSubmenuVisible] = useState(true)

    const isMenuItemActive = activeItem === name

    useEffect(() => {
      if (openedMenuItem) {
        setSubmenuVisible(openedMenuItem === name)
      }
    }, [openedMenuItem])

    const handleClick = () => {
      if (withSubmenu) {
        setSubmenuVisible(!isSubmenuVisible)
      } else {
        onMenuItemClick(name)
      }

      if (path && !withSubmenu) {
        navigate(path)
      }
    }

    const getLabelColor = () => {
      if (disabled) {
        return 'grey400'
      }
      if (isMenuItemActive) {
        return 'blue700'
      }
      return 'blackMaster'
    }

    return (
      <div className={styles['menu-item-wrapper']}>
        <div
          className={joinClasses(
            styles['menu-item'],
            [styles.active, isMenuItemActive],
            [styles['with-submenu'], withSubmenu],
            [styles.disabled, disabled]
          )}
          onClick={!disabled ? handleClick : () => {}}
          role="menuitem"
        >
          <div className={styles['menu-item-label']}>
            {iconSrc && <LazyImage width={20} height={20} src={iconSrc} />}
            <Typography
              className={styles.title}
              color={getLabelColor()}
              name="Button2"
            >
              {name}
            </Typography>
          </div>
          {disabled && <LazyImage width={20} height={20} src={lockIconSrc} />}
          {withSubmenu && (
            <ArrowBottomIcon
              color={isSubmenuVisible ? 'blue700' : 'grey400'}
              className={joinClasses(styles['arrow-icon'], [
                styles['submenu-opened'],
                isSubmenuVisible,
              ])}
            />
          )}
        </div>
        {isSubmenuVisible && <div className={styles.submenu}>{children}</div>}
      </div>
    )
  }
)
