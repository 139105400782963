import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const CheckedIcon = ({ size, color = 'green700Master' }: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 20 20">
    <rect
      x="2"
      y="2"
      width="16"
      height="16"
      rx="8"
      fill={TYPOGRAPHY_COLORS[color]}
    />
    <path
      d="M14 7.3324L8.5 12.6657L6 10.2415"
      stroke={TYPOGRAPHY_COLORS.whiteMaster}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
