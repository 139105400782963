import { useCallback, useEffect, useMemo, useState } from 'react'
import { MENU_NAMES } from 'texts/menuNames'
import { PROFILE_LIST } from 'texts/profileList'
import { Container } from 'ui/molecules/Container'
import { DaysPicker, Tabs, TabPane, Typography } from '@frontend/design_system'
import { ApplicationList } from '../ApplicationList'
import {
  APPLICATION_SEARCH_PARAM,
  APPLICATION_SHOWN_STATUS,
} from 'modules/Applications/constants'
import styles from './styles.module.scss'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedApplicationsRange } from 'redux/applications/slice'
import { format } from 'date-fns'
import { STOP_SALES_DATE_FORMAT } from 'constants/date'
import { selectedRangeSelector } from 'redux/applications/selectors'
import { useApiRequest } from 'hooks/useApiRequest'
import { applicationsApi } from 'api'
import { StatusCountersResponse } from 'api/applications/types'
import { SP_STATUS } from 'constants/searchParams'
import { ApplicationStatusSearchParam } from '../ApplicationList/types'
import { formatStatus } from './utils'
import {
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import { Filter } from 'utils/addQueryString'
import { FILTER_KEYS } from '../ApplicationList/constants'

export const AllApplications = () => {
  const [activeKey, setActiveKey] = useState<ApplicationStatusSearchParam>(
    APPLICATION_SEARCH_PARAM.WaitingList
  )
  const [, setSearchParams] = useSearchParams()
  const selectedRange = useSelector(selectedRangeSelector)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const dispatch = useDispatch()
  const [statusCounter, setStatusCounter] = useState<StatusCountersResponse>([])

  const statusCountersRequest = useApiRequest((fromDate, toDate, filters) =>
    applicationsApi.getStatusCounter(fromDate, toDate, filters)
  )

  const renderTab = (key: string, index: number) => (
    <div className={styles['tab-content']}>
      <span>{key}</span>
      <Typography
        color="blue700"
        name="caption2WBold"
        className={styles.counter}
      >
        {statusCounter[index]?.count}
      </Typography>
    </div>
  )

  useEffect(() => {
    setSearchParams({ [SP_STATUS]: APPLICATION_SEARCH_PARAM.WaitingList })
  }, [])

  useEffect(() => {
    if (selectedRange.from && selectedRange.to) {
      const fetchCounters = async () => {
        let filters: Filter[] = []

        if (selectedFiltersCount) {
          filters = Object.entries(selectedFilters.applications).map(
            ([key, value]) => ({
              name: FILTER_KEYS[key],
              type: 'In',
              value: value.join(','),
            })
          )
        }

        const statusesResponse = await statusCountersRequest.apiRequest(
          selectedRange.from,
          selectedRange.to,
          filters && filters
        )

        if (statusesResponse) {
          setStatusCounter(statusesResponse.data.statuses)
        }
      }
      fetchCounters()
    }
  }, [selectedRange, selectedFiltersCount])

  const handleClick = (activeKey: ApplicationStatusSearchParam) => {
    setActiveKey(activeKey)
    setSearchParams({ [SP_STATUS]: activeKey })
  }

  const tabs = useMemo(
    () =>
      Object.entries(APPLICATION_SHOWN_STATUS).map(([key, value], i) => ({
        tab: renderTab(value, i),
        key: key,
        children: (
          <ApplicationList
            activeKey={activeKey}
            status={formatStatus(key as ApplicationStatusSearchParam)}
            selectedRange={selectedRange}
          />
        ),
      })),
    [statusCounter]
  )

  const handleSelectedRange = useCallback(
    (newValues: { from: Date; to: Date }) => {
      dispatch(
        setSelectedApplicationsRange({
          from: format(newValues.from, STOP_SALES_DATE_FORMAT),
          to: newValues.to ? format(newValues.to, STOP_SALES_DATE_FORMAT) : '',
        })
      )
    },
    []
  )

  const daysPickerRange = useMemo(
    () => ({
      from: selectedRange.from ? new Date(selectedRange.from) : null,
      to: selectedRange.to ? new Date(selectedRange.to) : null,
    }),
    [selectedRange]
  )

  return (
    <Container
      title={MENU_NAMES.APPLICATIONS}
      rightBlock={
        <div className={styles['date-picker']}>
          <DaysPicker
            range={daysPickerRange}
            initialFrom={selectedRange.from}
            initialTo={selectedRange.to}
            size="small"
            handleRangeSelect={handleSelectedRange}
            variant="secondary"
          />
        </div>
      }
      tooltip={PROFILE_LIST.TOOLTIP_CONTENT}
      withCompanyName={false}
    >
      <Tabs activeKey={activeKey} onChange={handleClick} type="marketplace">
        {tabs.map((tab) => (
          <TabPane id={tab.tab} key={tab.key} tab={tab.tab}>
            <div className={styles['tab-children']}>{tab.children}</div>
          </TabPane>
        ))}
      </Tabs>
    </Container>
  )
}
