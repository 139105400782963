import { URLS } from 'constants/urls'
import { Route, Routes } from 'react-router-dom'
import { ApplicationsArchive } from './components/ApplicationsArchive/'
import { Application } from './components/ApplicationList/components/Application'
import { AllApplications } from './components/AllApplications'
import { NotFound } from 'ui/molecules/NotFound'
import { ApplicationsDuplicate } from './components/ApplicationsDuplicate/ApplicationsDuplicate'

export const Applications = () => (
  <Routes>
    <Route path={URLS.APPLICATIONS_LIST} element={<AllApplications />} />
    <Route path={URLS.APPLICATION} element={<Application />} />
    <Route path={URLS.APPLICATIONS_ARCHIVE} element={<ApplicationsArchive />} />
    <Route
      path={`${URLS.APPLICATIONS_ARCHIVE}/:id`}
      element={<Application variant="archive" />}
    />

    <Route
      path={`${URLS.APPLICATION}${URLS.APPLICATIONS_DUPLICATE}`}
      element={<ApplicationsDuplicate />}
    />
    <Route
      path={`${URLS.APPLICATION}${URLS.APPLICATIONS_DUPLICATE}/:duplicateId`}
      element={<Application variant="duplicate" />}
    />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
