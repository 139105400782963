import { useMemo, useState } from 'react'
import { Typography } from 'ui/atoms/Typography'
import { Dropdown } from 'ui/atoms/Dropdown'
import { Tip } from './components/Tip/Tip'
import { ALL, TIPS_TOPICS, MOCK_TIPS as TIPS } from 'texts/tips'
import styles from './styles.module.scss'
import { useAppSelector } from 'redux/hooks'
import { userDataSelector } from 'redux/login/selectors'

interface ITipsProps {
  onToggle: () => void
}

export const Tips = ({ onToggle }: ITipsProps) => {
  const [activeTopic, setActiveTopic] = useState(ALL)
  const { accountStatus } = useAppSelector(userDataSelector)
  const isApprovedUser = accountStatus === 'Approved'

  let currentTips: typeof TIPS = []

  if (!isApprovedUser) {
    currentTips = TIPS.filter((el) => !el.hiddenForUnapprovedUser)
  } else {
    currentTips = TIPS
  }

  const filteredTips = useMemo(
    () =>
      activeTopic === ALL
        ? currentTips
        : currentTips.filter((el) => el.group === activeTopic),
    [activeTopic]
  )

  return (
    <div className={styles.tips}>
      <div className={styles.dropdown}>
        <Dropdown
          value={activeTopic}
          defaultValue={ALL}
          renderLabel={(value) => (
            <Typography name="Button1" color="blue700">
              {value}
            </Typography>
          )}
          items={TIPS_TOPICS}
          onChange={setActiveTopic}
          noBorder
        />
      </div>
      {filteredTips.map(
        ({
          topic,
          buttonLabel,
          startDescription,
          endDescription,
          subTitle,
          title,
          linkForButton,
        }) => (
          <Tip
            topic={topic}
            key={topic}
            buttonLabel={buttonLabel}
            startDescription={startDescription}
            endDescription={endDescription}
            title={title}
            closeTips={onToggle}
            subTitle={subTitle}
            linkForButton={linkForButton}
          />
        )
      )}
    </div>
  )
}
