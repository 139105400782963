import { useNavigate } from 'react-router-dom'
import { MENU_NAMES } from 'texts/menuNames'
import { Typography } from 'ui/atoms/Typography'
import { Autocomplete } from 'ui/atoms/Autocomplete'
import { Button } from 'ui/atoms/Button'
import { InfoIcon, PlusIcon } from 'ui/icons'
import { Pagination } from '@frontend/design_system'
import { DELETE_LOCATION_MODAL, LIST_OF_LOCATIONS } from 'texts/locationList'
import { useEffect, useState } from 'react'
import { AutocompleteItem } from 'ui/atoms/Autocomplete/types'
import { locationsApi, LocationsApiTypes } from 'api'
import { headItems } from './constants'
import { Table } from 'ui/molecules/Table'
import { Toggle } from 'ui/atoms/Toggle'
import { breadcrumbs } from 'constants/breadcrumbs'
import { ModalWrapper } from './components/ModalWrapper'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { VehicleListModal } from './components/VehicleListModal'
import { EquipmentListModal } from './components/EquipmentListModal'
import { Container } from 'ui/molecules/Container'
import { Tooltip } from 'ui/atoms/Tooltip'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { PendingForApproval } from 'modules/PendingForApproval'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { ACCOUNT_STATUSES, REGISTRATION_STATUSES } from 'api/auth/constants'
import { AUTOCOMPLETE_LABEL, PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { URLS } from 'constants/urls'
import { useApiRequest } from 'hooks/useApiRequest'
import styles from './styles.module.scss'
import {
  LOCATION_EDIT_CONFIRMATION,
  SUCCESS_ACTIVATE,
  SUCCESS_INACTIVATE,
} from 'texts/locationEdit'
import { Confirmation } from 'ui/molecules/Confirmation'
import { HirePoint } from 'api/locations/types'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import { ApproveBtn } from 'ui/components/ApproveBtn'

export const LocationsList = () => {
  const dispatch = useDispatch()
  const [filteredCompanies, setFilteredCompanies] = useState<
    AutocompleteItem[]
  >([])
  const [locationsList, setLocationsList] =
    useState<LocationsApiTypes.GetHirePointsResponse | null>(null)
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<HirePoint>()
  const { accountStatus, sectionsStatuses } = useAppSelector(userDataSelector)
  const isOperator = useAppSelector(isOperatorSelector)
  const isAccountApproved =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED

  const navigate = useNavigate()

  const breadcrumbsList = [breadcrumbs.profileList]

  const updateCompanyStatusRequest = useApiRequest((id, isActive) =>
    locationsApi.changeHirePointState(id, isActive)
  )
  const searchLocationRequest = useApiRequest((companyId, value) =>
    locationsApi.getHirePointsList(0, 0, Number(companyId), [
      { name: 'locationName', type: 'ILike', value },
    ])
  )
  const getHirePointListRequest = useApiRequest((companyId) =>
    locationsApi.getHirePointsList(
      page - 1,
      Number(pageSize),
      Number(companyId)
    )
  )

  const { companyId } = useCompanyInfo(isOperator)

  const handleAddLocation = () => {
    navigate('add')
  }

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const fetchHirePoints = async () => {
    const hirePointListResponse = await getHirePointListRequest.apiRequest(
      Number(companyId)
    )
    if (hirePointListResponse) setLocationsList(hirePointListResponse.data)
  }

  useEffect(() => {
    if (companyId && isAccountApproved) {
      fetchHirePoints()
    }
  }, [page, pageSize, companyId, isAccountApproved])

  useEffect(() => {
    if (
      sectionsStatuses?.locationsStatus === REGISTRATION_STATUSES.INCOMPLETE &&
      accountStatus !== ACCOUNT_STATUSES.APPROVED &&
      isOperator
    ) {
      handleAddLocation()
    }
  }, [sectionsStatuses])

  const handleSearch = async (value: string) => {
    if (companyId) {
      const companyListResponse = await searchLocationRequest.apiRequest(
        companyId,
        value
      )
      if (companyListResponse) {
        const preparedData = companyListResponse.data.hirePoints.pageItems.map(
          (company) => ({
            name: company.locationName || '',
            value: String(company.id),
          })
        )
        setFilteredCompanies(preparedData)
      }
    }
  }

  const handleChangeHirePointStatus = async () => {
    if (selectedLocation) {
      const response = await updateCompanyStatusRequest.apiRequest(
        selectedLocation.id,
        selectedLocation.isClosed
      )
      if (response) {
        dispatch(
          setNotificationMessage({
            notificationMessage: selectedLocation.isClosed
              ? SUCCESS_ACTIVATE
              : SUCCESS_INACTIVATE,
          })
        )
        if (!selectedLocation.isClosed) {
          GTM.dataLayer({
            event: DATA_LAYER_EVENT.LOCATION_REMOVED,
            location_name: selectedLocation.locationName,
            location_country: selectedLocation.country,
            location_city: selectedLocation.city,
          })
        }
        fetchHirePoints()
        setConfirmationModalOpen(false)
      }
    }
  }

  const handleSelect = async (id: string) => {
    const companyListResponse = await locationsApi.getHirePointsList(
      0,
      0,
      Number(companyId),
      [{ name: 'id', type: 'Eq', value: Number(id) }]
    )
    setLocationsList(companyListResponse.data)
  }

  const handleRowClick = (id: number) => {
    navigate(
      `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/${companyId}/${id}/edit`
    )
  }

  const handleDeleteLocation = (location: HirePoint) => {
    setConfirmationModalOpen(true)
    setSelectedLocation(location)
  }

  const formattedBodyItems = locationsList?.hirePoints.pageItems?.map(
    (hirePoint, i) => {
      const isCompanyActive = !hirePoint.isClosed
      return {
        items: [
          hirePoint.locationName,
          <ModalWrapper
            label={hirePoint.availableCarsCount}
            isEmptyModal={!hirePoint.availableCarsCount}
            companyId={Number(companyId)}
            hirePointId={hirePoint.id}
            Modal={VehicleListModal}
          />,
          <ModalWrapper
            label={hirePoint.availableEquipmentsCount}
            isEmptyModal={!hirePoint.availableEquipmentsCount}
            companyId={Number(companyId)}
            hirePointId={hirePoint.id}
            Modal={EquipmentListModal}
          />,
          <div className={styles.toggle}>
            <Toggle
              key={i}
              value={isCompanyActive}
              onChange={() => handleDeleteLocation(hirePoint)}
            />
          </div>,
        ],
        onClick: () => handleRowClick(hirePoint.id),
      }
    }
  )

  const formattedHeadItems = headItems.map((header) => ({ value: header }))

  const header = (
    <>
      {isConfirmationModalOpen && (
        <Confirmation
          confirmModalProps={{
            question: `${
              LOCATION_EDIT_CONFIRMATION.CONFIRMATION_MODAL.QUESTION
            } ${selectedLocation?.locationName}${
              !selectedLocation?.isClosed
                ? LOCATION_EDIT_CONFIRMATION.CONFIRMATION_MODAL.INACTIVE
                : LOCATION_EDIT_CONFIRMATION.CONFIRMATION_MODAL.ACTIVATE
            }`,
            onConfirm: handleChangeHirePointStatus,
            onClose: () => setConfirmationModalOpen(false),
            title: !selectedLocation?.isClosed
              ? `${DELETE_LOCATION_MODAL.DEACTIVATE} ${selectedLocation?.locationName}`
              : `${DELETE_LOCATION_MODAL.ACTIVATE} ${selectedLocation?.locationName}`,
          }}
        />
      )}
      <div className={styles.title}>
        <Typography Tag="h5" name="H5">
          {MENU_NAMES.LOCATIONS.NAME}
        </Typography>
        <div className={styles['tooltip-wrapper']}>
          <Tooltip
            size="primary"
            popupContent={
              <Typography
                Tag="div"
                name="Subtitle4"
                className={styles['popup-content']}
              >
                {LIST_OF_LOCATIONS.TOOLTIP_CONTENT}
              </Typography>
            }
            placement="bottom"
          >
            <InfoIcon color="blue700" size="medium" />
          </Tooltip>
        </div>
      </div>
    </>
  )

  if (!isAccountApproved) {
    return <PendingForApproval />
  }

  return (
    <>
      <Container
        breadcrumbList={isOperator ? breadcrumbsList : []}
        currentPageLabel={MENU_NAMES.LOCATIONS.NAME}
        title={header}
        rightBlock={(id: string) => (
          <div className={styles['right-side']}>
            <ApproveBtn companyId={id} />
            <div className={styles['autocomplete-wrapper']}>
              <Autocomplete
                dropdownEmptyText={
                  LIST_OF_LOCATIONS.SEARCH_DROPDOWN_EMPTY_VALUE
                }
                handleSelect={handleSelect}
                placeholder={LIST_OF_LOCATIONS.AUTOCOMPLETE_PLACEHOLDER}
                data={filteredCompanies}
                borderRadius="8px"
                onIconClick={fetchHirePoints}
                setData={setFilteredCompanies}
                ofLabel={AUTOCOMPLETE_LABEL}
                handleSearch={(value) => handleSearch(value)}
              />
            </div>
          </div>
        )}
        withNavigation={isOperator}
      >
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.button}>
              <Button
                size="small"
                typographyName="Button2"
                variant="outline"
                onClick={handleAddLocation}
                label={LIST_OF_LOCATIONS.BUTTON_LABEL}
                iconPosition="right"
                icon={<PlusIcon color="blue700" />}
              />
            </div>
          </div>
          {formattedBodyItems && (
            <div className={styles.table}>
              <Table
                headItems={formattedHeadItems}
                bodyItems={formattedBodyItems}
              />
            </div>
          )}
        </div>
        <footer className={styles.pagination}>
          <Pagination
            current={page}
            pageItemCount={locationsList?.hirePoints.pageItems.length}
            total={locationsList?.hirePoints?.totalItems || 0}
            pageSize={pageSize}
            changePageSize={changePageSize}
            changePage={handleChange}
            pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
          />
        </footer>
      </Container>
    </>
  )
}
