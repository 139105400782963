import { useMemo } from 'react'
import { Typography, DaysPicker } from '@frontend/design_system'
import {
  STOP_SALES_DATE_FORMAT,
  CAR_RENTAL_DAYS,
  CAR_RENTAL_DAYS_TABLE,
  CAR_RENTAL_DAYS_TABLE_ONE_YEAR,
} from 'constants/date'
import { format, parse } from 'date-fns'
import { formatCarRentalData, groupedCarRentalItems } from '../../utils'
import { MONTHS } from '../../constants'
import {
  COMPLETED_INDEX,
  SCHEDULED_INDEX,
  COMPLETED_TYPE,
  SCHEDULED_TYPE,
} from './constants'
import { DIAGRAMS, CAR_RENTAL_TABLE_ITEMS } from 'texts/dashboard'
import { InfoTable } from '../InfoTable'
import { Diagram } from '../Diagram'
import { CarRentalDaysProps } from './types'
import styles from './styles.module.scss'

export const CarRentalDays = ({
  dateRange,
  handleDateRange,
  initialDates,
  data,
}: CarRentalDaysProps) => {
  const { groupCount, formatData } = formatCarRentalData(data)

  const formattedBodyItems = useMemo(() => {
    if (!data?.items) return []

    const { groupItems, yearsCount } = groupedCarRentalItems(data.items)
    return Object.values(groupItems).map((item) => ({
      items: [
        format(
          parse(item.date, CAR_RENTAL_DAYS, new Date()),
          yearsCount > 1
            ? CAR_RENTAL_DAYS_TABLE
            : CAR_RENTAL_DAYS_TABLE_ONE_YEAR
        ),
        item.completed,
        item.scheduled,
      ],
    }))
  }, [data])

  const formattedFooterItem = useMemo(
    () =>
      data?.items.reduce(
        (acc, item) => {
          if (item.breakdownType === COMPLETED_TYPE) {
            acc[COMPLETED_INDEX] += Number(item.rentalDays)
          }
          if (item.breakdownType === SCHEDULED_TYPE) {
            acc[SCHEDULED_INDEX] += Number(item.rentalDays)
          }
          return acc
        },
        [0, 0]
      ),
    [data]
  )

  return (
    <div className={styles['car-rental']}>
      <div className={styles['diagram']}>
        <div className={styles['diagram-header']}>
          <Typography name="subtitleWBold">
            {DIAGRAMS.CAR_RENTAL_DAYS}
          </Typography>
          <div className={styles['date-picker']}>
            <DaysPicker
              range={dateRange}
              size="small"
              initialFrom={format(
                new Date(initialDates.start),
                STOP_SALES_DATE_FORMAT
              )}
              initialTo={format(
                new Date(initialDates.end),
                STOP_SALES_DATE_FORMAT
              )}
              handleRangeSelect={handleDateRange}
              variant="secondary"
              isAvailableFutureDates
            />
          </div>
        </div>
        <Diagram
          groupCount={groupCount}
          categories={MONTHS}
          series={formatData}
        />
      </div>
      <InfoTable
        bodyItems={formattedBodyItems}
        detailsHeadItems={CAR_RENTAL_TABLE_ITEMS}
        footerItems={formattedFooterItem}
      />
    </div>
  )
}
