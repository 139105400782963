import { phoneNumberRules } from 'constants/form'
import { allSymbolsAndEnglishLettersRegex, mailRegex } from 'constants/regex'
import { CONTACT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { FormValuesSetting } from 'types/form'

export const contactInformationValuesSetting: FormValuesSetting = {
  name: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.NAME.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.NAME.VALIDATION_MESSAGE,
      },
    ],
  },
  postalCode: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.POSTAL_CODE.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.POSTAL_CODE.VALIDATION_MESSAGE,
      },
    ],
  },
  country: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.COUNTRY.REQUIRED_MESSAGE,
      },
    ],
  },
  street: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.STREET.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.STREET.VALIDATION_MESSAGE,
      },
    ],
  },
  building: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.BUILDING.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.BUILDING.VALIDATION_MESSAGE,
      },
    ],
  },
  city: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.CITY.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.CITY.VALIDATION_MESSAGE,
      },
    ],
  },
  mainMail: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: true,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.EMAIL.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: mailRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.EMAIL.VALIDATION_MESSAGE,
      },
      {
        type: 'min',
        value: 5,
        message: CONTACT_INFORMATION_FORM_VALUES.EMAIL.VALIDATION_MESSAGE,
      },
      {
        type: 'max',
        value: 90,
        message: CONTACT_INFORMATION_FORM_VALUES.EMAIL.MAX_VALIDATION_MESSAGE,
      },
    ],
  },
  contactPersonName: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message:
          CONTACT_INFORMATION_FORM_VALUES.CONTACT_PERSON_NAME.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message:
          CONTACT_INFORMATION_FORM_VALUES.CONTACT_PERSON_NAME
            .VALIDATION_MESSAGE,
      },
    ],
  },
  brandName: {
    required: {
      supplier: true,
      operator: true,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.BRAND_NAME.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.BRAND_NAME.VALIDATION_MESSAGE,
      },
    ],
  },
  state: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.CITY.REQUIRED_MESSAGE,
      },
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.CITY.VALIDATION_MESSAGE,
      },
    ],
  },
  phone: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: phoneNumberRules,
  },
  phoneCor: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.PHONE_COR.REQUIRED_MESSAGE,
      },
    ],
  },
  website: {
    required: {
      supplier: false,
      operator: false,
    },
    disabled: {
      supplier: false,
      operator: false,
    },
    validationRules: [
      {
        type: 'pattern',
        value: allSymbolsAndEnglishLettersRegex,
        message: CONTACT_INFORMATION_FORM_VALUES.URL.VALIDATION_MESSAGE,
      },
    ],
  },
}
