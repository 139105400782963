import { CROSS_BORDER_POLICY_FORM_VALUES } from 'texts/termsAndConditions'
import { getRequiredMessage } from 'utils/getRequiredMessage'

export const RADIO_BUTTONS = [
  {
    id: CROSS_BORDER_POLICY_FORM_VALUES.ALLOWED_TO.ID,
    label: CROSS_BORDER_POLICY_FORM_VALUES.ALLOWED_TO.LABEL,
  },
  {
    id: CROSS_BORDER_POLICY_FORM_VALUES.NOT_ALLOWED.ID,
    label: CROSS_BORDER_POLICY_FORM_VALUES.NOT_ALLOWED.LABEL,
  },
]

export const COUNTRY_RULES = [
  {
    type: 'required',
    message: getRequiredMessage(
      CROSS_BORDER_POLICY_FORM_VALUES.COUNTRY_LIST.LABEL
    ),
  },
]
