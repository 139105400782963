import { Typography } from '@frontend/design_system'
import { APPROVAL_INFO } from 'texts/pendingForApproval'
import styles from './styles.module.scss'

export const ApprovalBlock = () => (
  <div className={styles.container}>
    <Typography name="subtitleWBold" className={styles.title}>
      {APPROVAL_INFO.title}
    </Typography>
    <Typography
      name="body2WMedium"
      color="grey500"
      className={styles['title-description']}
    >
      {APPROVAL_INFO.subTitle}
    </Typography>
    <Typography name="body2WBold" className={styles['sub-title']}>
      {APPROVAL_INFO.stepOneTitle}
    </Typography>
    <Typography name="body2WMedium">
      {APPROVAL_INFO.stepOneDescription}
    </Typography>
    <Typography name="body2WBold" className={styles['sub-title']}>
      {APPROVAL_INFO.stepTwoTitle}
    </Typography>
    <Typography name="body2WMedium">
      {APPROVAL_INFO.stepTwoDescription}
    </Typography>
    <Typography name="body2WBold" className={styles['sub-title']}>
      {APPROVAL_INFO.stepThreeTitle}
    </Typography>
    <Typography name="body2WMedium">
      {APPROVAL_INFO.stepThreeDescription}
    </Typography>
  </div>
)
