export const OPTIONS = {
  fullscreenControl: false,
  streetViewControl: false,
  mapTypeControl: false,
  zoomControl: false,
  clickableIcons: false,
}

export const CONTAINER_STYLE = {
  width: '100%',
  height: '400px',
  marginBottom: 24,
}
