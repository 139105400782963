import { addQueryString } from 'utils/addQueryString'
import { EmptyResponse, ErrorResponse } from '../types'
import { request } from '../utils'
import { PATH } from './constants'
import { INewTermConditionBody, ITermsAndConditionsResponse } from './types'
import { EnvironmentVariablesService } from 'utils/env'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'

class Api {
  public baseUrl = EnvironmentVariablesService.getEnv('REACT_APP_API_URL') || ''

  async getTermConditionListRequest(
    companyId: number,
    termConditionTypesId: TERM_CONDITIONS_TYPES
  ) {
    return request<ITermsAndConditionsResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.getTermConditionListRequest, {
        companyId,
        termConditionTypesId,
      }),
      { method: 'GET' }
    )
  }

  async newTermCondition(body: INewTermConditionBody) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      PATH.newTermConditionRequest,
      { method: 'POST' },
      body
    )
  }

  async updateTermCondition(body: INewTermConditionBody) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      PATH.updateTermConditionRequest,
      { method: 'PUT' },
      body
    )
  }

  async deleteTermConditionRequest(termConditionSettingId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.deleteTermConditionRequest}`,
      { method: 'DELETE' },
      { termConditionSettingId }
    )
  }
}

export const termsApi = new Api()
