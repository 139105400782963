export const PENDING_INFO = {
  title: "You're well on your way to go live",
  description:
    "Please follow the steps listed on the right and wait for approval. If everything is okay, it won't take long.",
}

export const APPROVAL_INFO = {
  title: 'You did great!',
  subTitle: "You're almost done with the onboarding.",
  stepOneTitle: "What's next?",
  stepOneDescription: `We will verify the information you provided. Don't worry, it won't take
  much time.`,
  stepTwoTitle: 'Why do we need this?',
  stepTwoDescription:
    'We just want to ensure that everything meets our high standards',
  stepThreeTitle: 'In the meantime…',
  stepThreeDescription: `Prepare prices, a list of optional extras and rental Terms & Conditions.
      You will need them to complete the onboarding process.`,
}

export const PROFILE_INFO = {
  title: 'Profile details',
  description: 'Add basic information about your business.',
}

export const LOCATION_INFO = {
  title: 'Locations',
  description: 'Add the locations you operate in.',
}

export const CARS_INFO = {
  title: 'Cars',
  description: 'Add the cars you have in your fleet.',
}

export const CARD_BLOCK_TITLE = 'Here’s what to add'
export const CARD_BLOCK_TITLE_DONE = '3 out of 3 complete'
