import { createSlice } from '@reduxjs/toolkit'
import { ProfileState } from './types'

const initialState: ProfileState = {
  shortSupplierData: {
    id: 0,
    country: '',
    name: '',
    currency: '',
    isRegistered: false,
    accountStatus: 'New',
  },
  isLoading: false,
  supplierCompanyName: '',
  checkedStatuses: {},
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setShortSupplierData(state, action) {
      state.shortSupplierData = action.payload
    },
    setCompanyName(state, action) {
      state.supplierCompanyName = action.payload
    },
    resetShortSupplierData(state) {
      state.shortSupplierData = initialState.shortSupplierData
    },
    setCheckedStatuses(state, { payload: { id, ...rest } }) {
      state.checkedStatuses = {
        ...state.checkedStatuses,
        [id]: {
          ...state.checkedStatuses[id],
          ...rest,
        },
      }
    },
  },
})

export const profileReducer = profileSlice.reducer
export const {
  setShortSupplierData,
  setCompanyName,
  resetShortSupplierData,
  setCheckedStatuses,
} = profileSlice.actions
