import { FILTER_KEYS, SORTING_KEYS } from 'constants/filters'
import { ISortingItemReq, IFilterItemReq } from 'types/table'
import { Filter } from './addQueryString'

export const prepareSortingForReq = (sortItems: ISortingItemReq[]) =>
  sortItems.map((el) => `[${SORTING_KEYS[el.key]}]${el.type}`).join(',')

export const prepareFiltersForReq = (
  filterItems: IFilterItemReq,
  count: number
): Filter[] =>
  count
    ? Object.entries(filterItems).map(([key, value]) => ({
        name: FILTER_KEYS[key],
        type: 'In',
        value: value.join(','),
      }))
    : []
