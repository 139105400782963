import { getRequiredMessage } from 'utils/getRequiredMessage'
import { LATIN_LETTER_RULE } from './uiTexts'

export const VEHICLE_TITLE = 'Car'
export const VEHICLE_ADDING_BREADCRUMB = 'Add car'
export const VEHICLE_DETAILS_BREADCRUMB = 'Car details'

export const VEHICLE_DETAILS = {
  FORM_TITLE: 'Car details',
  DRIVER_AGE_RANGE_TITLE: 'Driver age ranges',
  BUTTONS: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save',
    ADD_CAR: 'Add car',
  },
  SEARCH_VALUE: 'location',
}

export const LIMIT_INFO =
  'The maximum number of cars that can be added for initial approval is 10.'

export const SUCCESS_VEHICLES_UPDATE = 'Your cars have been successfully saved '

export const VEHICLE_DETAILS_FORM_VALUES = {
  CAR_TYPE: {
    ID: 'carType',
    PLACEHOLDER: 'Select a car type',
    LABEL: 'Car type',
    REQUIRED_MESSAGE: 'Please select a car type.',
    VALIDATION_MESSAGE: 'Invalid characters',
    SEARCH_PLACEHOLDER: 'Search for car type',
  },
  CAR_MODEL_NAME: {
    ID: 'carModelName',
    LABEL: 'Add a car model',
    PLACEHOLDER: 'Enter a car model',
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
  },
  CAR_CATEGORY: {
    ID: 'carCategoryId',
    PLACEHOLDER: 'Select a car category',
    LABEL: 'Car category',
    REQUIRED_MESSAGE: 'Please select a car category.',
    VALIDATION_MESSAGE: 'Invalid characters',
    SEARCH_PLACEHOLDER: 'Search for car category',
  },
  COMPANY_CLASS: {
    ID: 'companyClass',
    PLACEHOLDER: 'Enter a company class',
    LABEL: 'Company class',
    REQUIRED_MESSAGE: getRequiredMessage('company class'),
    VALIDATION_MESSAGE: LATIN_LETTER_RULE,
    TOOLTIP: 'How car models are designated in your system.',
  },
  SIPP: {
    ID: 'sippCode',
    PLACEHOLDER: 'Enter SIPP',
    LABEL: 'SIPP',
    REQUIRED_MESSAGE: 'Please enter SIPP.',
    VALIDATION_MESSAGE:
      'Car class must be 2 - 8 characters long, include letters and numbers',
  },
  DRIVER_AGE_RANGE: {
    ID: 'driverAgeRange',
    PLACEHOLDER: 'Please enter drive age range',
    LABEL: 'Driver age range',
    REQUIRED_MESSAGE: getRequiredMessage('driver age range'),
  },
  CAR: {
    ID: 'car',
    PLACEHOLDER: 'Select a car model',
    LABEL: 'Car model',
    REQUIRED_MESSAGE: 'Please select a car model.',
    SEARCH_PLACEHOLDER: 'Search for car model',
  },
  HIRE_POINTS: {
    ID: 'hirePoints',
    PLACEHOLDER: 'Select a location',
    LABEL: 'Refers to location',
    REQUIRED_MESSAGE: 'Please select a location.',
  },
  AIR_CONDITIONING: {
    ID: 'airConditioning',
    LABEL: 'Air conditioning',
  },
  TRANSMISSION: {
    ID: 'transmission',
    LABEL: 'Transmission',
    PLACEHOLDER: 'Select a transmission',
    REQUIRED_MESSAGE: 'Please select a transmission.',
  },
  FUEL: {
    ID: 'fuel',
    LABEL: 'Fuel',
    PLACEHOLDER: 'Select a fuel type',
    REQUIRED_MESSAGE: 'Please select a fuel type.',
  },
  SEATS: {
    ID: 'sits',
    LABEL: 'Seats',
  },
  DOORS: {
    ID: 'doors',
    LABEL: 'Doors',
  },
  QUANTITY: {
    ID: 'quantity',
    LABEL: 'Quantity',
    PLACEHOLDER: 'Enter quantity',
    REQUIRED_MESSAGE: 'Please enter quantity.',
    TOOLTIP:
      'Please specify how many cars of the selected model are available.',
    VALIDATION_MESSAGE: 'Quantity must be greater than 0.',
  },
}

export const CAR_TABS = {
  DETAILS: 'Details',
  DRIVER_AGE_RANGE: 'Driver age range',
}

export const TABLE_INFO = {
  TITLE: 'Your cars',
  SUBTITLE: 'Manage the cars you have added.',
  BUTTON_LABEL: 'Complete',
}
