import { URLS } from 'constants/urls'
import { useMemo } from 'react'
import { useAppSelector } from 'redux/hooks'
import { isReadyToApproveSelector } from 'redux/login/selectors'
import { MENU_NAMES } from 'texts/menuNames'

export const useNavigationItems = () => {
  const isReadyToApprove = useAppSelector(isReadyToApproveSelector)

  const items = useMemo(
    () => [
      {
        label: MENU_NAMES.PROFILE_DETAILS.NAME,
        getPath: (id: string) =>
          `${URLS.PROFILE}${URLS.PROFILE_LIST}/${id}/edit`,
        withCheckbox: isReadyToApprove,
      },
      {
        label: MENU_NAMES.LOCATIONS.NAME,
        getPath: (id: string) =>
          `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/${id}`,
        withCheckbox: isReadyToApprove,
      },
      {
        label: MENU_NAMES.VEHICLES,
        getPath: (id: string) => `${URLS.FLEET}${URLS.VEHICLES}/${id}`,
        withCheckbox: isReadyToApprove,
      },
      {
        label: MENU_NAMES.EQUIPMENTS,
        getPath: (id: string) => `${URLS.FLEET}${URLS.EQUIPMENTS}/${id}`,
        isDisabled: isReadyToApprove,
      },
      {
        label: MENU_NAMES.PRICE_LISTS,
        getPath: (id: string) => `${URLS.FLEET}${URLS.PRICE_LIST}/${id}`,
        isDisabled: isReadyToApprove,
      },
      {
        label: MENU_NAMES.DROP_OFF_FEE,
        getPath: (id: string) => `${URLS.DROP_OFF_FEE}/${id}`,
        isDisabled: isReadyToApprove,
      },
      {
        label: MENU_NAMES.STOP_SALES,
        getPath: (id: string) => `${URLS.FLEET}${URLS.STOP_SALES}/${id}`,
        isDisabled: isReadyToApprove,
      },
      {
        label: MENU_NAMES.TERMS_AND_CONDITIONS,
        getPath: (id: string) =>
          `${URLS.TERMS_AND_CONDITIONS}${URLS.RENTAL_INCLUDES}/${id}`,
        activePath: URLS.TERMS_AND_CONDITIONS,
        isDisabled: isReadyToApprove,
      },
    ],
    [isReadyToApprove]
  )

  return items
}
