import { useCallback, useContext, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import { EnvironmentVariablesService } from 'utils/env'
import { CONTAINER_STYLE, OPTIONS } from './Map.constants'
import { useDispatch, useSelector } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { setMapData, setMarkerPosition } from 'redux/locations/slice'
import {
  LOCATION_ADDING_FORM_VALUES,
  NO_COORDINATES,
} from 'texts/locationDetails'
import { IMarkerPosition, MapData } from 'types/locations'
import { markerPositionSelector } from 'redux/locations/selectors'
import { FormContext } from 'ui/molecules/Form/Form'
import { ContextType } from 'ui/molecules/Form/types'

const center = {
  lat: 10,
  lng: 10,
}
const MAX_FRACTIONAL_PART = 10

const googleMapsApiKey = EnvironmentVariablesService.getEnv(
  'REACT_APP_GOOGLE_API_KEY'
)

interface IMapProps {
  mapData: MapData
}

export const Map = ({ mapData }: IMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
  })
  const dispatch = useDispatch()
  const markerPosition = useSelector(markerPositionSelector)
  const { setValues } = useContext<ContextType>(FormContext)
  const [googleMap, setGoogleMap] = useState<google.maps.Map>()

  const onChangeMarkerPosition = (position: IMarkerPosition) => {
    dispatch(setMarkerPosition(position))
    setValues((prevValues) => ({
      ...prevValues,
      [LOCATION_ADDING_FORM_VALUES.LATITUDE.ID]: position.lat,
      [LOCATION_ADDING_FORM_VALUES.LONGITUDE.ID]: position.lng,
    }))
  }

  const detectPosition = async (withoutPostalCode?: boolean) => {
    try {
      const { Geocoder } = (await google.maps.importLibrary(
        'geocoding'
      )) as google.maps.GeocodingLibrary
      const geocoder = new Geocoder()

      const { results } = await geocoder.geocode({
        address: `${mapData.location} ${mapData.street}`,
        region: mapData.region,
        componentRestrictions: {
          country:
            mapData.country.split(' ')[0] === 'USA' ? 'USA' : mapData.country,
          postalCode: withoutPostalCode ? undefined : mapData.postalCode,
        },
      })

      if (results[0]) {
        const {
          geometry: { location },
        } = results[0]
        const position =
          markerPosition.lat || markerPosition.lng
            ? markerPosition
            : {
                lat: +location.lat().toFixed(MAX_FRACTIONAL_PART),
                lng: +location.lng().toFixed(MAX_FRACTIONAL_PART),
              }

        onChangeMarkerPosition(position)
      }
    } catch (error) {
      if (withoutPostalCode) {
        dispatch(
          setNotificationMessage({
            notificationMessage: NO_COORDINATES,
            type: 'error',
            timer: 10,
          })
        )
        dispatch(setMapData(null))
      } else {
        detectPosition(true)
      }
    }
  }

  const onLoad = useCallback(async (map: google.maps.Map) => {
    setGoogleMap(map)
    detectPosition()
  }, [])

  const onDragEnd = useCallback(({ latLng }: google.maps.MapMouseEvent) => {
    if (!latLng) {
      return null
    }

    const position = {
      lat: +latLng.lat().toFixed(MAX_FRACTIONAL_PART),
      lng: +latLng.lng().toFixed(MAX_FRACTIONAL_PART),
    }

    onChangeMarkerPosition(position)
    dispatch(setMarkerPosition(position))
  }, [])

  useEffect(() => {
    if (!googleMap) return

    if (markerPosition.lat || markerPosition.lng) {
      googleMap.setCenter(markerPosition)
    } else {
      detectPosition()
    }
  }, [markerPosition])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={CONTAINER_STYLE}
      center={center}
      zoom={15}
      onLoad={onLoad}
      options={OPTIONS}
    >
      <Marker
        key={`${markerPosition.lat}-${markerPosition.lng}`}
        position={markerPosition}
        draggable
        onDragEnd={onDragEnd}
      />
    </GoogleMap>
  ) : null
}
