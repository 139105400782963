import { ErrorResponse, EmptyResponse } from 'api/types'
import { addQueryString } from 'utils/addQueryString'
import { request } from '../utils'
import { PATH } from './constants'
import {
  GetDropOffFeeListResponse,
  DropOffData,
  SetDropOffFeeData,
} from './types'

class Api {
  public baseUrl: string = process.env.REACT_APP_API_URL || ''

  async getDropOffFeeList(
    pageIndex: number,
    pageSize: number,
    companyId: number,
    dropOffFeeData: DropOffData
  ) {
    return request<GetDropOffFeeListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.DROP_OFF_FEE_LIST, {
        companyId,
        pageSize,
        pageIndex,
        ...dropOffFeeData,
      }),
      { method: 'GET' }
    )
  }

  async setDropOffFee(
    fromHirePointId: number,
    toHirePointId: number,
    data: SetDropOffFeeData
  ) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.DROP_OFF_FEE}/${fromHirePointId}/${toHirePointId}`,
      { method: 'POST' },
      { ...data }
    )
  }
}

export const dropOffFeeApi = new Api()
