import { Route, Routes } from 'react-router-dom'
import { NotFound } from 'ui/molecules/NotFound'
import { BookingHistoryList } from './components/BookingHistoryList'

export const BookingHistory = () => (
  <Routes>
    <Route path="/" element={<BookingHistoryList />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
