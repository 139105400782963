import { Typography } from '@frontend/design_system'
import { ApprovalBlock } from './components/ApprovalBlock'
import { PendingBlock } from './components/PendingBlock'
import { useAppSelector } from 'redux/hooks'
import { authIsLoadedSelector, userDataSelector } from 'redux/login/selectors'
import { CardType, CardOption } from './types'
import { ACCOUNT_STATUSES, REGISTRATION_STATUSES } from 'api/auth/constants'
import { URLS } from 'constants/urls'
import { ProfileIcon, CarIcon, LocationIcon } from 'ui/icons'
import {
  CARS_INFO,
  PROFILE_INFO,
  LOCATION_INFO,
  CARD_BLOCK_TITLE,
  CARD_BLOCK_TITLE_DONE,
} from 'texts/pendingForApproval'
import { PendingCard } from './components/PendingCard'
import { useNavigate } from 'react-router-dom'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { useEffect } from 'react'
import styles from './styles.module.scss'

const cardOptions: CardOption[] = [
  {
    type: CardType.PROFILE,
    Icon: ProfileIcon,
    title: PROFILE_INFO.title,
    description: PROFILE_INFO.description,
    path: URLS.PROFILE + URLS.DETAILS,
  },
  {
    type: CardType.LOCATION,
    Icon: LocationIcon,
    title: LOCATION_INFO.title,
    description: LOCATION_INFO.description,
    path: URLS.LOCATIONS + URLS.LIST_OF_LOCATIONS + URLS.ADD,
  },
  {
    type: CardType.CARS,
    Icon: CarIcon,
    title: CARS_INFO.title,
    description: CARS_INFO.description,
    path: URLS.FLEET + URLS.VEHICLES + URLS.ADD,
  },
]

export const PendingForApproval = () => {
  const { sectionsStatuses, accountStatus } = useAppSelector(userDataSelector)
  const isLoaded = useAppSelector(authIsLoadedSelector)
  const isAllPageComplete = !!(
    sectionsStatuses &&
    sectionsStatuses.carsStatus === REGISTRATION_STATUSES.PENDING &&
    sectionsStatuses.locationsStatus === REGISTRATION_STATUSES.PENDING &&
    sectionsStatuses.profileStatus === REGISTRATION_STATUSES.PENDING
  )

  const isAccountApproved = accountStatus === ACCOUNT_STATUSES.APPROVED
  const navigate = useNavigate()

  const { getCompanyInfo, companyId } = useCompanyInfo(false)

  useEffect(() => {
    getCompanyInfo(Number(companyId))
  }, [])

  if (isAccountApproved) {
    navigate(`..${URLS.DETAILS}`)
  }

  if (!sectionsStatuses) {
    return null
  }

  return isLoaded ? (
    <div className={styles.container}>
      <div className={styles['left-block']}>
        {isAllPageComplete ? <ApprovalBlock /> : <PendingBlock />}
      </div>
      <div className={styles['right-block']}>
        <div className={styles.title}>
          <Typography name="subtitleWBold">
            {isAllPageComplete ? CARD_BLOCK_TITLE_DONE : CARD_BLOCK_TITLE}
          </Typography>
        </div>
        {cardOptions.map(({ Icon, type, title, description, path }, index) => {
          const isCompleted =
            sectionsStatuses[type] === REGISTRATION_STATUSES.PENDING ||
            isAllPageComplete
          return (
            <PendingCard
              key={index}
              Icon={
                <Icon
                  size="medium"
                  color={isCompleted ? 'green700Master' : 'grey400'}
                />
              }
              path={path}
              title={title}
              description={description}
              isDone={isCompleted}
            />
          )
        })}
      </div>
    </div>
  ) : null
}
