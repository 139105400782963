export const PROFILE_ARCHIVE = {
  RESTORE: 'Restore',
  ADD: 'Add to archive',
}

export const PROFILE_ARCHIVE_CONFIRMATION = {
  CONFIRMATION_MODAL: {
    QUESTION: 'Are you sure that you want to restore',
  },
  SUCCESS_MODAL: {
    TITLE: 'Profile was successfully restored',
    BUTTON_LABEL: 'Go to Profile details page',
    SUBTITLE: 'was restored and moved to Profiles page',
    SUPPLIER: 'Profile',
  },
}

export const ARCHIVED_PROFILE_TITLE = 'Profile information'
