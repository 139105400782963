export const MENU_NAMES = {
  PROFILE_LIST: {
    NAME: 'Profiles',
  },
  PROFILE_ARCHIVE: {
    NAME: 'Profile archive',
  },
  PROFILE_DETAILS: {
    NAME: 'Profile',
  },
  APPLICATIONS: 'Applications',
  APPLICATION_ARCHIVE: 'Application archive',
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  DROP_OFF_FEE: 'Drop-off fee',
  DASHBOARD: 'Dashboard',
  VEHICLES: 'Cars',
  EQUIPMENTS: 'Equipment',
  PRICE_LISTS: 'Price lists',
  STOP_SALES: 'Stop sales',
  RATES: 'Prices',
  LOCATIONS: {
    NAME: 'Locations',
  },
  ACTIVITY_HISTORY: 'Activity history',
  BOOKING_HISTORY: 'Booking history',
  PROFILE_PENDING: 'Profile pending',
}
