import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { getDiagramOptions } from '../../utils'
import { DiagramsProps } from './types'
import styles from './styles.module.scss'

export const Diagram = ({
  categories,
  groupCount,
  series,
  isDonut = false,
  total = '',
}: DiagramsProps) => {
  const options: ApexOptions = getDiagramOptions({
    categoryOptions: categories,
    groupCount,
    isDonut,
    total,
  })

  const [chartSeries, setChartSeries] = useState<ApexOptions['series']>(series)

  useEffect(() => {
    setChartSeries(series)
  }, [series])

  return (
    <div className={styles['diagram-block']}>
      <Chart
        options={options}
        series={chartSeries}
        type={isDonut ? 'donut' : 'bar'}
        height={350}
      />
    </div>
  )
}
