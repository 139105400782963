export const onlyNumbersRegex = /^([0-9\-() ]+)$/
export const onlyNumbersWithSymbolsRegex = /^([0-9+\-() ]+)$/
export const onlyNumbersWithDotRegex = /^([0-9.]+)$/
export const onlyNumbersWithPlus = /^([0-9]+)$/
export const mailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i
export const allSymbolsAndEnglishLettersRegex =
  /^[A-Za-z_ \d\\,\\.!@#$%^&*([)\]_{|}<>\n?~`“':;"/+=-]*$/
export const postalCodeRegex = /^(?=.*[^\W_])[\w /-]*$/
export const lettersWithSpaceRegex = /^[a-zA-Z\s_ ]*$/
export const lettersWithNumbersRegex = /^[\w]*$/
export const mustLowerUpperDigital11MinimumRegex =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{11,}/
export const mustLowerUpperDigitalSpecSymbol8MinimumRegex =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[//,//.!@#$%^&*([)\]_{|}<>?~\\`“':;+=-]){8,}/
export const minimum8Regex = /^.*(?=.{8,})/
export const must1DigitalRegex = /[\d]{1,}/
export const must1LowerAndUpperRegex = /(?=.*[a-z])(?=.*[A-Z]){1,}/
export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d\\,\\.!@#$%^&*([)\]_{|}<>?~`“':;"/+=-]{8,}$/
export const allSpacesRegex = /\s/g
export const latitudeRegex = /^-?([0-8]?[0-9]|90)(\.[0-9]{1,10})?$/
export const longitudeRegex = /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})?$/
export const onlyNumbersWithOneDotRegex = /[^\d.]|(?<=\.\d{2})\d+|(?<=\..*)\./g
export const onlyNumbersWithOneDotAndMinusRegex = /[^\d.-]|(?<!^)-|(?<=\..*)\./g
export const allCharactersExceptNumbers = /[^\d]+/g
export const base64DataRegex = /^data:image\/\w+;base64,/
