import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ProfileIcon = ({
  size,
  color = 'grey400',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className} viewBox="0 0 20 20">
    <path
      d="M9.9999 1.45349C14.72 1.45349 18.5464 5.2799 18.5464 9.99998C18.5464 14.7201 14.72 18.5465 9.9999 18.5465C5.27982 18.5465 1.45342 14.7201 1.45342 9.99998C1.45342 5.2799 5.27982 1.45349 9.9999 1.45349Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6057 15.4233C16.6057 15.4233 14.7006 12.9912 10.0001 12.9912C5.29954 12.9912 3.39439 15.4233 3.39439 15.4233"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99998 9.99996C8.58389 9.99996 7.436 8.85207 7.436 7.43598C7.436 6.01996 8.58389 4.87207 9.99998 4.87207C11.4161 4.87207 12.564 6.01996 12.564 7.43598C12.564 8.85207 11.4161 9.99996 9.99998 9.99996Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
