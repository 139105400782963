import { Typography, LazyImage } from '@frontend/design_system'
import rightArrow from 'assets/icons/blue700/blue700_rightArrow_16x16.webp'
import styles from './styles.module.scss'
import { NAVIGATION_ITEMS } from '../../constants'
import { joinClasses } from 'utils/joinClasses'
import { useNavigate, useParams } from 'react-router-dom'

interface INavigationProps {
  current: string
}

export const Navigation = ({ current }: INavigationProps) => {
  const navigate = useNavigate()
  const { companyId } = useParams()

  return (
    <ul className={styles.navigation}>
      {NAVIGATION_ITEMS.map(({ url, label }) => {
        const goTo = () => {
          navigate(`..${url}${companyId ? `/${companyId}` : ''}`)
        }

        return (
          <Typography
            key={url}
            name="body2WMedium"
            Tag="li"
            className={joinClasses(styles.item, [
              styles.active,
              current === url,
            ])}
            onClick={goTo}
          >
            {label}
            <LazyImage
              src={rightArrow}
              minWidth={16}
              height={16}
              isPriority
              className={styles.arrow}
            />
          </Typography>
        )
      })}
    </ul>
  )
}
