import { useAppSelector } from 'redux/hooks'
import { useNavigate } from 'react-router-dom'
import {
  PRICE_LIST_TITLE,
  PRICE_LIST_ADDING_BREADCRUMB,
  PRICE_LIST_DETAILS,
} from 'texts/priceListDetails'
import { Container } from 'ui/molecules/Container'
import { PriceListDetailsForm } from '../PriceListDetailsForm'
import { FormValues } from '../PriceListDetailsForm/types'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { isOperatorSelector } from 'redux/login/selectors'
import { PriceListType } from 'api/fleet/types'
import { fleetApi } from 'api'
import { formatSeasonRange } from '../../utils/formatSeasonRange'
import { useApiRequest } from 'hooks/useApiRequest'
import { getDetailsBreadcrumbsList } from 'utils/getDetailsBreadcrumbsList'
import { URLS } from 'constants/urls'
import { getSeasonFromSeasonRanges } from '../../utils/getSeasonFromSeasonRange'
import { GTM } from 'utils/gtm'
import { DATA_LAYER_EVENT } from 'constants/dataLayerEvents'
import { prepareResponseItems } from 'utils/form'

export const PriceListAdd = () => {
  const isOperator = useAppSelector(isOperatorSelector)
  const navigate = useNavigate()

  const { companyId } = useCompanyInfo(isOperator)

  const { apiRequest, loading } = useApiRequest(
    (values) =>
      fleetApi.addPriceList({
        ...values,
        companyID: Number(companyId),
      }),
    undefined,
    true,
    undefined,
    false
  )

  const breadcrumbList = getDetailsBreadcrumbsList(
    isOperator,
    String(companyId),
    'priceLists'
  )

  const handleSubmit = async (data: Partial<FormValues>) => {
    const formattedSeasonRanges = data.seasonRanges
      ? formatSeasonRange(data.seasonRanges)
      : []
    const joinedSeasons = getSeasonFromSeasonRanges(formattedSeasonRanges).join(
      '_'
    )
    const values = {
      ...data,
      isActive: true,
      priceListType: data.priceListType as PriceListType,
      hirePoints:
        data.hirePoints?.map((hirePoint) => Number(hirePoint.value)) || [],
      seasonRanges: formattedSeasonRanges,
      countriesOfResidence: prepareResponseItems(
        data.countriesOfResidence || []
      ),
    }
    const response = await apiRequest(values)
    if (response) {
      navigate(
        `${URLS.FLEET}${URLS.PRICE_LIST}/${companyId}/${response.data.priceListInfo.id}/rates`
      )
      GTM.dataLayer({
        event: DATA_LAYER_EVENT.PRICE_LIST_ADD,
        season_range: joinedSeasons,
      })
    }
  }

  return (
    <Container
      breadcrumbList={breadcrumbList}
      currentPageLabel={PRICE_LIST_ADDING_BREADCRUMB}
      title={PRICE_LIST_TITLE}
      withNavigation={isOperator}
    >
      <PriceListDetailsForm
        handleSubmit={handleSubmit}
        isLoading={loading}
        submitButtonLabel={PRICE_LIST_DETAILS.ADD_SUBMIT_BUTTON}
      />
    </Container>
  )
}
