import { APPLICATIONS_STATUS_VALUES } from 'texts/applications'

export const APPLICATION_STATUS = {
  WaitingList: 'WaitingList',
  WaitingForCall: 'WaitingForCall',
  WaitingForSign: 'WaitingForSign',
  Approve: 'Approve',
  Reject: 'Reject',
  Archive: 'Archive',
}

export const APPLICATION_SEARCH_PARAM = {
  [APPLICATION_STATUS.WaitingList]: 'waiting-list',
  [APPLICATION_STATUS.WaitingForCall]: 'waiting-for-call',
  [APPLICATION_STATUS.WaitingForSign]: 'waiting-for-sign',
  [APPLICATION_STATUS.Approve]: 'approve',
  [APPLICATION_STATUS.Reject]: 'reject',
  [APPLICATION_STATUS.Archive]: 'archive',
} as const

export const APPLICATION_SHOWN_STATUS: {
  [key: string]: string
} = {
  [APPLICATION_SEARCH_PARAM.WaitingList]:
    APPLICATIONS_STATUS_VALUES.WaitingList,
  [APPLICATION_SEARCH_PARAM.WaitingForCall]:
    APPLICATIONS_STATUS_VALUES.WaitingForCall,
  [APPLICATION_SEARCH_PARAM.WaitingForSign]:
    APPLICATIONS_STATUS_VALUES.WaitingForSign,
  [APPLICATION_SEARCH_PARAM.Approve]: APPLICATIONS_STATUS_VALUES.Approve,
  [APPLICATION_SEARCH_PARAM.Reject]: APPLICATIONS_STATUS_VALUES.Reject,
}
