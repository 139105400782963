import { IconProps } from 'ui/icons/types'

export enum CardType {
  PROFILE = 'profileStatus',
  LOCATION = 'locationsStatus',
  CARS = 'carsStatus',
}

export type CardOption = {
  type: CardType
  Icon: React.ComponentType<IconProps>
  title: string
  description: string
  path: string
}
