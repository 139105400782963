import { APPLICATION_FORM_VALUES } from 'texts/applications'
import { Input } from 'ui/atoms/Input'
import { FormItem } from '@frontend/design_system'
import { FormItemChildren } from 'types/form'
import styles from './styles.module.scss'

export const OtherInformation = () => (
  <>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.API_TYPE.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }: FormItemChildren) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            disabled
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.API_TYPE.LABEL}
          />
        )}
      </FormItem>
    </div>
  </>
)
