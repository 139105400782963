import { Typography } from '@frontend/design_system'
import { PRICE_INFO } from 'texts/dropoffFee'
import styles from './styles.module.scss'

export const PriceInfo = () => (
  <div>
    <Typography name="caption1WBold" Tag="div" className={styles.title}>
      {PRICE_INFO.TOOLTIP.TITLE}:
    </Typography>
    <ul className={styles.list}>
      <li>
        <Typography name="caption1WMedium">
          {PRICE_INFO.TOOLTIP.VALUE_PRICE}
        </Typography>
      </li>
      <li>
        <Typography name="caption1WMedium">
          {PRICE_INFO.TOOLTIP.FREE_PRICE}
        </Typography>
      </li>
      <li>
        <Typography name="caption1WMedium">
          {PRICE_INFO.TOOLTIP.EMPTY_PRICE}
        </Typography>
      </li>
    </ul>
  </div>
)
