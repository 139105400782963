import { URLS } from 'constants/urls'
import { Route, Routes } from 'react-router-dom'
import { ProfileList } from './ProfileList'
import { ProfileAdd } from './ProfileList/components/ProfileAdd'
import { ProfileEdit } from './ProfileList/components/ProfileEdit'
import { ArchiveCompanyList } from './ArchiveCompanyList'
import { ProfileRestore } from './ArchiveCompanyList/components/ProfileRestore'
import { NotFound } from 'ui/molecules/NotFound/NotFound'
import { ProtectedRoute } from 'routes/ProtectedRoute'
import { useAppSelector } from 'redux/hooks'
import { isSupplierSelector } from 'redux/login/selectors'
import { PendingForApproval } from 'modules/PendingForApproval'

export const Profile = () => {
  const isSupplier = useAppSelector(isSupplierSelector)

  return (
    <Routes>
      <Route
        path={URLS.PROFILE_LIST}
        element={
          <ProtectedRoute isUnavailable={isSupplier}>
            <ProfileList />
          </ProtectedRoute>
        }
      />
      <Route path={URLS.PROFILE_ADD} element={<ProfileAdd />} />
      <Route path={URLS.PENDING} element={<PendingForApproval />} />
      <Route path={URLS.DETAILS} element={<ProfileEdit />} />
      <Route
        path={`${URLS.PROFILE_LIST}/:companyId/edit`}
        element={
          <ProtectedRoute isUnavailable={isSupplier}>
            <ProfileEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path={URLS.ARCHIVE}
        element={
          <ProtectedRoute isUnavailable={isSupplier}>
            <ArchiveCompanyList />
          </ProtectedRoute>
        }
      />
      <Route
        path={`${URLS.ARCHIVE}/:companyId`}
        element={
          <ProtectedRoute isUnavailable={isSupplier}>
            <ProfileRestore />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
