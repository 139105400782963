import { FUEL_POLICY_FORM_VALUES } from 'texts/termsAndConditions'

export const RADIO_BUTTONS = [
  {
    id: FUEL_POLICY_FORM_VALUES.FULL_TO_FULL.ID,
    label: FUEL_POLICY_FORM_VALUES.FULL_TO_FULL.LABEL,
    description: FUEL_POLICY_FORM_VALUES.FULL_TO_FULL.DESCRIPTION,
  },
  {
    id: FUEL_POLICY_FORM_VALUES.SAME_TO_SAME.ID,
    label: FUEL_POLICY_FORM_VALUES.SAME_TO_SAME.LABEL,
    description: FUEL_POLICY_FORM_VALUES.SAME_TO_SAME.DESCRIPTION,
  },
]
