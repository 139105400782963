import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const CarIcon = ({ size, color = 'grey400', className }: IconProps) => (
  <SVGIcon size={size} color={color} className={className} viewBox="0 0 20 20">
    <path
      d="M17.5 14.375C17.5 14.8723 17.3025 15.3492 16.9508 15.7008C16.5992 16.0525 16.1223 16.25 15.625 16.25C15.1277 16.25 14.6508 16.0525 14.2992 15.7008C13.9475 15.3492 13.75 14.8723 13.75 14.375C13.75 13.8777 13.9475 13.4008 14.2992 13.0492C14.6508 12.6975 15.1277 12.5 15.625 12.5C16.1223 12.5 16.5992 12.6975 16.9508 13.0492C17.3025 13.4008 17.5 13.8777 17.5 14.375Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M6.25 14.375C6.25 14.8723 6.05246 15.3492 5.70083 15.7008C5.34919 16.0525 4.87228 16.25 4.375 16.25C3.87772 16.25 3.40081 16.0525 3.04917 15.7008C2.69754 15.3492 2.5 14.8723 2.5 14.375C2.5 13.8777 2.69754 13.4008 3.04917 13.0492C3.40081 12.6975 3.87772 12.5 4.375 12.5C4.87228 12.5 5.34919 12.6975 5.70083 13.0492C6.05246 13.4008 6.25 13.8777 6.25 14.375Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M17.5 13.75L18.75 13.75V8.97727M18.75 8.97727L16.8056 5H8.05556L4.16667 8.97727M18.75 8.97727H4.16667M4.16667 8.97727H3.19445C2.67875 8.97727 2.18417 9.14489 1.81952 9.44324C1.45486 9.74159 1.25 10.1462 1.25 10.5682V13.75L2.5 13.75M6.25 13.75H13.75M10 8.97727V5"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SVGIcon>
)
