import { createSlice } from '@reduxjs/toolkit'
import { ActivityHistoryState } from './types'

const initialState: ActivityHistoryState = {
  rates: {},
  selectedActivityHistoryRange: {
    from: '',
    to: '',
  },
}

export const activityHistorySlice = createSlice({
  name: 'activityHistory',
  initialState,
  reducers: {
    setRates(state, action) {
      state.rates = action.payload
    },
    setSelectedActivityHistoryRange(state, action) {
      state.selectedActivityHistoryRange = action.payload
    },
  },
})

export const activityHistoryReducer = activityHistorySlice.reducer
export const { setRates, setSelectedActivityHistoryRange } =
  activityHistorySlice.actions
