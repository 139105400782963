import { useParams } from 'react-router-dom'
import { supplierIdSelector } from 'redux/common/selectors'
import { useAppSelector } from 'redux/hooks'

export const useCompanyId = () => {
  const params = useParams()
  const supplierCompanyId = useAppSelector(supplierIdSelector)

  return supplierCompanyId || Number(params.companyId)
}
