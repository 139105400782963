import { useEffect, useMemo, useState } from 'react'
import { ReferencesApiTypes, referencesApi } from 'api'
import { Typography, Form, FormItem } from '@frontend/design_system'
import { ItemType } from 'ui/atoms/Select/types'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { supplierIdSelector } from 'redux/common/selectors'
import {
  FormValues,
  VehicleDetailsData,
  VehicleDetailsFormProps,
} from './types'
import {
  CAR_TABS,
  LIMIT_INFO,
  VEHICLE_DETAILS_FORM_VALUES,
} from 'texts/vehicleDetails'
import { useApiRequest } from 'hooks/useApiRequest'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { DriveAgeRanges } from 'ui/components/DriverAgeRanges'
import { useDriverAgeRangeValidation } from 'ui/components/DriverAgeRanges/hooks/useDriverAgeRangeValidation'
import { LeftSide } from './components/LeftSide/LeftSide'
import { InfoIcon } from 'ui/icons'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { ACCOUNT_STATUSES } from 'api/auth/constants'
import { VehicleInformationForm } from './components/VehicleInformationForm/VehicleInformationForm'
import { initialFormValues } from './constants'
import { RightSide } from './components/RightSide/RightSide'
import { VehiclesTable } from '../VehiclesTable'
import { replaceNotAvailable, replaceShortNotAvailable } from './utils'
import { FormChildren, FormItemChildren, ValidationType } from 'types/form'
import styles from './styles.module.scss'

export const VehicleDetailsForm = ({
  handleSubmit,
  formValues,
  isEditing,
  isLoading,
}: VehicleDetailsFormProps) => {
  const {
    carCategoryId,
    companyClass,
    sippCode,
    car,
    hirePoints,
    airConditioning,
    transmission,
    fuel,
    sits,
    doors,
    driverAgeRange,
    carType,
    carModelName,
    quantity,
  } = initialFormValues
  const params = useParams()
  const [vehicleDetailsData, setVehicleDetailsData] =
    useState<VehicleDetailsData>({
      carCategories: [],
      cars: [],
      carTypes: [],
    })
  const [loading, setLoading] = useState(true)
  const [locations, setLocations] = useState<ReferencesApiTypes.Location[]>([])
  const [formKey, setFormKey] = useState(0)
  const [seatsList, setSeatsList] = useState<ItemType[]>([])
  const [doorsList, setDoorsList] = useState<ItemType[]>([])
  const [driverAgeError, setDriverAgeError] = useState('')
  const [activeKey, setActiveKey] = useState(CAR_TABS.DETAILS)
  const [sipp, setSipp] = useState('')
  const [base64Image, setBase64Image] = useState('')
  const supplierCompanyId = useAppSelector(supplierIdSelector)
  const { accountStatus } = useAppSelector(userDataSelector)
  const isOperator = useAppSelector(isOperatorSelector)
  const showLimitInfoAndTable =
    !isOperator && accountStatus !== ACCOUNT_STATUSES.APPROVED
  const supplierId = supplierCompanyId || (params.companyId as string)

  const { apiRequest: carSeatsRequest, loading: seatLoading } = useApiRequest(
    () => referencesApi.getCarSeats()
  )

  const { apiRequest: carDoorsRequest, loading: doorsLoading } = useApiRequest(
    () => referencesApi.getCarDoors()
  )

  useEffect(() => {
    if (supplierId) {
      const fetchLocations = async () => {
        const locations = await referencesApi.getAllCompanyHirePoints(
          String(supplierId)
        )
        setLocations(locations.data.items)
      }
      fetchLocations()
    }
  }, [supplierId])

  useEffect(() => {
    const fetchVehicleDetailsData = async () => {
      setLoading(true)
      const response = await Promise.all([
        referencesApi.getAllCarGroups(),
        referencesApi.getAllCars(String(supplierId)),
        referencesApi.getAllCarTypes(),
      ])
      if (response) {
        setVehicleDetailsData((prev) => ({
          ...prev,
          carCategories: response[0].data.items,
          cars: response[1].data.items,
          carTypes: response[2].data.items,
        }))
      }
      setLoading(false)
    }
    fetchVehicleDetailsData()
  }, [])

  const checkDriverAgeRange = useDriverAgeRangeValidation(false)

  const onSubmit = (data: Partial<FormValues>, validate: boolean) => {
    let driverAgeError
    const isFilledFieldExist = data.driverAgeRange?.items.some(
      ({ ageStart, ageEnd, price, maxPrice }) =>
        ageStart || ageEnd || price || maxPrice
    )
    if (isFilledFieldExist) {
      driverAgeError = checkDriverAgeRange(data.driverAgeRange)
      if (driverAgeError) {
        setDriverAgeError(driverAgeError)
      }
    }

    const isNewCar = !vehicleDetailsData.cars.some((el) => el.id === data.car)

    if (validate && !driverAgeError) {
      handleSubmit({
        ...data,
        driverAgeRange: isFilledFieldExist ? data.driverAgeRange : undefined,
        carCategoryId: Number(data.carCategoryId),
        carId: !isNewCar ? Number(data.car) : null,
        hirePoints: data.hirePoints?.map((el) => Number(el?.value)),
        airConditioning: replaceShortNotAvailable(data.airConditioning),
        fuel: replaceShortNotAvailable(data.fuel),
        transmission: replaceShortNotAvailable(data.transmission),
        carModelName: isNewCar ? data.car : undefined,
        carModelImageContent: isNewCar ? base64Image : undefined,
        sippCode: sipp || data.sippCode,
        quantity: Number(data.quantity),
      })
    }
  }

  const initValues = useMemo(() => {
    const initData = {
      carCategoryId: formValues?.carCategoryId
        ? String(formValues?.carCategoryId)
        : carCategoryId,
      companyClass: formValues?.companyClass || companyClass,
      sippCode: formValues?.sippCode || sippCode,
      car:
        vehicleDetailsData.cars.find(
          (el) => Number(el.id) === Number(formValues?.carId)
        )?.id || car,
      hirePoints:
        formValues?.hirePoints
          ?.map((el) =>
            locations.find((location) => Number(location.id) === Number(el))
          )
          .map((el) => ({ label: el?.name, value: el?.id })) || hirePoints,
      airConditioning:
        replaceNotAvailable(formValues?.airConditioning) || airConditioning,
      transmission:
        replaceNotAvailable(formValues?.transmission) || transmission,
      fuel: replaceNotAvailable(formValues?.fuel) || fuel,
      sits: formValues?.sits || sits,
      doors: formValues?.doors || doors,
      driverAgeRange: formValues?.driverAgeRange || driverAgeRange,
      carType: formValues?.carType || carType,
      carModelName,
      quantity: formValues?.quantity || quantity,
    }
    return initData
  }, [formValues, vehicleDetailsData])

  useEffect(() => {
    const fetchSeatsList = async () => {
      const response = await carSeatsRequest()
      if (response) {
        setSeatsList(
          response.data.items.map((item) => ({
            value: item.name,
            label: item.name,
          }))
        )
      }
    }
    const fetchDoorsList = async () => {
      const response = await carDoorsRequest()
      if (response) {
        setDoorsList(
          response.data.items.map((item) => ({
            value: item.name,
            label: item.name,
          }))
        )
      }
    }
    fetchDoorsList()
    fetchSeatsList()
  }, [])

  useDidUpdate(() => {
    setFormKey((prev) => prev + 1)
  }, [initValues])

  const carTabs = (checkValidation: () => ValidationType) => [
    {
      tab: CAR_TABS.DETAILS,
      key: CAR_TABS.DETAILS,
      children: (
        <VehicleInformationForm
          locations={locations}
          vehicleDetailsData={vehicleDetailsData}
          seatsData={{ loading: seatLoading, list: seatsList }}
          doorsData={{ loading: doorsLoading, list: doorsList }}
          changeActiveKey={setActiveKey}
          checkValidation={checkValidation}
        />
      ),
    },
    {
      tab: CAR_TABS.DRIVER_AGE_RANGE,
      key: CAR_TABS.DRIVER_AGE_RANGE,
      children: (
        <FormItem id={VEHICLE_DETAILS_FORM_VALUES.DRIVER_AGE_RANGE.ID}>
          {({ onChange, value }: FormItemChildren) => (
            <DriveAgeRanges
              driverAgeRangeData={value}
              error={driverAgeError}
              onDriverAgeRangeDataChange={(newValue) => {
                setDriverAgeError('')
                onChange(newValue)
              }}
            />
          )}
        </FormItem>
      ),
    },
  ]

  return !loading ? (
    <>
      <Form
        initValues={initValues}
        key={formKey}
        onSubmit={onSubmit}
        className={styles.form}
      >
        {({ values, isDirty, checkValidation }: FormChildren) => (
          <>
            {showLimitInfoAndTable && (
              <div className={styles.info}>
                <InfoIcon color="blue700" size="small" />
                <Typography name="body1WMedium" color="blue700">
                  {LIMIT_INFO}
                </Typography>
              </div>
            )}
            <div className={styles.container}>
              <LeftSide
                changeActiveKey={setActiveKey}
                activeKey={activeKey}
                tabs={carTabs(checkValidation)}
                driverAgeError={driverAgeError}
              />
              <RightSide
                activeKey={activeKey}
                formValues={values}
                isDirty={isDirty}
                setBase64Image={setBase64Image}
                isLoading={isLoading}
                sipp={sipp}
                handleChangeSipp={setSipp}
                isEditing={isEditing}
                vehicleDetailsData={vehicleDetailsData}
              />
            </div>
            {showLimitInfoAndTable && <VehiclesTable isDirty={isDirty} />}
          </>
        )}
      </Form>
    </>
  ) : null
}
