import { request } from '../utils'
import { ContentType, ErrorResponse } from '../types'
import { Filter, addQueryString } from 'utils/addQueryString'
import { PATH } from './constants'
import {
  ApplicationDetailsResponse,
  ApplicationFilters,
  ApplicationListResponse,
  ApplicationStatus,
  RejectReasonType,
  StatusCountersResponse,
} from './types'
import { FullCompanyResponse } from 'api/profile/types'
import { EnvironmentVariablesService } from 'utils/env'

class Api {
  public baseUrl: string =
    EnvironmentVariablesService.getEnv('REACT_APP_API_URL') || ''

  async getApplicationList(
    pageIndex = 0,
    pageSize = 10,
    status: ApplicationStatus,
    isArchive = false,
    fromDate: string,
    toDate: string,
    filters?: Filter[],
    sorting?: string
  ) {
    return request<ApplicationListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(
        PATH.APPLICATION_LIST,
        {
          pageSize,
          pageIndex,
          status: !isArchive ? status : '',
          fromDate,
          toDate,
          isArchive,
        },
        filters,
        sorting
      ),
      { method: 'GET' }
    )
  }

  async getStatusCounter(fromDate: string, toDate: string, filters?: Filter[]) {
    return request<{ statuses: StatusCountersResponse }, ErrorResponse>(
      this.baseUrl,
      addQueryString(
        PATH.STATUS_COUNTER,
        {
          fromDate,
          toDate,
          isArchive: false,
        },
        filters
      ),
      { method: 'GET' }
    )
  }

  async getApplicationFilters(
    fromDate: string,
    toDate: string,
    isArchive = false
  ) {
    return request<ApplicationFilters, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.FILTERS, {
        fromDate,
        toDate,
        isArchive,
      })
    )
  }

  async getApplicationDetails(id: string) {
    return request<ApplicationDetailsResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.APPLICATION_LIST}/${id}`
    )
  }

  async setApplicationStatusForCall(id: string, comment: string) {
    return request<ApplicationDetailsResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.APPLICATION_LIST}/${id}${PATH.APPLICATION_FOR_CALL}`,
      { method: 'POST' },
      { comment }
    )
  }

  async setApplicationStatusForSign(id: string) {
    return request<ApplicationDetailsResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.APPLICATION_LIST}/${id}${PATH.APPLICATION_FOR_SIGN}`,
      { method: 'POST' }
    )
  }

  async setApplicationStatusApprove(
    id: string,
    connectionType: 'ApiIntegration' | 'Static',
    companyInfo: FullCompanyResponse
  ) {
    return request<ApplicationDetailsResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.APPLICATION_LIST}/${id}${PATH.APPLICATION_APPROVE}`,
      { method: 'POST' },
      { connectionType, ...companyInfo },
      'json',
      ContentType.FORM_DATA
    )
  }

  async setApplicationStatusReject(
    id: string,
    rejectReasonType?: RejectReasonType,
    rejectReason?: string
  ) {
    return request<ApplicationDetailsResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.APPLICATION_LIST}/${id}${PATH.APPLICATION_REJECT}`,
      { method: 'POST' },
      { rejectReasonType, rejectReason }
    )
  }

  async getApplicationDuplicateList(
    pageIndex = 0,
    pageSize = 10,
    id: string,
    includeArchived = true
  ) {
    return request<ApplicationListResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(`${PATH.APPLICATION_LIST}/${id}/duplicates`, {
        pageSize,
        pageIndex,
        includeArchived,
      })
    )
  }
}

export const applicationsApi = new Api()
