import { Typography } from '@frontend/design_system'
import styles from './styles.module.scss'
import { ReactNode } from 'react'

interface IBlockProps {
  title: string
  children: ReactNode
}

export const Block = ({ title, children }: IBlockProps) => (
  <div className={styles.block}>
    <Typography name="subtitleWBold" className={styles.title} Tag="h3">
      {title}
    </Typography>
    {children}
  </div>
)
