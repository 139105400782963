import { useMemo } from 'react'
import { Typography, DaysPicker } from '@frontend/design_system'
import { STOP_SALES_DATE_FORMAT } from 'constants/date'
import { format } from 'date-fns'
import { formatPerDayData } from '../../utils'
import { DIAGRAMS, REVENUE_PER_DAY_TABLE_ITEMS } from 'texts/dashboard'
import { RevenuePerDayProps } from './types'
import { CURRENCY_ID } from '../../constants'
import { InfoTable } from '../InfoTable'
import { Diagram } from '../Diagram'
import styles from './styles.module.scss'

export const RevenuePerDay = ({
  dateRange,
  handleDateRange,
  initialDates,
  data,
}: RevenuePerDayProps) => {
  const { categories, revenuePerDay, revenue, rentalDays, groupCount } =
    formatPerDayData(data)

  const formattedBodyItems = useMemo(
    () =>
      data?.items.map((item) => ({
        items: [
          item.date.split('-').reverse().join('.'),
          `${item.revenuePerDay} ${CURRENCY_ID}`,
          `${item.revenue} ${CURRENCY_ID}`,
          item.rentalDays,
        ],
      })),
    [data]
  )

  const formattedFooterItem = useMemo(() => {
    if (!data) {
      return []
    }
    return [
      `${data.avgRevenuePerRentalDay} ${CURRENCY_ID}`,
      `${data.totalRevenue} ${CURRENCY_ID}`,
      data.totalRentalDays,
    ]
  }, [data])

  return (
    <div className={styles['revenue-per-day']}>
      <div className={styles['revenue-per-day-header']}>
        <div className={styles['date-picker']}>
          <DaysPicker
            range={dateRange}
            size="small"
            initialFrom={format(
              new Date(initialDates.start),
              STOP_SALES_DATE_FORMAT
            )}
            initialTo={format(
              new Date(initialDates.end),
              STOP_SALES_DATE_FORMAT
            )}
            handleRangeSelect={handleDateRange}
            variant="secondary"
          />
        </div>
      </div>

      <div className={styles['diagram']}>
        <div className={styles['diagram-header']}>
          <Typography name="subtitleWBold">
            {DIAGRAMS.REVENUE_PER_DAY}
          </Typography>
        </div>
        <Diagram
          groupCount={groupCount}
          categories={categories}
          series={revenuePerDay}
        />
      </div>
      <div className={styles['diagram']}>
        <div className={styles['diagram-header']}>
          <Typography name="subtitleWBold">{DIAGRAMS.REVENUE}</Typography>
        </div>
        <Diagram
          groupCount={groupCount}
          categories={categories}
          series={revenue}
        />
      </div>
      <div className={styles['diagram']}>
        <div className={styles['diagram-header']}>
          <Typography name="subtitleWBold">{DIAGRAMS.RENTAL_DAYS}</Typography>
        </div>
        <Diagram
          groupCount={groupCount}
          categories={categories}
          series={rentalDays}
        />
      </div>
      <InfoTable
        bodyItems={formattedBodyItems}
        detailsHeadItems={REVENUE_PER_DAY_TABLE_ITEMS}
        footerItems={formattedFooterItem}
      />
    </div>
  )
}
