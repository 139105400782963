import { useState } from 'react'
import { Typography } from '@frontend/design_system'
import { CarRentalDays } from './components/CarRentalDays'
import { supplierIdSelector } from 'redux/common/selectors'
import { RevenuePerDay } from './components/RevenuePerDay'
import { RevenuePerUnit } from './components/RevenuePerUnit'
import { TotalRevenue } from './components/TotalRevenue'
import { Tooltip } from 'ui/atoms/Tooltip'
import { InfoIcon } from 'ui/icons'
import { useAppSelector } from 'redux/hooks'
import { useSelector } from 'react-redux'
import { Container } from 'ui/molecules/Container'
import { MENU_NAMES } from 'texts/menuNames'
import { useCarRentalData } from '../hooks/useCarRentalData'
import { useFinancialData } from '../hooks/useFinancialData'
import { supplierCompanyNameSelector } from 'redux/profile/selectors'
import { DASHBOARD_TABS, DASHBOARD_SUB_TABS, TOOLTIP } from 'texts/dashboard'
import { DiagramTabs } from './components/DiagramTabs'
import styles from './styles.module.scss'

export const DashboardList = () => {
  const supplierCompanyId = useAppSelector(supplierIdSelector)
  const companyName = useSelector(supplierCompanyNameSelector)
  const [tab, setTab] = useState(DASHBOARD_TABS.CAR_RENTAL_DAY)
  const [subTab, setSubTab] = useState(DASHBOARD_SUB_TABS.PER_DAY)

  const {
    selectedCarRentalRange,
    setInitialCartRentalDate,
    initialCarRentalDate,
    setSelectedCarRentalRange,
    carRentalDaysData,
  } = useCarRentalData(supplierCompanyId)

  const {
    perUnitData,
    perDaysData,
    initialFinancialDate,
    setInitialFinancialDate,
    selectedFinancialRange,
    setSelectedFinancialRange,
  } = useFinancialData(supplierCompanyId)

  const handelNewInitDate = () => {
    setInitialCartRentalDate({
      end: selectedCarRentalRange.to,
      start: selectedCarRentalRange.from,
    })
    setInitialFinancialDate({
      end: selectedFinancialRange.to,
      start: selectedFinancialRange.from,
    })
  }

  const handleTab = (value: string) => {
    setTab(value)
    handelNewInitDate()
  }

  const handleSubTab = (value: string) => {
    setSubTab(value)
    handelNewInitDate()
  }

  const renderTabs = () => {
    if (tab === DASHBOARD_TABS.FINANCIAL_INFO) {
      if (subTab === DASHBOARD_SUB_TABS.PER_UNIT) {
        return (
          <RevenuePerUnit
            dateRange={selectedFinancialRange}
            handleDateRange={setSelectedFinancialRange}
            initialDates={initialFinancialDate}
            data={perUnitData}
          />
        )
      }
      if (subTab === DASHBOARD_SUB_TABS.REVENUE) {
        return (
          <TotalRevenue
            dateRange={selectedFinancialRange}
            handleDateRange={setSelectedFinancialRange}
            initialDates={initialFinancialDate}
            data={perUnitData}
          />
        )
      }
      return (
        <RevenuePerDay
          data={perDaysData}
          dateRange={selectedFinancialRange}
          handleDateRange={setSelectedFinancialRange}
          initialDates={initialFinancialDate}
        />
      )
    }
    return (
      <CarRentalDays
        dateRange={selectedCarRentalRange}
        handleDateRange={setSelectedCarRentalRange}
        initialDates={initialCarRentalDate}
        data={carRentalDaysData}
      />
    )
  }

  return (
    <Container
      title={
        <Typography Tag="div" name="H5" className={styles['container-title']}>
          {MENU_NAMES.DASHBOARD}
          <Tooltip
            size="primary"
            popupContent={
              <Typography
                Tag="div"
                name="Subtitle4"
                className={styles['popup-content']}
              >
                {TOOLTIP}
              </Typography>
            }
            placement="bottom"
          >
            <InfoIcon color="blue700" size="medium" />
          </Tooltip>
        </Typography>
      }
      companyName={companyName}
      withCompanyName={false}
      additionalComponent={
        <DiagramTabs
          tab={tab}
          handleTab={handleTab}
          subTab={subTab}
          handleSubTab={handleSubTab}
        />
      }
    >
      {renderTabs()}
    </Container>
  )
}
