import { useState, useMemo } from 'react'
import { Input, Form, FormItem, Typography } from '@frontend/design_system'
import { EditableRouteProps, FormItemType } from './types'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import { EMPTY_VALUE, FORM } from './constants'
import { onlyNumbersWithOneDotRegex } from 'constants/regex'

import styles from './styles.module.scss'

export const EditableCell = ({
  value,
  readOnly,
  handleUpdate,
  symbolEmptyValue,
}: EditableRouteProps) => {
  const initPriceValue = value !== undefined ? value.toString() : ''
  const [clicked, setClicked] = useState(false)
  const [routeRef, setRouteRef] = useState<HTMLDivElement | null>(null)
  const [prevPriceValue, setPrevPriceValue] = useState<
    string | number | undefined
  >(initPriceValue)
  const [priceValue, setPriceValue] = useState<string | number | undefined>(
    initPriceValue
  )
  const displayedEmptyValue = symbolEmptyValue || EMPTY_VALUE

  const handleSubmit = () => {
    setClicked(false)
    if (priceValue !== prevPriceValue) {
      setPrevPriceValue(priceValue)
      handleUpdate(priceValue)
    }
  }

  const handleClose = () => {
    setClicked(false)
    handleSubmit()
  }

  const handleClear = () => {
    setPriceValue('')
  }

  useOutsideClick(routeRef, handleClose)

  const initValues = useMemo(
    () => ({
      [FORM.PRICE_ID]: prevPriceValue,
    }),
    [prevPriceValue]
  )

  const handleChangeValue = (value: string) =>
    value.replace(onlyNumbersWithOneDotRegex, '')

  return (
    <div>
      {clicked ? (
        <Form
          className={styles.form}
          onSubmit={handleSubmit}
          initValues={initValues}
          isCapture
        >
          <div ref={setRouteRef} className={styles['editable-cell-block']}>
            <FormItem id={FORM.PRICE_ID}>
              {({ value, onChange }: FormItemType) => (
                <Input
                  value={value}
                  className={styles['editable-cell']}
                  onChange={(value: string) => {
                    const newValue = handleChangeValue(value)
                    onChange(newValue)
                    setPriceValue(newValue)
                  }}
                  iconOnClickRight={() => {
                    handleClear()
                    onChange('')
                  }}
                  size="tiny"
                  borderRadius="4px"
                  iconRight="ModalCloseIcon"
                  paddingLeft={8}
                  rightOffsetVariant="tiny"
                  spaceForError="none"
                  iconSpace="tiny"
                  isFocus
                />
              )}
            </FormItem>
          </div>
        </Form>
      ) : (
        <Typography
          onClick={() => setClicked(!clicked)}
          name="Subtitle7"
          Tag="div"
          className={styles['editable-cell-value']}
          color={readOnly ? 'blackMaster' : 'blue700'}
        >
          {priceValue || priceValue === 0 ? priceValue : displayedEmptyValue}
        </Typography>
      )}
    </div>
  )
}
