import { useState, useRef, useCallback, useMemo, ReactNode } from 'react'
import { GLOBAL_MODAL } from 'constants/DOM'
import { TABLE, FILTER } from 'texts/dropoffFee'
import { createPortal } from 'react-dom'
import { MODAL_WIDTH, OFFSET_ICON } from './constants'
import { useOutsideClick } from 'ui/hooks/useOutsideClick'
import { useDetectPositionChange } from 'ui/hooks/useDetectPositionChange'
import { Typography, OriginButton } from '@frontend/design_system'
import { SelectList } from 'ui/atoms/SelectList'
import { ResetIcon } from 'ui/icons/ResetIcon'
import { ACTIVITY_HISTORY } from 'texts/activityHistory'
import { joinClasses } from 'utils/joinClasses'
import { FilterIcon, ArrowLeftRightIcon } from 'ui/icons'
import { FilterRouteProps } from './types'
import styles from './styles.module.scss'

export const FilterRoute = ({
  items,
  onChange,
  isDisabled,
}: FilterRouteProps) => {
  const [isFilterListOpen, setFilterListOpen] = useState(false)
  const [pickUpLocation, setPickUpLocation] = useState('')
  const [dropOffLocation, setDropOffLocation] = useState('')
  const filterIconRef = useRef<HTMLDivElement | null>(null)
  const [isFilterApply, setFilterApply] = useState(false)
  const [filterRef, setFilterRef] = useState<HTMLDivElement | null>(null)
  const listRef = useRef<HTMLDivElement>(null)

  const isDisabledFilter = isDisabled && !isFilterApply

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    setFilterListOpen((prev) => !prev)
  }, [])

  const itemsDropOffLocation = useMemo(() => {
    if (!items) {
      return []
    }
    return items.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  }, [items])

  const filteredDropOffLocation = useMemo(() => {
    if (!pickUpLocation) return itemsDropOffLocation
    return itemsDropOffLocation.filter((item) => item.value !== pickUpLocation)
  }, [pickUpLocation, itemsDropOffLocation])

  const itemsPickUpLocation = useMemo(() => {
    if (!items) {
      return []
    }
    return items.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  }, [items])

  const filteredPickUpLocation = useMemo(() => {
    if (!dropOffLocation) return itemsPickUpLocation
    return itemsPickUpLocation.filter((item) => item.value !== dropOffLocation)
  }, [dropOffLocation, itemsPickUpLocation])

  const handlePickUpOnChange = (value: string) => {
    setPickUpLocation(value)
  }

  const handleDropOffOnChange = (value: string) => {
    setDropOffLocation(value)
  }

  const handleCloseFilter = () => {
    setFilterListOpen(false)
  }

  const handleReset = () => {
    setFilterApply(false)
    setPickUpLocation('')
    setDropOffLocation('')
    onChange({ pickUpLocation: '', dropOffLocation: '' })
  }

  const handleFilterApply = () => {
    setFilterApply(true)
    onChange({ pickUpLocation, dropOffLocation })
    handleCloseFilter()
  }

  const renderDropdown = useCallback((children: ReactNode) => {
    const iconElement = filterIconRef.current?.getBoundingClientRect()

    if (!iconElement) {
      return null
    }
    const { left, top, height } = iconElement

    return createPortal(
      <div
        style={{
          left: left - MODAL_WIDTH,
          top: top + OFFSET_ICON + height,
        }}
        className={styles['dropdown-wrapper']}
      >
        {children}
      </div>,
      document.getElementById(GLOBAL_MODAL) as HTMLElement
    )
  }, [])

  useOutsideClick(filterRef, handleCloseFilter, {
    enabled: true,
    additionalElements: filterIconRef?.current ? [filterIconRef.current] : [],
  })
  useDetectPositionChange({ ref: filterIconRef, onChange: handleCloseFilter })

  return (
    <>
      <div
        className={styles['filter-icon']}
        ref={filterIconRef}
        onClick={!isDisabledFilter ? handleClick : undefined}
      >
        <FilterIcon
          color={isDisabledFilter ? 'grey500' : 'blue700'}
          className={joinClasses(
            [styles['filter-active'], isFilterListOpen],
            [styles.disabled, isDisabledFilter]
          )}
        />
        {isFilterApply && <div className={styles.selected} />}
      </div>

      {isFilterListOpen && (
        <>
          {renderDropdown(
            <div className={styles['filter-modal']} ref={setFilterRef}>
              <div className={styles['filter-modal-header']}>
                <Typography
                  name="Subtitle3"
                  className={styles['header-first-title']}
                >
                  {TABLE.PICK_UP}
                </Typography>
                <ArrowLeftRightIcon size="medium" color="grey900" />
                <Typography
                  name="Subtitle3"
                  className={styles['header-second-title']}
                >
                  {TABLE.DROP_OFF}
                </Typography>
              </div>
              <div className={styles['filter-body']}>
                <div ref={listRef} className={styles['filter-left-side']}>
                  <SelectList
                    items={filteredPickUpLocation}
                    selectedValue={pickUpLocation}
                    onChange={handlePickUpOnChange}
                    containerRef={listRef}
                    search={{
                      placeholder: FILTER.SEARCH,
                      showSearch: true,
                    }}
                    notFoundTitle={FILTER.NOT_FOUND}
                    inputSize="medium"
                  />
                </div>
                <div ref={listRef} className={styles['filter-right-side']}>
                  <SelectList
                    items={filteredDropOffLocation}
                    selectedValue={dropOffLocation}
                    onChange={handleDropOffOnChange}
                    containerRef={listRef}
                    search={{
                      placeholder: FILTER.SEARCH,
                      showSearch: true,
                    }}
                    notFoundTitle={FILTER.NOT_FOUND}
                    inputSize="medium"
                  />
                </div>
              </div>
              <div className={styles['filter-footer']}>
                <div>
                  {(dropOffLocation || pickUpLocation) && (
                    <Typography
                      Tag="div"
                      color="blue700"
                      name="Button2"
                      className={styles['reset-button']}
                      onClick={handleReset}
                    >
                      <div className={styles['reset-icon']}>
                        <ResetIcon color="blue700" size="small" />
                      </div>
                      {ACTIVITY_HISTORY.RESET}
                    </Typography>
                  )}
                </div>
                <div className={styles['filter-apply']}>
                  <OriginButton
                    onClick={handleFilterApply}
                    label={TABLE.APPLY}
                    disabled={!pickUpLocation && !dropOffLocation}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
