import { Route, Routes } from 'react-router-dom'
import { DropoffFeeList } from './components/DropoffFeeList'
import { NotFound } from 'ui/molecules/NotFound'

export const DropoffFee = () => (
  <Routes>
    <Route path="/:companyId" element={<DropoffFeeList />} />
    <Route path="/" element={<DropoffFeeList />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)
