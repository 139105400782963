import { useMemo } from 'react'
import {
  COUNTRY_DUPLICATES_ERROR,
  CROSS_BORDER_POLICY_FORM_VALUES,
} from 'texts/termsAndConditions'
import { getRequiredMessage } from 'utils/getRequiredMessage'
import { ICountriesValue } from '../types'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'

const checkOnDuplicates = (countries: ICountriesValue[]) => {
  const allCountryCodes: { [key: string]: number[] } = {}
  const errors: string[] = Array(countries.length).fill('')

  for (let i = 0; i < countries.length; i++) {
    const { countryList } = countries[i]
    for (const { value } of countryList) {
      allCountryCodes[value] = allCountryCodes[value]
        ? [...allCountryCodes[value], i]
        : [i]

      if (allCountryCodes[value].length > 1) {
        allCountryCodes[value].forEach((pos) => {
          errors[pos] = COUNTRY_DUPLICATES_ERROR
        })
      }

      if (
        allCountryCodes[value].length === countries.length &&
        countries.length !== 1
      ) {
        return {
          message: errors,
        }
      }
    }
  }

  return errors.filter((error) => error).length > 0
    ? { message: errors }
    : false
}

const checkOnRequired = (countries: ICountriesValue[]) => {
  const requiredErrors = countries.map(({ countryList }) =>
    countryList.length
      ? ''
      : getRequiredMessage(CROSS_BORDER_POLICY_FORM_VALUES.COUNTRY_LIST.LABEL)
  )
  const withError = !!requiredErrors.filter((message) => message).length
  return (
    withError && {
      message: requiredErrors,
    }
  )
}

export const useCountriesRules = () => {
  const rules: Rule[] = useMemo(
    () => [
      {
        type: 'custom',
        message: getRequiredMessage(
          CROSS_BORDER_POLICY_FORM_VALUES.COUNTRY_LIST.LABEL
        ),
        value: checkOnRequired,
      },
      {
        type: 'custom',
        message: COUNTRY_DUPLICATES_ERROR,
        value: checkOnDuplicates,
      },
    ],
    []
  )

  return rules
}
