import { INSURANCE_INCLUDED_FORM_VALUES } from 'texts/termsAndConditions'

export const RADIO_BUTTONS = [
  {
    id: INSURANCE_INCLUDED_FORM_VALUES.INCLUDED.ID,
    label: INSURANCE_INCLUDED_FORM_VALUES.INCLUDED.LABEL,
  },
  {
    id: INSURANCE_INCLUDED_FORM_VALUES.EXCLUDED.ID,
    label: INSURANCE_INCLUDED_FORM_VALUES.EXCLUDED.LABEL,
  },
]
