import { Multiselect, Input, Button, LazyImage } from '@frontend/design_system'
import { onlyNumbersWithOneDotRegex } from 'constants/regex'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { CROSS_BORDER_POLICY_FORM_VALUES } from 'texts/termsAndConditions'
import { ValueType } from 'ui/molecules/Form/types'
import plus from 'assets/icons/blue700/blue700_plus_20x20.webp'
import trash from 'assets/icons/red600/red600_trash_20x20.webp'
import styles from './styles.module.scss'
import { ICountriesValue } from '../../types'
import { FormErrorMessage, SelectValue } from 'types/form'

interface ICountriesProps {
  values: ICountriesValue[]
  onChange: (value: ValueType) => void
  error: FormErrorMessage
  onBlur: VoidFunction
}

export const Countries = ({
  values,
  onChange,
  error,
  onBlur,
}: ICountriesProps) => {
  const handleChange = (id: number, newValues: Partial<ICountriesValue>) => {
    onChange(
      values.map((value) =>
        value.id === id
          ? {
              ...value,
              ...newValues,
            }
          : value
      )
    )

    if (typeof newValues.price !== 'undefined') {
      onBlur()
    }
  }

  const onAddRow = () => {
    onChange([...values, { id: Date.now(), countryList: [], price: '' }])
    onBlur()
  }

  const onDeleteRow = (id: number) => () => {
    onChange(values.filter((value) => value.id !== id))
    onBlur()
  }

  return (
    <div>
      {values.map(({ id, countryList, price }, index) => (
        <div key={id} className={styles.row}>
          <Multiselect
            value={countryList}
            placeholder={
              CROSS_BORDER_POLICY_FORM_VALUES.COUNTRY_LIST.PLACEHOLDER
            }
            error={Array.isArray(error) ? error[index] : error}
            items={COR_ITEMS}
            onChange={(newCountryList: SelectValue[]) =>
              handleChange(id, { countryList: newCountryList })
            }
            label={CROSS_BORDER_POLICY_FORM_VALUES.COUNTRY_LIST.LABEL}
            isRequired
          />
          <div className={styles['row-with-actions']}>
            <div className={styles.price}>
              <Input
                size="large"
                placeholder={CROSS_BORDER_POLICY_FORM_VALUES.PRICE.PLACEHOLDER}
                value={price}
                onChange={(newPrice: string) => {
                  const preparedPrice = newPrice.replace(
                    onlyNumbersWithOneDotRegex,
                    ''
                  )
                  handleChange(id, { price: preparedPrice })
                }}
                label={CROSS_BORDER_POLICY_FORM_VALUES.PRICE.LABEL}
              />
            </div>
            {values.length !== 1 && (
              <div className={styles.action}>
                <Button
                  label={<LazyImage src={trash} width={20} height={20} />}
                  variant="red"
                  onClick={onDeleteRow(id)}
                  size="large"
                />
              </div>
            )}
            {index === values.length - 1 && (
              <div className={styles.action}>
                <Button
                  label={<LazyImage src={plus} width={20} height={20} />}
                  variant="secondary-border"
                  onClick={onAddRow}
                  size="large"
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
