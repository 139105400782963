import { LOCATION_TABS, LOCATION_DETAILS } from 'texts/locationDetails'
import { OriginButton } from '@frontend/design_system'
import { useNavigate } from 'react-router-dom'
import { CANCEL, SAVE } from 'texts/uiTexts'
import { useAppSelector } from 'redux/hooks'
import { isOperatorSelector, userDataSelector } from 'redux/login/selectors'
import { ContactInformationBlock } from '../ContactInformationBlock'
import { WorkingHoursBlock } from '../WorkingHoursBlock'
import { ACCOUNT_STATUSES } from 'api/auth/constants'
import { URLS } from 'constants/urls'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { useCheckRequiredFields } from 'hooks/useCheckRequiredFields'
import { ReservationBlock } from '../ReservationBlock'
import { WaitingHoursBlock } from '../WaitingHoursBlock'
import { RightSideProps } from './types'
import { REQUIRED_FIELDS } from '../../constants'
import { FormContext } from 'ui/molecules/Form/Form'
import styles from './styles.module.scss'

export const RightSide = ({
  formValues,
  activeKey,
  isDirty,
  isWorkingHoursChanged,
  workingHours,
  isLoading,
  isEditing,
}: RightSideProps) => {
  const navigate = useNavigate()
  const isOperator = useAppSelector(isOperatorSelector)
  const { accountStatus } = useAppSelector(userDataSelector)
  const { companyId } = useCompanyInfo(isOperator)
  const { isDisabledFormButton } = useCheckRequiredFields(
    REQUIRED_FIELDS,
    FormContext
  )
  const isAccountApproved =
    isOperator || accountStatus === ACCOUNT_STATUSES.APPROVED

  const handleCancelClick = () => {
    if (isAccountApproved) {
      const path = isOperator
        ? `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/${companyId}`
        : `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}`
      navigate(path)
    } else {
      navigate(`${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}/add`)
    }
  }

  const renderInfoContent = (formValues: Record<string, unknown>) => {
    if (activeKey === LOCATION_TABS.INFORMATION) {
      return <ContactInformationBlock values={formValues} />
    }
    if (activeKey === LOCATION_TABS.WORKING_HOURS) {
      return <WorkingHoursBlock workingHours={workingHours} />
    }
    if (activeKey === LOCATION_TABS.RESERVATION) {
      return <ReservationBlock values={formValues} />
    }
    if (activeKey === LOCATION_TABS.WAITING_HOURS) {
      return <WaitingHoursBlock values={formValues} />
    }
  }
  return (
    <div className={styles.container}>
      {renderInfoContent(formValues)}
      <div className={styles['button-wrapper']}>
        {isEditing ? (
          <>
            <div className={styles['button-wrapper-short']}>
              <OriginButton
                onClick={handleCancelClick}
                loading={isLoading}
                label={CANCEL}
                size="large"
                variant="secondary-border"
              />
            </div>
            <div className={styles['button-wrapper-short']}>
              <OriginButton
                htmlType="submit"
                loading={isLoading}
                label={SAVE}
                disabled={!isDirty}
                size="large"
              />
            </div>
          </>
        ) : (
          <OriginButton
            htmlType="submit"
            loading={isLoading}
            label={LOCATION_DETAILS.BUTTONS.ADD_LOCATION_LABEL}
            disabled={isDisabledFormButton && !isWorkingHoursChanged}
            size="large"
          />
        )}
      </div>
    </div>
  )
}
