import { addQueryString } from 'utils/addQueryString'
import { ErrorResponse } from '../types'
import { request } from '../utils'
import { PATH } from './constants'
import {
  FutureBookingsResponse,
  CarRentalDaysResponse,
  RevenuePerDayResponse,
  RevenuePerUnitResponse,
  IGetCommonProps,
  IGetFutureBookingsProps,
} from './types'
import { EnvironmentVariablesService } from 'utils/env'

class Api {
  public baseUrl = EnvironmentVariablesService.getEnv('REACT_APP_API_URL') || ''

  async getCarRentalDays({
    companyId = '',
    dateStart = '',
    dateEnd = '',
  }: IGetCommonProps) {
    return request<CarRentalDaysResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.RENTAL_DAYS, { companyId, dateStart, dateEnd }),
      { method: 'GET' }
    )
  }

  async getFutureBookings({
    companyId = '',
    locationId = '',
    dateStart = '',
    dateEnd = '',
  }: IGetFutureBookingsProps) {
    return request<FutureBookingsResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.FUTURE_BOOKING, {
        companyId,
        locationId,
        dateStart,
        dateEnd,
      }),
      { method: 'GET' }
    )
  }

  async getRevenuePerDay({
    companyId = '',
    dateStart = '',
    dateEnd = '',
  }: IGetCommonProps) {
    return request<RevenuePerDayResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.REVENUE_PER_DAY, {
        companyId,
        dateStart,
        dateEnd,
      }),
      { method: 'GET' }
    )
  }

  async getRevenuePerUnit({
    companyId = '',
    dateStart = '',
    dateEnd = '',
  }: IGetCommonProps) {
    return request<RevenuePerUnitResponse, ErrorResponse>(
      this.baseUrl,
      addQueryString(PATH.REVENUE_PER_UNIT, {
        companyId,
        dateStart,
        dateEnd,
      }),
      { method: 'GET' }
    )
  }
}

export const dashboardApi = new Api()
