import { APPLICATION_STATUS } from 'modules/Applications/constants'
import { ApplicationStatus } from 'api/applications/types'

const { WaitingForCall, WaitingForSign, Approve, Reject, WaitingList } =
  APPLICATION_STATUS

export const getApplicationStatusType = (status?: ApplicationStatus) => {
  if (
    status === WaitingForCall ||
    status === WaitingForSign ||
    status === WaitingList
  ) {
    return 'warning'
  }
  if (status === Approve) {
    return 'ok'
  }
  if (status === Reject) {
    return 'error'
  }
  return 'warning'
}
