import {
  Input,
  LazyImage,
  Button,
  Select,
  Form,
  FormItem,
} from '@frontend/design_system'
import { VEHICLE_DETAILS_FORM_VALUES } from 'texts/vehicleDetails'
import { FormItemChildren, SelectValue } from 'types/form'
import { memo } from 'react'
import { ADD } from 'texts/common'
import { VEHICLE_DETAILS_VALIDATION_RULES } from '../../../../constants'
import styles from './styles.module.scss'
import { CarSelectProps } from './types'

const FORM_VALUES = {
  carModelName: '',
}

export const CarSelect = memo(({ cars }: CarSelectProps) => {
  const carsSelectValues: SelectValue[] = cars.map((item) => ({
    label: item.name,
    value: item.id,
    renderLabel: () => (
      <div className={styles['car-model-select-list-car']}>
        <LazyImage src={item.meta?.image} width={48} height={32} />
        {item.name}
      </div>
    ),
  }))

  const handleSubmit = (
    data: typeof FORM_VALUES,
    validate: boolean,
    onChange: (value: string) => void
  ) => {
    if (validate) {
      carsSelectValues.push({
        label: data.carModelName,
        value: data.carModelName,
      })
      onChange(data.carModelName)
    }
  }

  return (
    <FormItem
      id={VEHICLE_DETAILS_FORM_VALUES.CAR.ID}
      className={styles['car-model-select']}
      rules={VEHICLE_DETAILS_VALIDATION_RULES.CAR}
    >
      {({ value, error, onChange }: FormItemChildren) => (
        <Select
          selectedValue={value}
          items={carsSelectValues}
          inputTypographyName="Button2"
          placeholder={VEHICLE_DETAILS_FORM_VALUES.CAR.PLACEHOLDER}
          size="large"
          error={error}
          onChange={onChange}
          isRequired
          label={VEHICLE_DETAILS_FORM_VALUES.CAR.LABEL}
          search={{
            placeholder: VEHICLE_DETAILS_FORM_VALUES.CAR.SEARCH_PLACEHOLDER,
          }}
          renderDropdownFooter={() => (
            <Form
              isCapture
              onSubmit={(data: typeof FORM_VALUES, validate: boolean) =>
                handleSubmit(data, validate, onChange)
              }
              initValues={FORM_VALUES}
            >
              <div className={styles['car-model-select-footer']}>
                <FormItem
                  id={VEHICLE_DETAILS_FORM_VALUES.CAR_MODEL_NAME.ID}
                  rules={VEHICLE_DETAILS_VALIDATION_RULES.CAR_MODEL_NAME}
                >
                  {({
                    error: carError,
                    value: carValue,
                    onChange: carChange,
                  }: FormItemChildren) => (
                    <Input
                      size="large"
                      value={carValue}
                      error={carError}
                      placeholder={
                        VEHICLE_DETAILS_FORM_VALUES.CAR_MODEL_NAME.PLACEHOLDER
                      }
                      onChange={carChange}
                      spaceForError="none"
                      isRequired
                      label={VEHICLE_DETAILS_FORM_VALUES.CAR_MODEL_NAME.LABEL}
                    />
                  )}
                </FormItem>
                <Button
                  size="large"
                  htmlType="submit"
                  label={ADD}
                  typographyName="Button1"
                />
              </div>
            </Form>
          )}
        />
      )}
    </FormItem>
  )
})
