import { URLS } from 'constants/urls'
import { Route, Routes } from 'react-router-dom'
import { StopSaleAdd } from './components/StopSales/components/StopSaleAdd'
import { StopSales } from './components/StopSales'
import { Vehicles } from './components/Vehicles'
import { VehicleAdd } from './components/Vehicles/components/VehicleAdd'
import { Equipments } from './components/Equipments/Equipments'
import { EquipmentAdd } from './components/Equipments/components/EquipmentAdd/EquipmentAdd'
import { VehicleEdit } from './components/Vehicles/components/VehicleEdit'
import { EquipmentDetails } from './components/Equipments/components/EquipmentDetails'
import { PriceLists } from './components/PriceLists'
import { PriceListAdd } from './components/PriceLists/components/PriceListAdd'
import { NotFound } from 'ui/molecules/NotFound'
import { PriceListDetails } from './components/PriceLists/components/PriceListDetails'
import { Rates } from './components/PriceLists/components/Rates'
import { StopSaleEdit } from './components/StopSales/components/StopSaleEdit'
import { useDisableMenuItems } from 'hooks/useDisableMenuItems'
import { ProtectedRoute } from 'routes/ProtectedRoute'
import { MENU_NAMES } from 'texts/menuNames'
import { ReactNode } from 'react'

export const Fleet = () => {
  const { disableMenuItem } = useDisableMenuItems()

  const protectEquipments = (children: ReactNode) => (
    <ProtectedRoute isUnavailable={disableMenuItem(MENU_NAMES.EQUIPMENTS)}>
      {children}
    </ProtectedRoute>
  )

  return (
    <Routes>
      <Route path={`${URLS.VEHICLES}/:companyId`} element={<Vehicles />} />
      <Route path={`${URLS.VEHICLES}`} element={<Vehicles />} />
      <Route
        path={`${URLS.VEHICLES}/:companyId/add`}
        element={<VehicleAdd />}
      />
      <Route path={`${URLS.VEHICLES}/add`} element={<VehicleAdd />} />
      <Route
        path={`${URLS.VEHICLES}/:companyId/:id/edit`}
        element={<VehicleEdit />}
      />
      <Route
        path={`${URLS.STOP_SALES}/:companyId/add`}
        element={protectEquipments(<StopSaleAdd />)}
      />
      <Route
        path={`${URLS.STOP_SALES}/add`}
        element={protectEquipments(<StopSaleAdd />)}
      />
      <Route
        path={`${URLS.STOP_SALES}`}
        element={protectEquipments(<StopSales />)}
      />
      <Route
        path={`${URLS.STOP_SALES}/:companyId`}
        element={protectEquipments(<StopSales />)}
      />
      <Route
        path={`${URLS.STOP_SALES}/:companyId/:id/edit`}
        element={protectEquipments(<StopSaleEdit />)}
      />
      <Route
        path={`${URLS.EQUIPMENTS}`}
        element={protectEquipments(<Equipments />)}
      />
      <Route
        path={`${URLS.EQUIPMENTS}/:companyId`}
        element={protectEquipments(<Equipments />)}
      />
      <Route
        path={`${URLS.EQUIPMENTS}/:companyId/add`}
        element={protectEquipments(<EquipmentAdd />)}
      />
      <Route
        path={`${URLS.EQUIPMENTS}/add`}
        element={protectEquipments(<EquipmentAdd />)}
      />
      <Route
        path={`${URLS.EQUIPMENTS}/:companyId/:id/edit`}
        element={protectEquipments(<EquipmentDetails />)}
      />
      <Route
        path={`${URLS.PRICE_LIST}/:companyId`}
        element={protectEquipments(<PriceLists />)}
      />
      <Route
        path={`${URLS.PRICE_LIST}`}
        element={protectEquipments(<PriceLists />)}
      />
      <Route
        path={`${URLS.PRICE_LIST}/:companyId/add`}
        element={protectEquipments(<PriceListAdd />)}
      />
      <Route
        path={`${URLS.PRICE_LIST}/add`}
        element={protectEquipments(<PriceListAdd />)}
      />
      <Route
        path={`${URLS.PRICE_LIST}/:companyId/:priceListId/edit`}
        element={protectEquipments(<PriceListDetails />)}
      />
      <Route
        path={`${URLS.PRICE_LIST}/:companyId/:priceListId/rates`}
        element={protectEquipments(<Rates />)}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}
