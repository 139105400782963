import { LOCATION_ADDING_FORM_VALUES } from 'texts/locationDetails'
import { DriverAgeRange } from 'api/fleet/types'
import { Cor } from 'types'
import { Typography } from '@frontend/design_system'
import { CheckedIcon } from 'ui/icons'
import { WaitingHoursBlockProps } from './types'
import {
  WAITING_HOURS_FORM_FIELDS,
  WAITING_HOUR_RANGE_VALUES,
} from '../../constants'
import styles from './styles.module.scss'

export const WaitingHoursBlock = ({ values }: WaitingHoursBlockProps) => {
  const renderItem = (
    id: string,
    value?: string | Cor | DriverAgeRange | undefined
  ) => {
    let formatValue = value

    if (
      id === LOCATION_ADDING_FORM_VALUES.WAITING_HOURS_RANGE.ID &&
      value &&
      typeof value === 'string'
    ) {
      formatValue = WAITING_HOUR_RANGE_VALUES[value]
    }

    return (
      <div
        className={styles['waiting-hours-info']}
        key={WAITING_HOURS_FORM_FIELDS[id]}
      >
        <div className={styles['waiting-hours-info-icon']}>
          <CheckedIcon
            color={formatValue ? 'green700Master' : 'grey500'}
            size="medium"
          />
        </div>

        <Typography name="body1WBold">
          {WAITING_HOURS_FORM_FIELDS[id]}:
          <Typography
            name="body1WMedium"
            className={styles['waiting-hours-info-value']}
          >
            {formatValue || '-'}
          </Typography>
        </Typography>
      </div>
    )
  }

  return (
    <div>
      {Object.keys(WAITING_HOURS_FORM_FIELDS).map((key: string) => {
        const value = values[key as keyof typeof values]
        return renderItem(key, value)
      })}
    </div>
  )
}
