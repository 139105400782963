import {
  APPLICATION,
  APPLICATION_DUPLICATE,
  APPLICATION_TITLE,
  APPLICATIONS_STATUS_VALUES,
  DUPLICATE,
  ID,
} from 'texts/applications'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { Container } from 'ui/molecules/Container'
import { ContactInformation } from './components/ContactInformation'
import { SupplierLocation } from './components/SupplierLocation'
import { StatusInfo } from './components/StatusInfo'
import { OtherInformation } from './components/OtherInformation'
import {
  OriginButton,
  Typography,
  LazyImage,
  Form,
} from '@frontend/design_system'
import { useNavigate, useParams } from 'react-router-dom'
import { CANCEL, SAVE } from 'texts/uiTexts'
import { StatusBadge } from 'ui/atoms/StatusBadge'
import duplicateIconSrc from 'assets/icons/grey400/grey400_duplicate_16x16.webp'
import { getApplicationStatusType } from './utils'
import { APPLICATION_STATUS } from 'modules/Applications/constants'
import { useApiRequest } from 'hooks/useApiRequest'
import { applicationsApi, ApplicationsApiTypes } from 'api'
import { useEffect, useMemo, useState } from 'react'
import { PROFILE_ADD_TITLE } from 'texts/profileDetails'
import { ProfileDetailsForm } from 'ui/components/ProfileDetailsForm'
import { initialFormValues } from 'ui/components/ProfileDetailsForm/constants'
import { useChangeStatusRequest } from './components/hooks/useChangeStatusRequest'
import { ApplicationStatus } from 'api/applications/types'
import { ApplicationProps, SubmitData } from './types'
import countryOfResidence from 'constants/countryOfResidence'
import { breadcrumbs } from 'constants/breadcrumbs'
import { URLS } from 'constants/urls'
import { FormChildren } from 'types/form'
import styles from './styles.module.scss'

export const Application = ({ variant = 'default' }: ApplicationProps) => {
  const navigate = useNavigate()
  const { id, status, duplicateId } = useParams()

  const breadcrumbsList = {
    archive: [breadcrumbs.applicationsArchive],
    default: [breadcrumbs.applications],
    duplicate: [
      {
        link: `${URLS.APPLICATIONS}/${status}/${id}`,
        label: APPLICATION_TITLE,
      },
      {
        link: `${URLS.APPLICATIONS}/${status}/${id}${URLS.APPLICATIONS_DUPLICATE}`,
        label: APPLICATION_DUPLICATE.TITLE,
      },
    ],
  }

  const title = {
    default: APPLICATION_TITLE,
    duplicate: APPLICATION_DUPLICATE.DETAILS,
    archive: APPLICATION_TITLE,
  }

  const [applicationDetails, setApplicationDetails] = useState<
    ApplicationsApiTypes.ApplicationDetailsResponse['applicationInfo'] | null
  >(null)

  const applicationDetailsRequest = useApiRequest((id) =>
    applicationsApi.getApplicationDetails(id)
  )

  const handleBack = () => navigate(-1)

  const changeStatusRequest = useChangeStatusRequest()

  useEffect(() => {
    const fetchApplicationDetails = async (id: string) => {
      const response = await applicationDetailsRequest.apiRequest(id)
      if (response) {
        setApplicationDetails(response?.data.applicationInfo)
      }
    }
    if (id) {
      const applicationId =
        variant === 'duplicate' && duplicateId ? duplicateId : id

      fetchApplicationDetails(applicationId)
    }
  }, [id, duplicateId, variant])

  const handleSubmit = async (data: SubmitData, validate: boolean) => {
    if (validate) {
      const resultValues: Record<string, unknown> = {}
      Object.keys(initialFormValues).forEach((key) => {
        resultValues[key] = data[key as keyof SubmitData]
      })
      const {
        status,
        rejectReason,
        rejectReasonType,
        applicationId,
        comment,
        connectionType,
      } = data
      const values = {
        companyInfo: {
          ...resultValues,
          logo: data.logo?.[0],
          phoneAreaCode: data.phoneCor?.code
            ? String(data.phoneCor?.phoneCode)
            : '',
          phoneCountryCode: data.phoneCor?.code
            ? String(data.phoneCor?.code)
            : '',
        },
        status,
        rejectReason,
        rejectReasonType,
        applicationId,
        comment,
        connectionType,
      }
      const response = await changeStatusRequest(
        values,
        values.status as ApplicationStatus
      )
      if (response) {
        handleBack()
      }
    }
  }

  const countries = useMemo(
    () =>
      countryOfResidence.reduce(
        (countries: { [key: string]: string }, country) => {
          countries[country.code] = country.name
          return countries
        },
        {}
      ),
    []
  )

  const initialValues = useMemo(
    () => ({
      ...initialFormValues,
      companyName: applicationDetails?.companyName || '',
      contactName: applicationDetails?.contactName || '',
      contactEmail: applicationDetails?.contactEmail || '',
      email: applicationDetails?.contactEmail || '',
      contactPersonName: applicationDetails?.contactName || '',
      applicationId: applicationDetails?.applicationId || '',
      currentPhone:
        `+${applicationDetails?.phoneAreaCode}${applicationDetails?.phone}` ||
        '',
      countriesWithFleets:
        applicationDetails?.countriesWithFleets?.map((countryCode) => ({
          label: countries[countryCode],
          value: countries[countryCode],
        })) || [],
      locationsCount: applicationDetails?.locationsCount || '',
      locationTypes:
        applicationDetails?.locationTypes?.map((el) => ({
          label: el,
          value: el,
        })) || [],
      vehiclesCount: applicationDetails?.vehiclesCount || '',
      apiIntegrationType: applicationDetails?.apiIntegrationType || '',
      status: '',
      comment: applicationDetails?.comment || '',
      rejectReasonType: applicationDetails?.rejectReasonType || '',
      rejectReason: applicationDetails?.rejectReason || '',
      connectionType: applicationDetails?.connectionType || 'ApiIntegration',
      phone: applicationDetails?.phone,
      phoneCor: countryOfResidence.find(
        ({ code }) => code === applicationDetails?.phoneCountryCode
      ),
    }),
    [applicationDetails]
  )

  const applicationStatus = applicationDetails?.status as ApplicationStatus

  const isShouldRenderDuplicateButton =
    applicationDetails?.hasDuplicates &&
    applicationStatus === APPLICATION_STATUS.WaitingList &&
    variant === 'default'

  const isReadOnly = useMemo(
    () =>
      [
        APPLICATION_STATUS.Approve,
        APPLICATION_STATUS.Reject,
        APPLICATION_STATUS.Archive,
      ].includes(applicationStatus) || variant !== 'default',
    [applicationStatus]
  )

  return (
    <Container
      title={
        <div className={styles.title}>
          <Typography Tag="h5" name="H5">
            {title[variant]}
          </Typography>
          <StatusBadge
            status={getApplicationStatusType(applicationStatus)}
            label={APPLICATIONS_STATUS_VALUES[applicationStatus]}
          />
        </div>
      }
      breadcrumbList={breadcrumbsList[variant]}
      currentPageLabel={title[variant]}
      withCompanyName={false}
      rightBlock={
        isShouldRenderDuplicateButton && (
          <div className={styles['duplicate-button-wrapper']}>
            <Typography
              Tag="a"
              href={`${window.location.href}/duplicate`}
              target="_blank"
            >
              <OriginButton
                label={DUPLICATE}
                iconRight={
                  <LazyImage width={16} height={16} src={duplicateIconSrc} />
                }
              />
            </Typography>
          </div>
        )
      }
    >
      {!applicationDetailsRequest.loading && (
        <Form
          className={styles.form}
          onSubmit={handleSubmit}
          initValues={initialValues}
          formId="application-details-form"
          isCleanUpOnUnmount={false}
        >
          {({ isDirty, values, errors }: FormChildren) => (
            <>
              <ContentWrapper
                className={styles.wrapper}
                title={APPLICATION.CONTACT_INFORMATION}
                additionalContent={
                  <StatusBadge
                    status="pending"
                    label={`${ID} - ${applicationDetails?.applicationId}`}
                  />
                }
              >
                <ContactInformation />
              </ContentWrapper>
              <ContentWrapper
                className={styles.wrapper}
                title={APPLICATION.SUPPLIER_LOCATION}
              >
                <SupplierLocation />
              </ContentWrapper>
              <ContentWrapper
                className={styles.wrapper}
                title={APPLICATION.OTHER}
              >
                <OtherInformation />
              </ContentWrapper>
              <ContentWrapper
                className={styles.wrapper}
                title={APPLICATION.STATUS_INFO}
              >
                <StatusInfo
                  status={applicationStatus}
                  values={values}
                  readonly={isReadOnly}
                />
              </ContentWrapper>
              {applicationStatus === APPLICATION_STATUS.WaitingForSign &&
                values.status === APPLICATION_STATUS.Approve &&
                values.connectionType === 'Static' && (
                  <div className={styles['profile-details-form-wrapper']}>
                    <Typography name="H5">{PROFILE_ADD_TITLE}</Typography>
                    <ProfileDetailsForm values={values} errors={errors} />
                  </div>
                )}
              {!isReadOnly && (
                <div className={styles.buttons}>
                  <div className={styles['button-wrapper']}>
                    <OriginButton
                      variant="secondary-border"
                      onClick={handleBack}
                      label={CANCEL}
                    />
                  </div>
                  <div className={styles['button-wrapper']}>
                    <OriginButton
                      htmlType="submit"
                      disabled={!isDirty}
                      label={SAVE}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </Form>
      )}
    </Container>
  )
}
