import { useLocation, useNavigate } from 'react-router-dom'
import { NavigationItem } from './components/NavigationItem/NavigationItem'
import styles from './styles.module.scss'
import { NavigationProps } from './types'
import { useNavigationItems } from 'ui/hooks/useNavigationItems'

export const Navigation = ({ id }: NavigationProps) => {
  const items = useNavigationItems()
  const navigate = useNavigate()
  const location = useLocation()

  const activeItem = items.find((el) =>
    location.pathname.includes(el.activePath || el.getPath(id))
  )

  return (
    <div className={styles.navigation}>
      {items.map(({ label, getPath, isDisabled, withCheckbox }) => (
        <NavigationItem
          key={label}
          isActive={activeItem?.label === label}
          label={label}
          onClick={() => navigate(getPath(id))}
          isDisabled={isDisabled}
          id={id}
          withCheckbox={withCheckbox}
        />
      ))}
    </div>
  )
}
