import { FilterItem, FilterItems } from 'types/filters'

export const getSelectedFiltersCount = (filters: {
  [key: string]: FilterItem[]
}) =>
  Object.entries(filters).reduce((acc, [, values]) => {
    let count = 0
    values.forEach((el) => {
      if (el.selected) {
        count++
      }
    })
    return acc + count
  }, 0)

export const getSelectedFiltersList = (filters: {
  [key: string]: FilterItem[]
}) =>
  Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [
      key,
      values.filter(({ selected }) => selected).map(({ value }) => value),
    ])
  )

export const toggleFilter = (
  key: string,
  value: string,
  initialFilters: FilterItems
) => {
  const currentFilters = structuredClone(initialFilters)
  const index = currentFilters[key].findIndex((el) => el.value === value)
  currentFilters[key][index].selected = !currentFilters[key][index].selected
  return currentFilters
}
