export const ACTIVITY_HISTORY_FILTER_KEY = 'activityHistory'
export const APPLICATIONS_FILTER_KEY = 'applications'
export const PRICE_LIST_FILTER_KEY = 'priceList'
export const STOP_SALES_FILTER_KEY = 'stopSales'
export const PROFILE_LIST_FILTER_KEY = 'profileList'

export const FILTER_KEYS: Record<string, string> = {
  categories: 'category',
  users: 'userId',
  activityTypes: 'actionType',
  elements: 'entityId',
  auditDate: 'auditDate',
  companies: 'companyName',
  accountStatus: 'accountStatus',
}

export const SORTING_KEYS: Record<string, string> = {
  categories: 'Category',
  users: 'UserId',
  activityTypes: 'ActionType',
  elements: 'EntityId',
  auditDate: 'AuditDate',
  companies: 'CompanyName',
  submission: 'CreationDate',
  accountStatus: 'accountStatus',
  processDate: 'ProcessDate',
}

export const ITEMS_WITH_SEARCH = [
  'users',
  'companies',
  'elements',
  'applicationIds',
  'emails',
]

export const ITEMS_WITH_SORTING = [
  'users',
  'companies',
  'auditDate',
  'submission',
  'accountStatus',
  'processDate',
]

export const ITEMS_WITHOUTH_FILTERING = [
  'auditDate',
  'submission',
  'processDate',
]
