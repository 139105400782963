import { useEffect, useState } from 'react'
import { Notification } from './components/Notification'
import styles from './styles.module.scss'
import { useApiRequest } from 'hooks/useApiRequest'
import { notificationsApi, NotificationsApiTypes } from 'api'
import { Typography, LazyImage } from '@frontend/design_system'
import noDataSrc from 'assets/img/noDataImage.png'

interface INotificationListProps {
  onToggle: () => void
  forceUpdateCount: () => void
}

export const NotificationList = ({
  onToggle,
  forceUpdateCount,
}: INotificationListProps) => {
  const [notifications, setNotifications] = useState<
    NotificationsApiTypes.INotificationResponse[]
  >([])

  const { apiRequest: getNotifications, loading } = useApiRequest(() =>
    notificationsApi.getNotifications()
  )

  useEffect(() => {
    const init = async () => {
      const notificationsResponse = await getNotifications()

      if (notificationsResponse) {
        setNotifications(notificationsResponse.data.notifications)
      }
    }

    init()
  }, [])

  const renderContent = () => {
    if (loading) {
      return null
    }

    if (notifications.length) {
      return notifications.map(
        ({ id, title, message, companyName, companyId }) => (
          <Notification
            key={id}
            onToggle={onToggle}
            id={id}
            title={title}
            message={message}
            companyName={companyName}
            companyId={companyId}
            forceUpdateCount={forceUpdateCount}
          />
        )
      )
    }

    return (
      <div className={styles.empty}>
        <LazyImage src={noDataSrc} width={200} height={127} />
        <Typography name="subtitleWBold">No data</Typography>
      </div>
    )
  }

  return <div className={styles.notifications}>{renderContent()}</div>
}
