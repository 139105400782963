import {
  CAR_TABS,
  VEHICLE_DETAILS,
  VEHICLE_DETAILS_FORM_VALUES,
} from 'texts/vehicleDetails'
import {
  VEHICLE_DETAILS_FIELDS,
  VEHICLE_DETAILS_VALIDATION_RULES,
} from '../../constants'
import {
  Input,
  OriginButton,
  Multiselect,
  LazyImage,
  FormItem,
  FormContext,
  Typography,
  Select,
} from '@frontend/design_system'
import { useSelectValues } from 'hooks/useSelectValues'
import { AirConditioning, Fuel, Transmission } from 'api/fleet/types'
import { VehicleInformationFormProps } from './types'
import { ContextType } from 'ui/molecules/Form/types'
import { useContext } from 'react'
import { NEXT } from 'texts/common'
import arrowRight from 'assets/img/arrowRight.webp'
import { CarSelect } from './components/CarSelect/CarSelect'
import { formatValuesToSelectItems } from 'utils/formatters'
import { isNotEmptyValueObject } from 'utils/object'
import { FormItemChildren } from 'types/form'
import styles from './styles.module.scss'

export const VehicleInformationForm = ({
  vehicleDetailsData,
  locations,
  seatsData,
  doorsData,
  changeActiveKey,
  checkValidation,
}: VehicleInformationFormProps) => {
  const carCategoriesSelectValues = useSelectValues(
    vehicleDetailsData.carCategories
  )
  const carTypesSelectValues = useSelectValues(vehicleDetailsData.carTypes)
  const locationsSelectValues = useSelectValues(locations)
  const { setErrors } = useContext<ContextType>(FormContext)

  const goNextTab = () => {
    const { newErrors } = checkValidation()
    const filteredErrors = Object.keys(VEHICLE_DETAILS_FIELDS).reduce(
      (acc: Record<string, unknown>, item) => {
        if (newErrors[item]) {
          acc[item] = newErrors[item]
        }
        return acc
      },
      {}
    )

    if (!isNotEmptyValueObject(filteredErrors)) {
      changeActiveKey(CAR_TABS.DRIVER_AGE_RANGE)
      setErrors(() => ({}))
    } else {
      setErrors(() => filteredErrors)
    }
  }

  return (
    <>
      <CarSelect cars={vehicleDetailsData.cars} />
      <div className={styles['form-items-row']}>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.ID}
          className={styles['form-item']}
          rules={VEHICLE_DETAILS_VALIDATION_RULES.CAR_CATEGORY}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Select
              items={carCategoriesSelectValues}
              placeholder={VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.PLACEHOLDER}
              size="large"
              inputTypographyName="Button2"
              selectedValue={String(value)}
              error={error}
              isRequired
              onChange={onChange}
              label={VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.LABEL}
              search={{
                placeholder:
                  VEHICLE_DETAILS_FORM_VALUES.CAR_CATEGORY.SEARCH_PLACEHOLDER,
              }}
            />
          )}
        </FormItem>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.ID}
          className={styles['form-item']}
          rules={VEHICLE_DETAILS_VALIDATION_RULES.CAR_TYPE}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Select
              items={carTypesSelectValues}
              placeholder={VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.PLACEHOLDER}
              size="large"
              inputTypographyName="Button2"
              selectedValue={String(value)}
              error={error}
              isRequired
              onChange={onChange}
              label={VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.LABEL}
              search={{
                placeholder:
                  VEHICLE_DETAILS_FORM_VALUES.CAR_TYPE.SEARCH_PLACEHOLDER,
              }}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.ID}
          className={styles['form-item']}
          rules={VEHICLE_DETAILS_VALIDATION_RULES.TRANSMISSION}
        >
          {({ onChange, value, error }: FormItemChildren) => (
            <Select
              selectedValue={value}
              items={formatValuesToSelectItems(Transmission)}
              size="large"
              inputTypographyName="Button2"
              onChange={onChange}
              error={error}
              isRequired
              placeholder={VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.PLACEHOLDER}
              label={VEHICLE_DETAILS_FORM_VALUES.TRANSMISSION.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.FUEL.ID}
          className={styles['form-item']}
          rules={VEHICLE_DETAILS_VALIDATION_RULES.FUEL}
        >
          {({ onChange, value, error }: FormItemChildren) => (
            <Select
              selectedValue={value}
              items={formatValuesToSelectItems(Fuel)}
              size="large"
              inputTypographyName="Button2"
              error={error}
              onChange={onChange}
              placeholder={VEHICLE_DETAILS_FORM_VALUES.FUEL.PLACEHOLDER}
              isRequired
              label={VEHICLE_DETAILS_FORM_VALUES.FUEL.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.SEATS.ID}
          className={styles['form-item']}
        >
          {({ onChange, value }: FormItemChildren) => (
            <Select
              selectedValue={value}
              items={seatsData.list}
              size="large"
              loading={seatsData.loading}
              onChange={onChange}
              label={VEHICLE_DETAILS_FORM_VALUES.SEATS.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.DOORS.ID}
          className={styles['form-item']}
        >
          {({ onChange, value }: FormItemChildren) => (
            <Select
              selectedValue={value}
              items={doorsData.list}
              loading={doorsData.loading}
              size="large"
              onChange={onChange}
              label={VEHICLE_DETAILS_FORM_VALUES.DOORS.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.ID}
          className={styles['form-item']}
          rules={VEHICLE_DETAILS_VALIDATION_RULES.COMPANY_CLASS}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              size="large"
              value={value}
              error={error}
              placeholder={
                VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.PLACEHOLDER
              }
              onChange={onChange}
              tooltip={
                <Typography name="caption1WMedium">
                  {VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.TOOLTIP}
                </Typography>
              }
              spaceForError="none"
              isRequired
              label={VEHICLE_DETAILS_FORM_VALUES.COMPANY_CLASS.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.ID}
          className={styles['form-item']}
        >
          {({ onChange, value }: FormItemChildren) => (
            <Select
              selectedValue={value}
              items={formatValuesToSelectItems(AirConditioning)}
              size="large"
              onChange={onChange}
              label={VEHICLE_DETAILS_FORM_VALUES.AIR_CONDITIONING.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.ID}
          className={styles['form-item']}
          rules={VEHICLE_DETAILS_VALIDATION_RULES.HIRE_POINTS}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Multiselect
              value={value}
              onChange={onChange}
              placeholder={VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.PLACEHOLDER}
              isRequired
              spaceForError="none"
              error={error}
              unitName={{
                plural: VEHICLE_DETAILS.SEARCH_VALUE,
              }}
              label={VEHICLE_DETAILS_FORM_VALUES.HIRE_POINTS.LABEL}
              items={locationsSelectValues}
            />
          )}
        </FormItem>
        <FormItem
          id={VEHICLE_DETAILS_FORM_VALUES.QUANTITY.ID}
          className={styles['form-item']}
          rules={VEHICLE_DETAILS_VALIDATION_RULES.QUANTITY}
        >
          {({ value, error, onChange }: FormItemChildren) => (
            <Input
              size="large"
              value={value}
              type="integer"
              typographyName="Button2"
              error={error}
              placeholder={VEHICLE_DETAILS_FORM_VALUES.QUANTITY.PLACEHOLDER}
              onChange={onChange}
              tooltip={
                <Typography name="caption1WMedium">
                  {VEHICLE_DETAILS_FORM_VALUES.QUANTITY.TOOLTIP}
                </Typography>
              }
              spaceForError="none"
              isRequired
              label={VEHICLE_DETAILS_FORM_VALUES.QUANTITY.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-item-right']}>
        <div>
          <OriginButton
            variant="link"
            size="large"
            onClick={goNextTab}
            label={NEXT}
            typographyName="Button1"
            iconRight={<LazyImage src={arrowRight} width={16} height={16} />}
          />
        </div>
      </div>
    </>
  )
}
