export const PATH = {
  CURRENCIES: 'refs/currency/list',
  COUNTRIES: 'refs/country/list',
  ALL_HIRE_POINTS: 'refs/hirePoint/list',
  ALL_CAR_GROUPS: 'refs/cargroup/list',
  ALL_CAR_TYPES: '/refs/carType/list',
  ALL_CARS: 'refs/car/list',
  CITIES: 'refs/city/list',
  LOCATIONS: 'refs/location/list',
  AVAILABLE_LOCATIONS: 'refs/location/available',
  AVAILABLE_CITIES: 'refs/city/available',
  AVAILABLE_COUNTRIES: 'refs/country/available',
  ALL_EXTRAS: 'refs/extratype/list',
  SEATS: 'refs/vehicle/sits/list',
  DOORS: '/refs/vehicle/doors/list',
}
