export const PUBLIC_URLS: { [key: string]: string } = {
  SIGNIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  REGISTER: '/register',
  CONFIRM_REGISTRATION: '/confirm-email',
}

export const URLS: { [key: string]: string } = {
  ...PUBLIC_URLS,
  MAIN: '/main',
  PROFILE: '/profile',
  PROFILE_LIST: '/profile-list',
  DETAILS: '/details',
  PROFILE_ADD: '/profile-list/add',
  EDIT: ':companyId/:id/edit',
  DASHBOARD: '/dashboard',
  CAR_RENTAL_DAYS: '/car-rental-days',
  FINANCIAL_INFO: '/financial-info',
  LOCATIONS: '/locations',
  LIST_OF_LOCATIONS: '/list-of-locations',
  ARCHIVE: '/archive',
  ACTIVITY_HISTORY: '/activity-history',
  RESTORE: '/archive',
  FLEET: '/fleet',
  VEHICLES: '/vehicles',
  STOP_SALES: '/stop-sales',
  EQUIPMENTS: '/equipments',
  TERMS_AND_CONDITIONS: '/terms-and-conditions',
  APPLICATIONS: '/applications',
  APPLICATIONS_LIST: '/applications-list',
  APPLICATIONS_ARCHIVE: '/archive',
  APPLICATION: '/:status/:id',
  PRICE_LIST: '/price-list',
  DROP_OFF_FEE: '/drop-off-fee',
  ADD: '/add',
  APPLICATIONS_DUPLICATE: '/duplicate',
  BOOKING_HISTORY: '/booking-history',
  RENTAL_INCLUDES: '/rental-includes',
  RENTAL_EXCLUDES: '/rental-excludes',
  DRIVER_REQUIREMENTS: '/driver-requirements',
  FUEL_POLICY: '/fuel-policy',
  CROSS_BORDER_POLICY: '/cross-border-policy',
  INSURANCE_INCLUDED: '/insurance-included',
  DRIVER_CREDIT: '/driver-credit',
  EXCESS_DEPOSIT: '/excess-deposit',
  PENDING: '/pending',
}
