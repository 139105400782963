import { useMemo } from 'react'
import { TOTAL } from 'texts/dashboard'
import { Typography } from '@frontend/design_system'
import { InfoTableProps } from './types'
import { Table } from 'ui/molecules/Table'
import styles from './styles.module.scss'

export const InfoTable = ({
  detailsHeadItems,
  bodyItems,
  footerItems,
}: InfoTableProps) => {
  const formattedHeadItems = detailsHeadItems.map((el) => ({
    value: <Typography name="Subtitle3">{el}</Typography>,
  }))

  const renderTotal = () => (
    <div key="total" className={styles['info-table-footer-total']}>
      <Typography name="Subtitle3">{TOTAL}</Typography>
    </div>
  )

  const formattedFooterItems = useMemo(() => {
    if (!footerItems || footerItems.length === 0) {
      return [renderTotal()]
    }

    const items = footerItems.map((item, index) => (
      <div key={index} className={styles['info-table-footer-item']}>
        <Typography name="Subtitle3">{item}</Typography>
      </div>
    ))

    return [renderTotal(), ...items]
  }, [footerItems])

  return (
    <div className={styles['info-table']}>
      <div className={styles.table}>
        <Table
          headItems={formattedHeadItems}
          bodyItems={bodyItems}
          footerItems={formattedFooterItems}
          isStickyHeader
        />
      </div>
    </div>
  )
}
