import { LazyImage } from '@frontend/design_system'
import warning from 'assets/icons/red600/red600_warning_20x20.webp'
import clock from 'assets/icons/blue700/blue700_clock_20x20.webp'
import { APPROVE } from 'texts/approval'
import { useAppSelector } from 'redux/hooks'
import { checkedStatusesSelector } from 'redux/profile/selectors'
import { Button } from 'ui/atoms/Button'
import { ApprovedModal } from './components/ApprovedModal'
import { useState } from 'react'
import { isReadyToApproveSelector } from 'redux/login/selectors'

interface IApproveBtnProps {
  companyId: string
}

const MAX_TABS = 3

export const ApproveBtn = ({ companyId }: IApproveBtnProps) => {
  const isReadyToApprove = useAppSelector(isReadyToApproveSelector)
  const checkedStatuses = useAppSelector(checkedStatusesSelector(companyId))
  const [isVisible, setIsVisible] = useState(false)

  if (!isReadyToApprove) {
    return null
  }

  const onToggle = () => setIsVisible((prev) => !prev)

  const sectionStatusValues = Object.values(checkedStatuses)
  const isApproveAvailable =
    sectionStatusValues.length === MAX_TABS &&
    sectionStatusValues.every((status) => status)

  return (
    <div>
      <Button
        variant="link"
        label={APPROVE}
        size="large"
        typographyName="Button2"
        iconPosition="right"
        disabled={!isApproveAvailable}
        icon={
          isApproveAvailable ? (
            <LazyImage key={clock} src={clock} minWidth={20} height={20} />
          ) : (
            <LazyImage key={warning} src={warning} minWidth={20} height={20} />
          )
        }
        onClick={onToggle}
      />
      {isVisible && <ApprovedModal onClose={onToggle} companyId={companyId} />}
    </div>
  )
}
