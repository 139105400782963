import { LazyImage } from '@frontend/design_system'
import { Popup } from '../Popup'
import { NotificationList } from './components/NotificationList'
import bellIcon from 'assets/icons/whiteMaster/whiteMaster_bell_24x24.webp'
import { MAIN_LAYOUT } from 'texts/mainLayout'
import styles from './styles.module.scss'
import { useEffect, useState } from 'react'
import { notificationsApi } from 'api'
import { useApiRequest } from 'hooks/useApiRequest'

const INTERVAL_TIME = 30000

export const Notifications = () => {
  const [isIndicator, setIsIndicator] = useState(false)
  const [refetchCount, setRefetchCount] = useState(0)

  const { apiRequest: getNotificationCount } = useApiRequest(() =>
    notificationsApi.getNotificationCount()
  )

  const forceUpdateCount = () => setRefetchCount((prev) => prev + 1)

  useEffect(() => {
    const init = async () => {
      const notificationCountResponse = await getNotificationCount()

      if (notificationCountResponse) {
        setIsIndicator(!!notificationCountResponse.data.notificationsCount)
      }
    }

    init()
    const interval = setInterval(init, INTERVAL_TIME)

    return () => {
      clearInterval(interval)
    }
  }, [refetchCount])

  return (
    <Popup
      icon={<LazyImage src={bellIcon} width={24} height={24} />}
      title={MAIN_LAYOUT.HEADER.NOTIFICATIONS}
      indicator={isIndicator ? <div className={styles.indicator} /> : undefined}
    >
      {(onToggle) => (
        <NotificationList
          onToggle={onToggle}
          forceUpdateCount={forceUpdateCount}
        />
      )}
    </Popup>
  )
}
