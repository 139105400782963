import { Typography, LazyImage, Checkbox } from '@frontend/design_system'
import { NavigationItemProps } from './types'
import { joinClasses } from 'utils/joinClasses'
import warning from 'assets/icons/red600/red600_warning_20x20.webp'
import styles from './styles.module.scss'
import { useAppSelector } from 'redux/hooks'
import { useDispatch } from 'react-redux'
import { setCheckedStatuses } from 'redux/profile/slice'
import { checkedStatusesSelector } from 'redux/profile/selectors'

export const NavigationItem = ({
  id,
  label,
  onClick,
  isActive,
  isDisabled,
  withCheckbox,
}: NavigationItemProps) => {
  const dispatch = useDispatch()
  const checkedStatuses = useAppSelector(checkedStatusesSelector(id))
  const checkedStatus = checkedStatuses[label]

  const getColor = () => {
    if (isDisabled) {
      return 'blueSecondary'
    } else if (isActive) {
      return 'blackMaster'
    }

    return 'blue700'
  }

  const toggleStatus = (value: boolean, e: React.MouseEvent) => {
    e.stopPropagation()
    dispatch(setCheckedStatuses({ id, [label]: value }))
  }

  const getIcon = () => {
    if (withCheckbox) {
      return isActive || checkedStatus ? (
        <Checkbox
          onChange={toggleStatus}
          variant="bcrm"
          borderVariant="grey"
          checked={checkedStatus}
          size="newFontsSecondary"
        />
      ) : (
        <LazyImage src={warning} minWidth={20} height={20} />
      )
    }

    return null
  }

  return (
    <Typography
      Tag="div"
      name="body2WBold"
      onClick={isDisabled ? undefined : onClick}
      color={getColor()}
      className={joinClasses(
        styles.item,
        [styles.active, isActive],
        [styles.disabled, isDisabled]
      )}
    >
      {label}
      {getIcon()}
    </Typography>
  )
}
