import React, { useRef } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { Skeleton } from '@frontend/design_system'
import { SelectList } from 'ui/atoms/SelectList'
import { DropdownProps } from './types'
import styles from './styles.module.scss'

export const Dropdown = ({
  items,
  selectedValue,
  onChange,
  noSpaceForError,
  search,
  loading,
  notFoundTitle,
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={dropdownRef}
      className={joinClasses(styles['select-dropdown'], [
        'without-error',
        noSpaceForError,
      ])}
    >
      {loading ? (
        <div className={styles.skeleton}>
          <Skeleton.Spinner size={36} />
        </div>
      ) : (
        <SelectList
          items={items}
          selectedValue={selectedValue}
          onChange={onChange}
          containerRef={dropdownRef}
          search={search}
          notFoundTitle={notFoundTitle}
        />
      )}
    </div>
  )
}
