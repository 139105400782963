import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const CompassIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} color={color} viewBox="0 0 20 20">
    <path
      d="M10.0002 18.3334C5.39779 18.3334 1.66683 14.6024 1.66683 10C1.66683 5.39765 5.39779 1.66669 10.0002 1.66669C14.6025 1.66669 18.3335 5.39765 18.3335 10C18.3335 14.6024 14.6025 18.3334 10.0002 18.3334Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.46654 6.46667L8.2332 11.7667L13.5332 13.5333L11.7665 8.23334L6.46654 6.46667Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      fill="none"
      strokeWidth="1.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <clipPath id="clip0_682_3692">
      <rect
        width="20"
        height="20"
        fill="white"
        transform="matrix(-1 0 0 1 20 0)"
      />
    </clipPath>
  </SVGIcon>
)
