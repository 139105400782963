import { FleetApiTypes, referencesApi, ReferencesApiTypes } from 'api'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import {
  PRICE_LIST_DETAILS,
  PRICE_LIST_DETAILS_FORM_VALUES,
} from 'texts/priceListDetails'
import {
  OriginButton,
  Typography,
  Multiselect,
  Form,
  FormItem,
} from '@frontend/design_system'
import { ContentWrapper } from 'ui/atoms/ContentWrapper'
import { Input } from 'ui/atoms/Input'
import { Select } from 'ui/atoms/Select'
import arrowRight from 'assets/img/arrowRight.webp'
import { FormValues, RateDetailsFormProps } from './types'
import { APPLY_BY, initialFormValues } from './constants'
import { Textarea } from 'ui/atoms/Textarea'
import { CANCEL } from 'texts/uiTexts'
import { Ranges } from './components/Ranges'
import { useSelectValues } from 'hooks/useSelectValues'
import { useCompanyInfo } from 'ui/hooks/useCompanyInfo'
import { isOperatorSelector } from 'redux/login/selectors'
import { useApiRequest } from 'hooks/useApiRequest'
import { MultiselectItem } from 'ui/atoms/Multiselect/types'
import {
  dailyRangeOverlapValidation,
  seasonRangeOverlapValidation,
  seasonRangeDateValidation,
  seasonRangeEmptyDateValidation,
  dailyRangeGapsValidation,
  dailyRangeEmptyValidation,
  seasonRangeDifferentYearValidation,
  dailyRangeFirstRangeValidation,
} from './utils'
import { allSymbolsAndEnglishLettersRegex } from 'constants/regex'
import styles from './styles.module.scss'
import { FormChildren, FormItemChildren } from 'types/form'
import { COR_ITEMS } from 'constants/countryOfResidence'

const COR_RULES = [
  {
    type: 'required',
    message: PRICE_LIST_DETAILS_FORM_VALUES.COR.REQUIRED_MESSAGE,
  },
]

export const PriceListDetailsForm = ({
  handleSubmit,
  initialValues = initialFormValues,
  isLoading,
  readOnly,
  submitButtonLabel,
  navigateToRates,
}: RateDetailsFormProps) => {
  const navigate = useNavigate()
  const [locations, setLocations] = useState<
    ReferencesApiTypes.LocationsResponse['items']
  >([])
  const isOperator = useAppSelector(isOperatorSelector)

  const { companyId } = useCompanyInfo(isOperator)
  const { apiRequest, loading } = useApiRequest(() =>
    referencesApi.getAllCompanyHirePoints(String(companyId))
  )

  const isEditForm = !!navigateToRates

  const getLocations = (currentLocations: MultiselectItem[]) =>
    currentLocations
      ?.map((currentLocation: MultiselectItem) =>
        locations.find(
          (location) => Number(location.id) === Number(currentLocation.label)
        )
      )
      .map((location) => ({ label: location?.name, value: location?.id }))

  const formValues = useMemo(
    () => ({
      ...initialValues,
      hirePoints: getLocations(initialValues.hirePoints || []),
    }),
    [initialValues, loading]
  )

  useEffect(() => {
    if (companyId) {
      const fetchLocations = async () => {
        const response = await apiRequest()
        if (response) {
          setLocations(response.data.items)
        }
      }
      fetchLocations()
    }
  }, [companyId])

  const locationsSelectValues = useSelectValues(locations)

  const onSubmit = (data: Partial<FormValues>, validate: boolean) => {
    if (validate) {
      handleSubmit(data)
    }
  }

  const rules = useMemo(
    () => ({
      hirePoints: [
        {
          type: 'required',
          message: PRICE_LIST_DETAILS_FORM_VALUES.HIRE_POINTS.REQUIRED_MESSAGE,
        },
      ],
      year: [
        {
          type: 'required',
          message: PRICE_LIST_DETAILS_FORM_VALUES.YEAR.REQUIRED_MESSAGE,
        },
      ],
      seasonRanges: [
        {
          type: 'required',
          message:
            PRICE_LIST_DETAILS_FORM_VALUES.SEASON_RANGES.REQUIRED_MESSAGE,
        },
        {
          type: 'custom',
          value: seasonRangeDateValidation,
        },
        {
          type: 'custom',
          value: seasonRangeEmptyDateValidation,
        },
        {
          type: 'custom',
          value: seasonRangeOverlapValidation,
        },
        {
          type: 'custom',
          value: seasonRangeDifferentYearValidation,
        },
      ],
      dailyRanges: [
        {
          type: 'required',
          message: PRICE_LIST_DETAILS_FORM_VALUES.DAILY_RANGES.REQUIRED_MESSAGE,
        },
        {
          type: 'custom',
          value: dailyRangeEmptyValidation,
        },
        {
          type: 'custom',
          value: dailyRangeOverlapValidation,
        },
        {
          type: 'custom',
          value: dailyRangeGapsValidation,
        },
        {
          type: 'custom',
          value: dailyRangeFirstRangeValidation,
        },
      ],
      comment: [
        {
          type: 'pattern',
          value: allSymbolsAndEnglishLettersRegex,
          message: PRICE_LIST_DETAILS_FORM_VALUES.COMMENT.VALIDATION_MESSAGE,
        },
      ],
    }),
    []
  )

  return !loading ? (
    <Form onSubmit={onSubmit} initValues={formValues} className={styles.form}>
      {({ isDirty }: FormChildren) => (
        <>
          <ContentWrapper
            className={styles.form}
            title={PRICE_LIST_DETAILS.TITLE}
          >
            <>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={PRICE_LIST_DETAILS_FORM_VALUES.HIRE_POINTS.ID}
                  className={styles['form-item']}
                  rules={rules.hirePoints}
                >
                  {({ value, error, onChange }: FormItemChildren) => (
                    <Multiselect
                      error={error}
                      onChange={onChange}
                      items={locationsSelectValues}
                      readonly={readOnly}
                      placeholder={
                        PRICE_LIST_DETAILS_FORM_VALUES.HIRE_POINTS.PLACEHOLDER
                      }
                      label={PRICE_LIST_DETAILS_FORM_VALUES.HIRE_POINTS.LABEL}
                      value={value}
                      isRequired
                      unitName={{
                        plural: PRICE_LIST_DETAILS.SEARCH_VALUE,
                      }}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={PRICE_LIST_DETAILS_FORM_VALUES.YEAR.ID}
                  className={styles['form-item']}
                  rules={rules.year}
                >
                  {({ value, error, onChange }: FormItemChildren) => (
                    <Input
                      value={value}
                      size="large"
                      isRequired
                      placeholder={
                        PRICE_LIST_DETAILS_FORM_VALUES.YEAR.PLACEHOLDER
                      }
                      error={error}
                      spaceForError="auto"
                      disabled={readOnly}
                      onChange={onChange}
                      label={PRICE_LIST_DETAILS_FORM_VALUES.YEAR.LABEL}
                    />
                  )}
                </FormItem>
              </div>
              <div className={styles['form-items-row']}>
                <FormItem
                  id={PRICE_LIST_DETAILS_FORM_VALUES.COR.ID}
                  rules={COR_RULES}
                  className={styles['form-item']}
                >
                  {({ value, error, onChange }: FormItemChildren) => (
                    <Multiselect
                      value={value}
                      placeholder={
                        PRICE_LIST_DETAILS_FORM_VALUES.COR.PLACEHOLDER
                      }
                      error={error}
                      items={COR_ITEMS}
                      onChange={onChange}
                      label={PRICE_LIST_DETAILS_FORM_VALUES.COR.LABEL}
                      isRequired
                      disabled={readOnly}
                    />
                  )}
                </FormItem>
                <div className={styles['form-item']} />
              </div>
            </>
          </ContentWrapper>
          <ContentWrapper
            className={styles.form}
            title={PRICE_LIST_DETAILS.SEASON_RANGE}
          >
            <FormItem
              id={PRICE_LIST_DETAILS_FORM_VALUES.SEASON_RANGES.ID}
              className={styles['form-item']}
              rules={rules.seasonRanges}
            >
              {({ value, onChange, error }: FormItemChildren) => (
                <Ranges<FleetApiTypes.SeasonRange>
                  ranges={value}
                  readOnly={readOnly}
                  onRangesChange={onChange}
                  type="season"
                  error={error}
                />
              )}
            </FormItem>
          </ContentWrapper>
          <ContentWrapper
            className={styles.form}
            title={PRICE_LIST_DETAILS.DAILY_RANGE}
          >
            <FormItem
              id={PRICE_LIST_DETAILS_FORM_VALUES.DAILY_RANGES.ID}
              className={styles['form-item']}
              rules={rules.dailyRanges}
            >
              {({ value, onChange, error }: FormItemChildren) => (
                <Ranges<FleetApiTypes.DailyRange>
                  ranges={value}
                  readOnly={readOnly}
                  onRangesChange={onChange}
                  type="daily"
                  error={error}
                />
              )}
            </FormItem>
          </ContentWrapper>
          <ContentWrapper className={styles.form}>
            <>
              <FormItem
                id={PRICE_LIST_DETAILS_FORM_VALUES.PRICE_LIST_TYPE.ID}
                className={styles['car-class-select']}
              >
                {({ value, onChange }: FormItemChildren) => (
                  <Select
                    items={Object.entries(APPLY_BY).map(([key, value]) => ({
                      label: value,
                      value: key,
                    }))}
                    disabled={readOnly}
                    selectedValue={value}
                    onChange={onChange}
                    label={PRICE_LIST_DETAILS_FORM_VALUES.PRICE_LIST_TYPE.LABEL}
                    size="large"
                    tooltip={
                      isEditForm && (
                        <Typography name="caption1WMedium">
                          {
                            PRICE_LIST_DETAILS_FORM_VALUES.PRICE_LIST_TYPE
                              .TOOLTIP
                          }
                        </Typography>
                      )
                    }
                  />
                )}
              </FormItem>
              <FormItem
                id={PRICE_LIST_DETAILS_FORM_VALUES.COMMENT.ID}
                rules={rules.comment}
              >
                {({ value, onChange, error }: FormItemChildren) => (
                  <Textarea
                    value={value}
                    disabled={readOnly}
                    onChange={onChange}
                    label={PRICE_LIST_DETAILS_FORM_VALUES.COMMENT.LABEL}
                    placeholder={
                      PRICE_LIST_DETAILS_FORM_VALUES.COMMENT.PLACEHOLDER
                    }
                    error={error}
                  />
                )}
              </FormItem>
            </>
          </ContentWrapper>
          <div className={styles.buttons}>
            <div className={styles['button-wrapper']}>
              <OriginButton
                variant="secondary-border"
                size="large"
                onClick={() => navigate(-1)}
                label={CANCEL}
              />
            </div>
            <div className={styles['button-wrapper']}>
              <OriginButton
                htmlType="submit"
                size="large"
                label={submitButtonLabel}
                loading={isLoading}
                disabled={!isDirty}
              />
            </div>
            {navigateToRates && (
              <div className={styles['button-wrapper']}>
                <OriginButton
                  variant="link"
                  size="large"
                  onClick={navigateToRates}
                  label={PRICE_LIST_DETAILS.GO_TO_RATES}
                  typographyName="Button1"
                  iconRight={<img src={arrowRight} width={16} height={16} />}
                />
              </div>
            )}
          </div>
        </>
      )}
    </Form>
  ) : null
}
