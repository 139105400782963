import { MENU_NAMES } from 'texts/menuNames'
import { Container } from 'ui/molecules/Container'
import { DaysPicker } from '@frontend/design_system'
import { ApplicationList } from '../ApplicationList'
import { useCallback, useMemo } from 'react'
import { setSelectedApplicationsRange } from 'redux/applications/slice'
import { format } from 'date-fns'
import { STOP_SALES_DATE_FORMAT } from 'constants/date'
import { useDispatch, useSelector } from 'react-redux'
import { selectedRangeSelector } from 'redux/applications/selectors'
import styles from './styles.module.scss'

export const ApplicationsArchive = () => {
  const selectedRange = useSelector(selectedRangeSelector)
  const dispatch = useDispatch()

  const handleSelectedRange = useCallback(
    (newValues: { from: Date; to: Date }) => {
      dispatch(
        setSelectedApplicationsRange({
          from: format(newValues.from, STOP_SALES_DATE_FORMAT),
          to: newValues.to ? format(newValues.to, STOP_SALES_DATE_FORMAT) : '',
        })
      )
    },
    []
  )

  const daysPickerRange = useMemo(
    () => ({
      from: selectedRange.from ? new Date(selectedRange.from) : null,
      to: selectedRange.to ? new Date(selectedRange.to) : null,
    }),
    [selectedRange]
  )

  return (
    <Container
      title={MENU_NAMES.APPLICATION_ARCHIVE}
      withCompanyName={false}
      rightBlock={
        <div className={styles['date-picker']}>
          <DaysPicker
            range={daysPickerRange}
            initialFrom={selectedRange.from}
            initialTo={selectedRange.to}
            size="small"
            handleRangeSelect={handleSelectedRange}
            variant="secondary"
          />
        </div>
      }
    >
      <ApplicationList selectedRange={selectedRange} isArchive />
    </Container>
  )
}
