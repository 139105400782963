import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { joinClasses } from 'utils/joinClasses'
import { SvgIconProps } from './types'
import styles from './styles.module.scss'

export const SVGIcon = ({
  children,
  size = 'small',
  color = 'grey400',
  className,
  onClick,
  viewBox,
  rotate,
  disabled,
}: SvgIconProps) => {
  const iconColor = TYPOGRAPHY_COLORS[color]
  const handleClick = (e: React.MouseEvent) => {
    if (onClick && !disabled) {
      onClick(e)
    }
  }

  return (
    <svg
      color={iconColor}
      viewBox={viewBox}
      className={joinClasses(
        styles['svg-icon'],
        className || '',
        styles[`svg-icon-${size}`],
        styles[`svg-icon-color-${color}`],
        [styles.clickable, !!onClick],
        [styles.disabled, disabled]
      )}
      style={{ transform: rotate ? `rotate(${rotate}deg)` : undefined }}
      focusable={false}
      xmlns="http://www.w3.org/2000/svg"
      onClick={handleClick}
    >
      {children}
    </svg>
  )
}
