import { ApplicationStatus } from 'api/applications/types'
import { APPLICATION_STATUS } from 'modules/Applications/constants'
import { APPLICATION_RADIO_BUTTON } from 'texts/applications'

export const RADIO_BUTTONS = [
  { label: APPLICATION_RADIO_BUTTON.API, id: 'ApiIntegration' },
  { label: APPLICATION_RADIO_BUTTON.STATIC, id: 'Static' },
]

export const settings: {
  [key in ApplicationStatus]: {
    shouldSelectRender: boolean
    shouldSelectDisable: boolean
    shouldTextareaDisable: boolean
  }
} = {
  WaitingList: {
    shouldSelectRender: true,
    shouldSelectDisable: false,
    shouldTextareaDisable: false,
  },
  WaitingForCall: {
    shouldSelectRender: true,
    shouldSelectDisable: false,
    shouldTextareaDisable: true,
  },
  WaitingForSign: {
    shouldSelectRender: true,
    shouldSelectDisable: false,
    shouldTextareaDisable: true,
  },
  Approve: {
    shouldSelectRender: false,
    shouldSelectDisable: false,
    shouldTextareaDisable: false,
  },
  Reject: {
    shouldSelectRender: false,
    shouldSelectDisable: true,
    shouldTextareaDisable: true,
  },
}

export const REJECT_REASON_VALUES = {
  InsufficientFleetSize: 'Insufficient fleet size',
  LocationIsFull: 'Location is full',
  IncompleteInformation: 'Incomplete or inaccurate information',
  NonCompliance: 'Non-compliance of cars with quality standards',
  LowReputation: 'Low reputation or review score',
  IncompleteDocumentation: 'Incomplete documentation',
  Other: 'Other',
}

export const APPLICATION_STATUS_SELECT_VALUES = {
  [APPLICATION_STATUS.WaitingList]: [
    APPLICATION_STATUS.WaitingForCall,
    APPLICATION_STATUS.Reject,
  ],
  [APPLICATION_STATUS.WaitingForCall]: [
    APPLICATION_STATUS.WaitingForSign,
    APPLICATION_STATUS.Reject,
  ],
  [APPLICATION_STATUS.WaitingForSign]: [
    APPLICATION_STATUS.Approve,
    APPLICATION_STATUS.Reject,
  ],
}
