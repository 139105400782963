import { applicationsApi, ApplicationsApiTypes } from 'api'
import { ApplicationStatus } from 'api/applications/types'
import { useApiRequest } from 'hooks/useApiRequest'

export const useChangeStatusRequest = () => {
  const setApplicationStatusForCallRequest = useApiRequest((id, comment) =>
    applicationsApi.setApplicationStatusForCall(id, comment)
  )
  const setApplicationStatusForSignRequest = useApiRequest((id) =>
    applicationsApi.setApplicationStatusForSign(id)
  )
  const setApplicationStatusApproveRequest = useApiRequest(
    (id, connectionType, companyInfo) =>
      applicationsApi.setApplicationStatusApprove(
        id,
        connectionType,
        companyInfo
      )
  )
  const setApplicationStatusRejectRequest = useApiRequest(
    (id, rejectReasonType, rejectReason) =>
      applicationsApi.setApplicationStatusReject(
        id,
        rejectReasonType,
        rejectReason
      )
  )

  const changeStatusRequest = async (
    data: ApplicationsApiTypes.ApplicationDetailsResponse['applicationInfo'],
    status: ApplicationStatus
  ) => {
    let response
    if (status === 'WaitingForSign') {
      response = await setApplicationStatusForSignRequest.apiRequest(
        data.applicationId
      )
    } else if (status === 'WaitingForCall') {
      response = await setApplicationStatusForCallRequest.apiRequest(
        data.applicationId,
        data.comment
      )
    } else if (status === 'Approve') {
      response = await setApplicationStatusApproveRequest.apiRequest(
        data.applicationId,
        data.connectionType,
        data.companyInfo
      )
    } else {
      response = await setApplicationStatusRejectRequest.apiRequest(
        data.applicationId,
        data.rejectReasonType,
        data.rejectReason
      )
    }
    return response
  }

  return changeStatusRequest
}
