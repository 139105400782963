import { EmptyResponse, ErrorResponse } from 'api/types'
import { request } from '../utils'
import { EnvironmentVariablesService } from 'utils/env'
import {
  IGetNotificationCountResponse,
  IGetNotificationsResponse,
} from './types'
import { PATH } from './constants'

class Api {
  public baseUrl = EnvironmentVariablesService.getEnv('REACT_APP_API_URL') || ''

  getNotifications() {
    return request<IGetNotificationsResponse, ErrorResponse>(
      this.baseUrl,
      PATH.NOTIFICATION_LIST,
      { method: 'GET' }
    )
  }

  getNotificationCount() {
    return request<IGetNotificationCountResponse, ErrorResponse>(
      this.baseUrl,
      PATH.NOTIFICATION_COUNT,
      { method: 'GET' }
    )
  }

  deleteNotification(notificationId: number) {
    return request<EmptyResponse, ErrorResponse>(
      this.baseUrl,
      `${PATH.NOTIFICATION}/${notificationId}`,
      { method: 'DELETE' }
    )
  }
}

export const notificationsApi = new Api()
