import { Input } from 'ui/atoms/Input'
import { APPLICATION_FORM_VALUES } from 'texts/applications'
import styles from './styles.module.scss'
import { Multiselect, FormItem } from '@frontend/design_system'
import { FormItemChildren } from 'types/form'

export const SupplierLocation = () => (
  <>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.COUNTRIES_FLEET.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }: FormItemChildren) => (
          <Multiselect
            value={value}
            placeholder=""
            error={error}
            items={value}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.COUNTRIES_FLEET.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.LOCATIONS_COUNT.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }: FormItemChildren) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            disabled
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.LOCATIONS_COUNT.LABEL}
          />
        )}
      </FormItem>
    </div>
    <div className={styles['form-items-row']}>
      <FormItem
        id={APPLICATION_FORM_VALUES.LOCATION_TYPE.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }: FormItemChildren) => (
          <Multiselect
            value={value}
            placeholder=""
            error={error}
            items={value}
            readonly
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.LOCATION_TYPE.LABEL}
          />
        )}
      </FormItem>
      <FormItem
        id={APPLICATION_FORM_VALUES.VEHICLE_COUNT.ID}
        className={styles['form-item']}
      >
        {({ value, error, onChange }: FormItemChildren) => (
          <Input
            value={value}
            placeholder=""
            size="large"
            error={error}
            disabled
            spaceForError="none"
            onChange={onChange}
            label={APPLICATION_FORM_VALUES.VEHICLE_COUNT.LABEL}
          />
        )}
      </FormItem>
    </div>
  </>
)
