import { useMemo } from 'react'
import { Typography, DaysPicker } from '@frontend/design_system'
import { STOP_SALES_DATE_FORMAT } from 'constants/date'
import { format } from 'date-fns'
import { formatRevenueTotalData } from '../../utils'
import { DIAGRAMS, REVENUE_TABLE_ITEMS } from 'texts/dashboard'
import { CURRENCY_ID, EMPTY_GROUP } from '../../constants'
import { TotalRevenueProps } from './types'
import { InfoTable } from '../InfoTable'
import { Diagram } from '../Diagram'
import styles from './styles.module.scss'

export const TotalRevenue = ({
  dateRange,
  handleDateRange,
  initialDates,
  data,
}: TotalRevenueProps) => {
  const { revenue, categories } = formatRevenueTotalData(data)

  const formattedBodyItems = useMemo(
    () =>
      data?.items.map((item) => ({
        items: [
          item.carType,
          `${item.revenue} ${CURRENCY_ID}`,
          item.reservations,
        ],
      })),
    [data]
  )

  const formattedFooterItem = useMemo(() => {
    if (!data) {
      return { result: [], totalRevenue: 0 }
    }
    return {
      result: [`${data.totalRevenue} ${CURRENCY_ID}`, data.totalReservations],
      totalRevenue: data.totalRevenue,
    }
  }, [data])

  return (
    <div className={styles['car-rental']}>
      <div className={styles['diagram']}>
        <div className={styles['diagram-header']}>
          <Typography name="subtitleWBold">{DIAGRAMS.TOTAL_REVENUE}</Typography>
          <div className={styles['date-picker']}>
            <DaysPicker
              range={dateRange}
              size="small"
              initialFrom={format(
                new Date(initialDates.start),
                STOP_SALES_DATE_FORMAT
              )}
              initialTo={format(
                new Date(initialDates.end),
                STOP_SALES_DATE_FORMAT
              )}
              handleRangeSelect={handleDateRange}
              variant="secondary"
            />
          </div>
        </div>
        <Diagram
          groupCount={EMPTY_GROUP}
          categories={categories}
          series={revenue}
          isDonut
          total={`${formattedFooterItem.totalRevenue}`}
        />
      </div>
      <InfoTable
        bodyItems={formattedBodyItems}
        detailsHeadItems={REVENUE_TABLE_ITEMS}
        footerItems={formattedFooterItem.result}
      />
    </div>
  )
}
