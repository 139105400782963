import { URLS } from 'constants/urls'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import {
  authIsLoadedSelector,
  isOperatorSelector,
  userDataSelector,
} from 'redux/login/selectors'
import { UnauthorizedRouteProps } from './types'

export const UnauthorizedRoute = ({ children }: UnauthorizedRouteProps) => {
  const isLoaded = useSelector(authIsLoadedSelector)
  const userData = useSelector(userDataSelector)
  const isOperator = useSelector(isOperatorSelector)

  if (!isLoaded) {
    return null
  }

  if (isLoaded && userData.displayName) {
    return (
      <Navigate
        to={
          isOperator
            ? URLS.PROFILE + URLS.PROFILE_LIST
            : URLS.PROFILE + URLS.PENDING
        }
        replace
      />
    )
  }

  return <>{children}</>
}
