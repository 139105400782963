import {
  Form,
  FormItem,
  Input,
  Typography,
  Tooltip,
  Select,
  Button,
  LazyImage,
  RadioButton,
} from '@frontend/design_system'
import {
  ADDED_SUCCESS,
  DRIVER_REQUIREMENTS,
  DRIVER_REQUIREMENTS_CONTENT,
  DRIVER_REQUIREMENTS_FORM_VALUES,
  GO_TO_LOCATION,
  UPDATED_SUCCESS,
  YOUNG_DRIVER_SURCHARGE,
  YOUNG_DRIVER_SURCHARGE_TOOLTIP,
} from 'texts/termsAndConditions'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { Block } from '../Block'
import { CorBtn } from '../CorBtn'
import { ActionButtons } from '../ActionButtons'
import { FormChildren, FormItemChildren, SelectValue } from 'types/form'
import { IFormValues } from './types'
import { Row } from '../Row'
import { MAX_AGE_RULES, MIN_AGE_RULES } from 'constants/form'
import { LICENSE_YEARS_RULES, LOCATION_RULES, RADIO_BUTTONS } from './constants'
import { InfoIcon } from 'ui/icons'
import nextRightIcon from 'assets/icons/blue700/blue700_nextRightArrow_16x16.webp'
import styles from './styles.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { URLS } from 'constants/urls'
import { useApiRequest } from 'hooks/useApiRequest'
import { locationsApi, termsApi } from 'api'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { DriverAgeRange, DriverAgeRangeType } from 'api/fleet/types'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { prepareCorForSelect, prepareResponseItems } from 'utils/form'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { useCompanyId } from 'hooks/useCompanyId'

const ALL_LOCATIONS_AMOUNT = 10000
const INIT_DRIVER_AGE_RANGES = {
  items: [],
  rangeType: DriverAgeRangeType.PerRental,
}

export const DriverRequirements = ({ reload, data }: IContentProps) => {
  const { companyId: companyIdParam } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const companyId = useCompanyId()
  const [locations, setLocations] = useState<SelectValue<number>[]>([])
  const [locationId, setLocationId] = useState(0)
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [driverAgeRange, setDriverAgeRanges] = useState<DriverAgeRange>(
    INIT_DRIVER_AGE_RANGES
  )
  const getHirePointsList = useApiRequest(() =>
    locationsApi.getHirePointsList(0, ALL_LOCATIONS_AMOUNT, companyId)
  )
  const getHirePointInfo = useApiRequest((hirePointId) =>
    locationsApi.getHirePointInfo(hirePointId)
  )
  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )

  const initValues = useMemo(() => {
    const initData: IFormValues = {
      cor: COR_ITEMS,
      minDrivedAge: undefined,
      maxDrivedAge: undefined,
      driversLicenceYear: undefined,
      locationContractId: undefined,
    }

    if (data) {
      initData.cor = prepareCorForSelect(data.countriesOfResidence)
      initData.minDrivedAge = data.minDrivedAge
      initData.maxDrivedAge = data.maxDrivedAge
      initData.driversLicenceYear = data.driversLicenceYear
      initData.locationContractId = data.locationContractId
    }

    return initData
  }, [data])
  const onSubmit = async (values: IFormValues, validate: boolean) => {
    if (validate) {
      const body = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.DriverRequirements,
        companyId,
        countriesOfResidence: prepareResponseItems(values.cor),
        minDrivedAge: values.minDrivedAge,
        maxDrivedAge: values.maxDrivedAge,
        driversLicenceYear: values.driversLicenceYear,
        locationContractId: values.locationContractId,
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  const goToLocation = () => {
    navigate(
      `${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}${
        companyIdParam ? `/${companyIdParam}` : ''
      }`
    )
  }

  useEffect(() => {
    const getLocations = async () => {
      const response = await getHirePointsList.apiRequest()

      if (response) {
        setLocations(
          response.data.hirePoints.pageItems.map(({ id, locationName }) => ({
            value: id,
            label: locationName,
          }))
        )
      }
    }
    getLocations()
  }, [])

  useEffect(() => {
    if (locationId) {
      const getDriverRangeAges = async () => {
        setDriverAgeRanges(INIT_DRIVER_AGE_RANGES)
        const response = await getHirePointInfo.apiRequest(locationId)
        const driverAgeRangeResponse =
          response?.data.hirePointInfo.driverAgeRange

        if (driverAgeRangeResponse) {
          setDriverAgeRanges(driverAgeRangeResponse)
        }
      }

      getDriverRangeAges()
    }
  }, [locationId])

  useDidUpdate(() => {
    if (data?.locationContractId) {
      setLocationId(data.locationContractId)
    }
  }, [data?.locationContractId])

  return (
    <Block title={DRIVER_REQUIREMENTS}>
      <Form initValues={initValues} onSubmit={onSubmit} key={data?.id}>
        {({ isDirty }: FormChildren) => (
          <>
            <CorBtn />
            <Row>
              <FormItem
                id={DRIVER_REQUIREMENTS_FORM_VALUES.MIN_AGE.ID}
                rules={MIN_AGE_RULES}
              >
                {({ value, error, onChange }: FormItemChildren) => (
                  <Input
                    value={value}
                    size="large"
                    error={error}
                    spaceForError="auto"
                    onChange={onChange}
                    label={DRIVER_REQUIREMENTS_FORM_VALUES.MIN_AGE.LABEL}
                    isRequired
                    type="integer"
                    placeholder={
                      DRIVER_REQUIREMENTS_FORM_VALUES.MIN_AGE.PLACEHOLDER
                    }
                  />
                )}
              </FormItem>
              <FormItem
                id={DRIVER_REQUIREMENTS_FORM_VALUES.MAX_AGE.ID}
                rules={MAX_AGE_RULES}
              >
                {({ value, error, onChange }: FormItemChildren) => (
                  <Input
                    value={value}
                    size="large"
                    error={error}
                    spaceForError="auto"
                    onChange={onChange}
                    label={DRIVER_REQUIREMENTS_FORM_VALUES.MAX_AGE.LABEL}
                    isRequired
                    type="integer"
                    placeholder={
                      DRIVER_REQUIREMENTS_FORM_VALUES.MAX_AGE.PLACEHOLDER
                    }
                  />
                )}
              </FormItem>
            </Row>
            <FormItem
              id={DRIVER_REQUIREMENTS_FORM_VALUES.LICENSE_YEARS.ID}
              rules={LICENSE_YEARS_RULES}
            >
              {({ value, error, onChange }: FormItemChildren) => (
                <Input
                  value={value}
                  size="large"
                  error={error}
                  spaceForError="auto"
                  onChange={onChange}
                  label={DRIVER_REQUIREMENTS_FORM_VALUES.LICENSE_YEARS.LABEL}
                  isRequired
                  type="integer"
                  placeholder={
                    DRIVER_REQUIREMENTS_FORM_VALUES.LICENSE_YEARS.PLACEHOLDER
                  }
                />
              )}
            </FormItem>
            <Typography name="body1WBold" Tag="h4" className={styles.subtitle}>
              {YOUNG_DRIVER_SURCHARGE}
              <Tooltip
                arrowPosition="right"
                popupContent={
                  <Typography
                    name="caption1WMedium"
                    className={styles.tooltip}
                    Tag="div"
                  >
                    {YOUNG_DRIVER_SURCHARGE_TOOLTIP}
                  </Typography>
                }
                placement="bottom"
              >
                <InfoIcon color="blue700" size="small" />
              </Tooltip>
            </Typography>
            <Row>
              <FormItem
                id={DRIVER_REQUIREMENTS_FORM_VALUES.LOCATION.ID}
                rules={LOCATION_RULES}
              >
                {({ onChange, value, error }: FormItemChildren) => (
                  <Select
                    selectedValue={value}
                    items={locations}
                    placeholder={
                      DRIVER_REQUIREMENTS_FORM_VALUES.LOCATION.PLACEHOLDER
                    }
                    size="large"
                    onChange={(value: number) => {
                      onChange(value)
                      setLocationId(value)
                    }}
                    label={DRIVER_REQUIREMENTS_FORM_VALUES.LOCATION.LABEL}
                    isRequired
                    error={error}
                    search={{
                      placeholder:
                        DRIVER_REQUIREMENTS_FORM_VALUES.LOCATION
                          .SEARCH_PLACEHOLDER,
                    }}
                  />
                )}
              </FormItem>
              <div className={styles['go-to-location']}>
                <Button
                  typographyName="body1WMedium"
                  label={GO_TO_LOCATION}
                  variant="link"
                  iconRight={
                    <LazyImage src={nextRightIcon} width={16} height={16} />
                  }
                  onClick={goToLocation}
                />
              </div>
            </Row>
            {driverAgeRange.items.length > 0 && (
              <>
                {driverAgeRange.items.map(
                  ({ ageStart, ageEnd, price, maxPrice }, index) => (
                    <Row key={index} isOffset>
                      <Input
                        value={ageStart}
                        size="large"
                        spaceForError="none"
                        label={DRIVER_REQUIREMENTS_FORM_VALUES.FROM.LABEL}
                        readonly
                        isDisabled
                      />
                      <Input
                        value={ageEnd}
                        size="large"
                        spaceForError="none"
                        label={DRIVER_REQUIREMENTS_FORM_VALUES.TO.LABEL}
                        readonly
                        isDisabled
                      />
                      <Input
                        value={price}
                        size="large"
                        spaceForError="none"
                        label={DRIVER_REQUIREMENTS_FORM_VALUES.FEE.LABEL}
                        readonly
                        isDisabled
                      />
                      <Input
                        value={maxPrice}
                        size="large"
                        spaceForError="none"
                        label={DRIVER_REQUIREMENTS_FORM_VALUES.MAX_PRICE.LABEL}
                        readonly
                        isDisabled
                      />
                    </Row>
                  )
                )}
                <Row isOffset>
                  {RADIO_BUTTONS.map(({ label, id }) => (
                    <RadioButton
                      labelTypographyName="body2WMedium"
                      key={id}
                      id={id}
                      label={label}
                      checked={driverAgeRange.rangeType === id}
                      readonly
                    />
                  ))}
                </Row>
              </>
            )}
            <Typography name="body1WBold" Tag="h4">
              {DRIVER_REQUIREMENTS_CONTENT.TITLE}
            </Typography>
            {DRIVER_REQUIREMENTS_CONTENT.LIST.map(
              ({ TITLE, DESCRIPTION }, index) => (
                <Fragment key={index}>
                  <Typography
                    name="body1WBold"
                    Tag="h5"
                    className={styles['requirements-title']}
                  >
                    {TITLE}
                  </Typography>
                  <Typography name="body2WMedium" Tag="p">
                    {DESCRIPTION}
                  </Typography>
                </Fragment>
              )
            )}
            <ActionButtons
              isOffset
              disabled={data && !isDirty}
              onCancel={data && reload}
              loading={
                newTermConditionRequest.loading ||
                updateTermConditionRequest.loading
              }
            />
          </>
        )}
      </Form>
    </Block>
  )
}
