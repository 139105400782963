export const LIST_OF_LOCATIONS = {
  AUTOCOMPLETE_PLACEHOLDER: 'Find by location',
  SEARCH_DROPDOWN_EMPTY_VALUE: 'Location was not found...',
  BUTTON_LABEL: 'Add location',
  TOOLTIP_CONTENT:
    'Here you can add information about the locations where you provide car rental services.',
}

export const AVAILABLE_VEHICLES_MODAL = {
  TITLE: 'List of cars',
  ALL: 'All',
  SELECTED: 'Selected: ',
  CARS: ' cars',
  BUTTON_LABEL: 'Save',
}

export const AVAILABLE_EQUIPMENTS_MODAL = {
  TITLE: 'List of equipment',
  ALL: 'All',
  SELECTED: 'Selected: ',
  EQUIPMENTS: ' equipments',
  BUTTON_LABEL: 'Save',
}

export const DELETE_LOCATION_MODAL = {
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  DEACTIVATE: 'Deactivate',
  ACTIVATE: 'Activate',
  CONFIRM: 'Confirm',
  CONFIRM_QUESTION: (
    locationName: string
  ) => `Are you sure that you want to delete ${locationName}
   location?`,
}
