import { URLS } from 'constants/urls'

export const ALL = 'All'
const PROFILE_DETAILS = 'Profile details'
const LIST_OF_LOCATIONS = 'List of locations'
const LOCATION_DETAILS = 'Location details'
const CARS = 'Cars'
const CAR_DETAILS = 'Car details'
const EQUIPMENT = 'Equipment'
const EQUIPMENT_DETAILS = 'Equipment details'
const PRICE_LISTS = 'Price lists'
const PRICE_LIST_DETAILS = 'Price list details'
const STOP_SALES = 'Stop sales'
const STOP_SALES_DETAILS = 'Stop sales details'
const TERMS_AND_CONDITIONS = 'Terms & Conditions'

export const TIPS_TOPICS = [
  ALL,
  PROFILE_DETAILS,
  LIST_OF_LOCATIONS,
  CARS,
  EQUIPMENT,
  PRICE_LISTS,
  STOP_SALES,
  TERMS_AND_CONDITIONS,
]

export const MOCK_TIPS = [
  {
    topic: PROFILE_DETAILS,
    group: PROFILE_DETAILS,
    buttonLabel: 'Try it',
    linkForButton: `${URLS.PROFILE}${URLS.DETAILS}`,
    title: 'How can I add info to my profile?',
    startDescription: `<ul>
		<li>Phone number</li>
		<li>Website</li>
		<li>City</li>
		<li>State/Region</li>
		<li>Street</li>
		<li>Building</li>
		<li>Postal code/Postcode/ZIP-code</li>
		</ul>`,
    subTitle: 'You can enter and edit the following information:',
    endDescription: `Other information in your profile is not subject to editing, as it was added in accordance with the contract terms. To edit, please contact our support department<br />
	<a href="mailto: supplierportal@bookinggroup.com">supplierportal@bookinggroup.com.</a>`,
  },
  {
    topic: LIST_OF_LOCATIONS,
    group: LIST_OF_LOCATIONS,
    startDescription: `<p>Here you can see all your added locations, and you can also add a new one.</p>
	For both actions, please follow this <a href="${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}">link</a>`,
    hiddenForUnapprovedUser: true,
  },
  {
    topic: LOCATION_DETAILS,
    group: LIST_OF_LOCATIONS,
    title: 'How can I add a new location?',
    startDescription: `<p>If you would like to add a new location, please follow this <a href="${URLS.LOCATIONS}${URLS.LIST_OF_LOCATIONS}${URLS.ADD}">link</a>`,
  },
  {
    topic: CARS,
    group: CARS,
    startDescription:
      'Here you can list all the cars you have in your fleet. You can also connect a car with a specific location.',
  },
  {
    topic: CAR_DETAILS,
    group: CARS,
    title: 'How can I add a car to my fleet?',
    buttonLabel: 'Try it',
    linkForButton: `${URLS.FLEET}${URLS.VEHICLES}${URLS.ADD}`,
  },
  {
    topic: EQUIPMENT,
    group: EQUIPMENT,
    startDescription:
      'Here you can list all the equipment available for rent. You can also connect equipment with a specific location.',
  },
  {
    topic: EQUIPMENT_DETAILS,
    group: EQUIPMENT,
    title: 'How can I add equipment?',
    buttonLabel: 'Try it',
    linkForButton: `${URLS.FLEET}${URLS.EQUIPMENTS}${URLS.ADD}`,
    hiddenForUnapprovedUser: true,
  },
  {
    topic: PRICE_LISTS,
    group: PRICE_LISTS,
    startDescription:
      'Fill in the table with prices for different combinations of rental period and vehicle type, or upload a filled-in template.',
  },
  {
    topic: PRICE_LIST_DETAILS,
    group: PRICE_LISTS,
    title: 'How can I set prices and add a new price list?',
    buttonLabel: 'Try it',
    linkForButton: `${URLS.FLEET}${URLS.PRICE_LIST}${URLS.ADD}`,
    hiddenForUnapprovedUser: true,
  },
  {
    topic: STOP_SALES,
    group: STOP_SALES,
    startDescription:
      'Here you can add stop sales so that cars are no longer available for booking.',
  },
  {
    topic: STOP_SALES_DETAILS,
    group: STOP_SALES,
    title: 'How can I add stop sales?',
    buttonLabel: 'Try it',
    linkForButton: `${URLS.FLEET}${URLS.STOP_SALES}${URLS.ADD}`,
    hiddenForUnapprovedUser: true,
  },
  {
    topic: TERMS_AND_CONDITIONS,
    group: TERMS_AND_CONDITIONS,
    startDescription:
      'Terms & Conditions contain important information about renting a car from your company. The information is read-only and cannot be edited for now.',
  },
]
