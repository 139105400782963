export const PATH = {
  LOGIN: 'auth/credentials',
  LOGOUT: 'auth/logout',
  REGISTRATION: 'registration/register',
  CHECK_REGISTRATION_CODE: 'registration/init',
  SEND_VERIFICATION_CODE_REQUEST: 'registration/sendVerificationCode',
  SEND_REGISTRATION_CODE_REQUEST: 'registration/sendRegistrationCode',
  CONFIRM_VERIFICATION_CODE: 'registration/confirmAccount',
  RESET_PASSWORD: 'registration/resetPassword',
  USER_INFO: 'auth/info',
  GET_COMPANY_INFO: 'company',
  VERIFY_EMAIL: 'registration/verifyEmail',
  CONFIRM_REGISTRATION: 'registration/confirmSelfRegisteredAccount',
}

export const ACCOUNT_STATUSES = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
}

export const REGISTRATION_STATUSES = {
  PENDING: 'Pending',
  INCOMPLETE: 'Incomplete',
}
