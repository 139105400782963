import { DASHBOARD_TABS, DASHBOARD_SUB_TABS } from 'texts/dashboard'
import { Typography } from '@frontend/design_system'
import { DiagramTabsProps } from './types'
import { joinClasses } from 'utils/joinClasses'
import styles from './styles.module.scss'

export const DiagramTabs = ({
  tab,
  subTab,
  handleTab,
  handleSubTab,
}: DiagramTabsProps) => (
  <div className={styles.navigation}>
    <div className={styles.tabs}>
      {Object.values(DASHBOARD_TABS).map((item) => (
        <Typography
          key={item}
          Tag="div"
          name="body2WBold"
          onClick={() => handleTab(item)}
          color={tab !== item && 'blue700'}
          className={joinClasses(styles.item, [styles.active, tab === item])}
        >
          {item}
        </Typography>
      ))}
    </div>

    {tab === DASHBOARD_TABS.FINANCIAL_INFO && (
      <div className={styles['sub-tabs']}>
        {Object.values(DASHBOARD_SUB_TABS).map((item) => (
          <Typography
            key={item}
            Tag="div"
            name="Button2"
            onClick={() => handleSubTab(item)}
            color={subTab !== item && 'blue700'}
            className={joinClasses(styles['sub-item'], [
              styles.active,
              tab === item,
            ])}
          >
            {item}
          </Typography>
        ))}{' '}
      </div>
    )}
  </div>
)
