import { Table } from 'ui/molecules/Table'
import { Pagination } from '@frontend/design_system'
import { useEffect, useMemo, useState } from 'react'
import {
  DEFAULT_INITIAL_PAGE,
  DEFAULT_INITIAL_PAGE_SIZE,
} from 'constants/pagination'
import { PAGINATION_DROPDOWN_LABEL } from 'texts/uiTexts'
import { HEAD_ITEMS } from 'texts/applications'
import { useNavigate } from 'react-router-dom'
import { ApplicationListProps } from './types'
import styles from './styles.module.scss'
import { TableHeadProps } from 'ui/molecules/Table/TableHead/types'
import { useApiRequest } from 'hooks/useApiRequest'
import { applicationsApi, ApplicationsApiTypes } from 'api'
import { useDispatch, useSelector } from 'react-redux'
import { Filter } from 'utils/addQueryString'
import { FILTER_KEYS } from './constants'
import { useFilterSettings } from 'hooks/useFilterSettings'
import {
  filterItemsSelector,
  selectedFiltersCountSelector,
  selectedFiltersSelector,
} from 'redux/filters/selectors'
import { setFilterItems } from 'redux/filters/slice'
import { sortingSelector } from 'redux/sorting/selectors'
import { APPLICATIONS_FILTER_KEY, SORTING_KEYS } from 'constants/filters'
import {
  APPLICATION_SEARCH_PARAM,
  APPLICATION_STATUS,
} from 'modules/Applications/constants'
import { ApplicationStatus } from 'api/applications/types'
import { Typography } from 'ui/atoms/Typography'

export const ApplicationList = ({
  status,
  selectedRange,
  isArchive,
}: ApplicationListProps) => {
  const [page, setPage] = useState(DEFAULT_INITIAL_PAGE)
  const [pageSize, setPageSize] = useState(DEFAULT_INITIAL_PAGE_SIZE)
  const selectedFiltersCount = useSelector(selectedFiltersCountSelector)
  const selectedFilters = useSelector(selectedFiltersSelector)
  const sortingData = useSelector(sortingSelector)
  const filterItems = useSelector(filterItemsSelector)
  const [applications, setApplications] = useState<
    ApplicationsApiTypes.ApplicationListResponse['applications'] | null
  >(null)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const changePageSize = (value: string) => {
    setPageSize(value)
    setPage(DEFAULT_INITIAL_PAGE)
  }

  const handleChange = (pageNumber: number) => {
    setPage(pageNumber)
  }

  const applicationListRequest = useApiRequest(
    (status, isArchive, fromDate, toDate, filters, sorting) =>
      applicationsApi.getApplicationList(
        page - 1,
        Number(pageSize),
        status,
        isArchive,
        fromDate,
        toDate,
        filters,
        sorting
      )
  )

  const applicationFiltersRequest = useApiRequest(
    (fromDate, toDate, isArchive) =>
      applicationsApi.getApplicationFilters(fromDate, toDate, isArchive)
  )

  const fetchApplicationFilters = () => {
    const fetchFilters = async () => {
      const applicationFiltersResponse =
        await applicationFiltersRequest.apiRequest(
          selectedRange?.from,
          selectedRange?.to,
          isArchive
        )

      if (applicationFiltersResponse) {
        const filterItems = Object.entries(
          applicationFiltersResponse.data
        ).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.map((el) => ({ value: el, selected: false })),
          }),
          {}
        )
        dispatch(
          setFilterItems({ items: filterItems, key: APPLICATIONS_FILTER_KEY })
        )
      }
    }
    fetchFilters()
  }

  useEffect(() => {
    selectedRange?.from && selectedRange.to && fetchApplicationFilters()
  }, [page, pageSize, status, selectedRange])

  useEffect(() => {
    const fetchFilteredCompanyList = async () => {
      let filters: Filter[] = []

      if (selectedFiltersCount) {
        filters = Object.entries(selectedFilters.applications).map(
          ([key, value]) => ({
            name: FILTER_KEYS[key],
            type: 'In',
            value: value.join(','),
          })
        )
      }

      const sortingStr = sortingData.applications
        .map((el) => `[${SORTING_KEYS[el.key]}]${el.type}`)
        .join(',')

      const applicationListResponse = await applicationListRequest.apiRequest(
        status,
        isArchive,
        selectedRange.from,
        selectedRange.to,
        filters && filters,
        sortingStr
      )
      if (applicationListResponse) {
        setApplications(applicationListResponse.data.applications)
      }
    }
    selectedRange?.from && selectedRange.to && fetchFilteredCompanyList()
  }, [
    page,
    pageSize,
    selectedFiltersCount,
    selectedRange,
    sortingData.applications,
  ])

  const getFilterSettings = useFilterSettings(
    APPLICATIONS_FILTER_KEY,
    setPage,
    APPLICATIONS_FILTER_KEY
  )

  const handleRedirectToApplication = (id: string) => {
    navigate(
      `../${
        APPLICATION_SEARCH_PARAM[
          isArchive ? APPLICATION_STATUS.Archive : (status as ApplicationStatus)
        ]
      }/${id}`
    )
  }

  const formattedBodyItems = useMemo(
    () =>
      applications?.pageItems.map((application) => {
        const items = {
          items: [
            application.Id,
            application.companyName,
            application.submission,
            <Typography
              Tag="a"
              className={styles['email-link']}
              name="Subtitle7"
              color="blue700"
              target="_blank"
              onClick={(e) => {
                e.stopPropagation()
              }}
              href={`mailto:${application.companyEmail}`}
            >
              {application.companyEmail}
            </Typography>,
          ],
          onClick: () => handleRedirectToApplication(String(application.Id)),
        }

        if (status !== APPLICATION_STATUS.WaitingList) {
          items.items.push(
            application.status === APPLICATION_STATUS.WaitingList
              ? '-'
              : application.processTime
          )
        }
        return items
      }),
    [applications, status]
  )

  const formattedHeadItems = useMemo(() => {
    const newHeadItems: TableHeadProps['item'][] = Object.values(
      HEAD_ITEMS
    ).map((header) => ({
      value: header,
    }))
    if (status === APPLICATION_STATUS.WaitingList) {
      newHeadItems.pop()
    }

    const settingKey = Object.keys(FILTER_KEYS)
    newHeadItems.forEach(
      (headItem, i) =>
        (headItem.filterSettings = getFilterSettings(settingKey[i]))
    )
    return newHeadItems
  }, [filterItems, getFilterSettings, status])

  return (
    <div>
      <div className={styles.list}>
        <div className={styles.table}>
          <Table
            headItems={formattedHeadItems}
            bodyItems={formattedBodyItems}
            className={styles.thead}
          />
        </div>
      </div>
      <footer className={styles.pagination}>
        <Pagination
          current={page}
          total={applications?.totalItems || 0}
          pageSize={pageSize}
          changePageSize={changePageSize}
          changePage={handleChange}
          pageSizeLabel={PAGINATION_DROPDOWN_LABEL}
        />
      </footer>
    </div>
  )
}
