import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ImportantIcon = ({
  size,
  color = 'red600Master',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className} viewBox="0 0 20 20">
    <path
      d="M10 17.5C5.85787 17.5 2.5 14.1421 2.5 10C2.5 5.85787 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.66699L10 10.0003"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.333L9.99167 13.333"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
