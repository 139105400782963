import { useEffect, RefObject } from 'react'

interface UseDetectPositionChangeProps {
  ref: RefObject<HTMLElement> | null
  onChange: VoidFunction
}

export const useDetectPositionChange = ({
  ref,
  onChange,
}: UseDetectPositionChangeProps) => {
  useEffect(() => {
    let lastPosition = ref?.current?.getBoundingClientRect()

    const handleScrollOrResize = () => {
      if (ref && ref.current) {
        const currentPosition = ref.current.getBoundingClientRect()
        if (
          lastPosition &&
          (currentPosition.top !== lastPosition.top ||
            currentPosition.left !== lastPosition.left)
        ) {
          onChange()
        }
        lastPosition = currentPosition
      }
    }

    window.addEventListener('scroll', handleScrollOrResize, true)
    window.addEventListener('resize', handleScrollOrResize)

    return () => {
      window.removeEventListener('scroll', handleScrollOrResize, true)
      window.removeEventListener('resize', handleScrollOrResize)
    }
  }, [ref, onChange])
}
