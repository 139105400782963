import { useSelectValues } from 'hooks/useSelectValues'
import { Input } from 'ui/atoms/Input'
import { Select } from 'ui/atoms/Select'
import arrowRight from 'assets/img/arrowRight.webp'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { getValidationRules } from 'utils/getValidationRules'
import { ContactInformationFormProps } from './types'
import { useSelectLocation } from '../../../../hooks/useSelectLocation'
import countryOfResidence from 'constants/countryOfResidence'
import { Phone } from 'ui/molecules/Phone'
import { PHONE_NUMBER_LABELS } from 'texts/uiTexts'
import styles from './styles.module.scss'
import { ItemType } from 'ui/atoms/Select/types'

import { useMemo, useContext } from 'react'
import {
  LOCATION_TABS,
  NEXT_BUTTON_TITLE,
  BUTTON_MAP_TITLE,
  LOCATION_ADDING_FORM_VALUES,
} from 'texts/locationDetails'
import { CONTACT_FORM_FIELDS } from '../../constants'
import { CONTACT_INFORMATION_FORM_VALUES } from 'texts/profileDetails'
import { EMPTY_RULES } from 'constants/form'
import { Rule } from 'ui/molecules/Form/components/FormItem/types'
import { formValuesSetting } from './constants'
import { FormContext } from 'ui/molecules/Form/Form'
import { ContextType } from 'ui/molecules/Form/types'
import { FormValuesSetting } from 'types/form'
import { PickupOptions } from 'api/locations/types'
import { Textarea, OriginButton } from '@frontend/design_system'
import { onlyNumbersWithOneDotAndMinusRegex } from 'constants/regex'
import { useDispatch, useSelector } from 'react-redux'
import { CrossHairIcon } from 'ui/icons'

import { setMapData, setMarkerPosition } from 'redux/locations/slice'
import { FormValues } from '../../types'
import { mapDataSelector } from 'redux/locations/selectors'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { scrollTop } from 'utils/scrollTop'

const requiredFields = [
  LOCATION_ADDING_FORM_VALUES.COUNTRY.ID,
  LOCATION_ADDING_FORM_VALUES.CITY.ID,
  LOCATION_ADDING_FORM_VALUES.LOCATION.ID,
  LOCATION_ADDING_FORM_VALUES.STREET.ID,
  LOCATION_ADDING_FORM_VALUES.BUILDING.ID,
  LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.ID,
]
const allMapFields = [...requiredFields, LOCATION_ADDING_FORM_VALUES.STATE.ID]

const isMapReady = (values: Partial<FormValues>) =>
  requiredFields.every((id) => (values as { [key: string]: string })[id])

export const ContactInformationForm = ({
  currentLocation,
  changeLocation,
  values,
  locationId,
  changeActiveKey,
}: ContactInformationFormProps) => {
  const dispatch = useDispatch()
  const mapData = useSelector(mapDataSelector)
  const { checkValidation, setErrors } = useContext<ContextType>(FormContext)
  const pickUpOptionsSelectValues = useSelectValues(
    Object.entries(PickupOptions).map(([key, value]) => ({
      id: key,
      name: value,
    }))
  )
  const {
    handleCountryChange,
    handleCityChange,
    handleLocationChange,
    countriesSelectValues,
    citiesSelectValues,
    locationsSelectValues,
    countries,
    loading,
  } = useSelectLocation(currentLocation, changeLocation, locationId)

  useDidUpdate(
    () => {
      if (mapData) {
        dispatch(setMapData(null))
      }
    },
    allMapFields.map((id) => (values as { [key: string]: string })[id])
  )

  const goNextTab = () => {
    const { newErrors } = checkValidation()
    const filteredErrors = Object.keys(CONTACT_FORM_FIELDS).reduce(
      (acc: Record<string, unknown>, item) => {
        if (newErrors[item]) {
          acc[item] = newErrors[item]
        }
        return acc
      },
      {}
    )

    if (!Object.keys(filteredErrors).length) {
      scrollTop()
      changeActiveKey(LOCATION_TABS.WORKING_HOURS)
      setErrors(() => ({}))
    } else {
      setErrors(() => filteredErrors)
    }
  }

  const phoneCorRules: Rule[] = useMemo(
    () => [
      {
        type: 'required',
        message: CONTACT_INFORMATION_FORM_VALUES.PHONE_COR.REQUIRED_MESSAGE,
      },
    ],
    []
  )

  const showMap = () => {
    dispatch(setMarkerPosition({ lat: 0, lng: 0 }))
    dispatch(
      setMapData({
        country: countriesSelectValues.find(
          (item) => item.value === values.countryId
        )?.label,
        city: citiesSelectValues.find((item) => item.value === values.cityId)
          ?.label,
        location: locationsSelectValues.find(
          (item) => item.value === values.locationId
        )?.label,
        street: values.street,
        region: values.region,
        building: values.building,
        postalCode: values.postalCode,
      })
    )
  }

  const validationRules = (field: FormValuesSetting['key']) =>
    useMemo(() => getValidationRules(field, 'operator'), [])

  const getCor = (country: ItemType) => {
    const countryCode = countries.items.find((el) => el.name === country.label)
      ?.meta?.CountryAlpha2Code
    const selectedCountry = countryOfResidence.find(
      (el) => el.code === countryCode
    )
    return selectedCountry
  }

  return (
    <>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.COUNTRY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.countryId)}
        >
          {({ value, error, onChange, onChangeField }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.COUNTRY.PLACEHOLDER}
              onChange={(el: string, item: ItemType) => {
                onChange(el)
                handleCountryChange(item)
                onChangeField(
                  getCor(item),
                  LOCATION_ADDING_FORM_VALUES.PHONE_COR.ID
                )
                onChangeField('', LOCATION_ADDING_FORM_VALUES.LOCATION.ID)
                onChangeField('', LOCATION_ADDING_FORM_VALUES.CITY.ID)
              }}
              className={styles.select}
              label={LOCATION_ADDING_FORM_VALUES.COUNTRY.LABEL}
              items={countriesSelectValues}
              error={error}
              selectedValue={value}
              isRequired
              search={{
                placeholder:
                  LOCATION_ADDING_FORM_VALUES.COUNTRY.SEARCH_PLACEHOLDER,
              }}
              loading={loading.countries}
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.CITY.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.cityId)}
        >
          {({ value, error, onChange, onChangeField }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.CITY.PLACEHOLDER}
              onChange={(el: string, item: ItemType) => {
                onChange(el)
                handleCityChange(item)
                onChangeField('', LOCATION_ADDING_FORM_VALUES.LOCATION.ID)
              }}
              className={styles.select}
              label={LOCATION_ADDING_FORM_VALUES.CITY.LABEL}
              items={citiesSelectValues}
              error={error}
              selectedValue={value}
              isRequired
              disabled={!currentLocation.country.name}
              search={{
                placeholder:
                  LOCATION_ADDING_FORM_VALUES.CITY.SEARCH_PLACEHOLDER,
              }}
              loading={loading.cities}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.LOCATION.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.locationId)}
        >
          {({ value, error, onChange }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.LOCATION.PLACEHOLDER}
              onChange={(el: string, item: ItemType) => {
                onChange(el)
                handleLocationChange(item)
              }}
              className={styles.select}
              label={LOCATION_ADDING_FORM_VALUES.LOCATION.LABEL}
              items={locationsSelectValues}
              error={error}
              selectedValue={value}
              disabled={!currentLocation.city.name}
              isRequired
              search={{
                placeholder:
                  LOCATION_ADDING_FORM_VALUES.LOCATION.SEARCH_PLACEHOLDER,
              }}
              loading={loading.locations}
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.STATE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.state)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.STATE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.STATE.LABEL}
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.STREET.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.street)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.STREET.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.STREET.LABEL}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.BUILDING.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.building)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.BUILDING.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.BUILDING.LABEL}
              isRequired
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-item-full']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.postalCode)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.POSTAL_CODE.LABEL}
              isRequired
            />
          )}
        </FormItem>
        <div className={styles['form-item-right']}>
          <div className={styles['form-item-map-button']}>
            <OriginButton
              onClick={showMap}
              size="large"
              iconLeft={
                <CrossHairIcon
                  size="medium"
                  color={
                    !isMapReady(values as FormValues)
                      ? 'blueSecondary'
                      : 'whiteMaster'
                  }
                />
              }
              label={BUTTON_MAP_TITLE}
              disabled={!isMapReady(values as FormValues)}
            />
          </div>
        </div>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.LATITUDE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.latitude)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.LATITUDE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={(value) => {
                const lat = value.replace(
                  onlyNumbersWithOneDotAndMinusRegex,
                  ''
                )
                dispatch(setMarkerPosition({ lat: +lat }))
                onChange(lat)
              }}
              label={LOCATION_ADDING_FORM_VALUES.LATITUDE.LABEL}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.LONGITUDE.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.longitude)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.LONGITUDE.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={(value) => {
                const lng = value.replace(
                  onlyNumbersWithOneDotAndMinusRegex,
                  ''
                )
                dispatch(setMarkerPosition({ lng: +lng }))
                onChange(lng)
              }}
              label={LOCATION_ADDING_FORM_VALUES.LONGITUDE.LABEL}
              isRequired
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-full']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.CURRENCY.ID}
          className={styles['form-item']}
        >
          {({ value, error, onChange }) => (
            <Input
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.CURRENCY.PLACEHOLDER}
              error={error}
              value={value}
              disabled
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.CURRENCY.LABEL}
              isRequired
            />
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-full']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.phone)}
        >
          {({ value: number, onChange: changeNumber, error }) => (
            <FormItem
              id={LOCATION_ADDING_FORM_VALUES.PHONE_COR.ID}
              className={styles.phone}
              rules={number?.length ? phoneCorRules : EMPTY_RULES}
            >
              {({ value: cor, onChange, error: corError }) => (
                <Phone
                  label={LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.LABEL}
                  number={number}
                  changeNumber={changeNumber}
                  cor={cor}
                  changeCOR={onChange}
                  corList={countryOfResidence}
                  locales={{
                    ofLabel: PHONE_NUMBER_LABELS.OF,
                    searchPlaceholder: PHONE_NUMBER_LABELS.SEARCH,
                    save: PHONE_NUMBER_LABELS.SAVE,
                  }}
                  isRequired
                  spaceForError="auto"
                  error={error || corError}
                  placeholder={
                    LOCATION_ADDING_FORM_VALUES.PHONE_NUMBER.PLACEHOLDER
                  }
                />
              )}
            </FormItem>
          )}
        </FormItem>
      </div>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.EMAIL.ID}
          className={styles['form-item']}
          rules={validationRules(formValuesSetting.email)}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={LOCATION_ADDING_FORM_VALUES.EMAIL.PLACEHOLDER}
              size="large"
              error={error}
              spaceForError="auto"
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.EMAIL.LABEL}
              isRequired
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.ID}
          className={styles['form-item']}
        >
          {({ value, error, onChange }) => (
            <Select
              size="large"
              placeholder={LOCATION_ADDING_FORM_VALUES.CURRENCY.PLACEHOLDER}
              error={error}
              onChange={onChange}
              label={LOCATION_ADDING_FORM_VALUES.PICKUP_OPTION.LABEL}
              items={pickUpOptionsSelectValues}
              selectedValue={value}
            />
          )}
        </FormItem>
      </div>
      <FormItem
        id={LOCATION_ADDING_FORM_VALUES.PICKUP_INSTRUCTIONS.ID}
        rules={validationRules(formValuesSetting.pickUpInstruction)}
        className={styles['text-area-field']}
      >
        {({ value, error, onChange }) => (
          <Textarea
            value={value}
            onChange={onChange}
            label={LOCATION_ADDING_FORM_VALUES.PICKUP_INSTRUCTIONS.LABEL}
            placeholder={
              LOCATION_ADDING_FORM_VALUES.PICKUP_INSTRUCTIONS.PLACEHOLDER
            }
            error={error}
          />
        )}
      </FormItem>
      <FormItem
        id={LOCATION_ADDING_FORM_VALUES.DROPOFF_INSTRUCTIONS.ID}
        rules={validationRules(formValuesSetting.dropOffInstruction)}
        className={styles['text-area-field']}
      >
        {({ value, error, onChange }) => (
          <Textarea
            value={value}
            onChange={onChange}
            label={LOCATION_ADDING_FORM_VALUES.DROPOFF_INSTRUCTIONS.LABEL}
            placeholder={
              LOCATION_ADDING_FORM_VALUES.DROPOFF_INSTRUCTIONS.PLACEHOLDER
            }
            error={error}
          />
        )}
      </FormItem>
      <div className={styles['form-item-right']}>
        <div className={styles['form-next-button']}>
          <OriginButton
            variant="link"
            size="large"
            onClick={goNextTab}
            label={NEXT_BUTTON_TITLE}
            typographyName="Button1"
            iconRight={<img src={arrowRight} width={16} height={16} />}
          />
        </div>
      </div>
    </>
  )
}
