import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const LocationIcon = ({
  size,
  color = 'grey400',
  className,
}: IconProps) => (
  <SVGIcon size={size} color={color} className={className} viewBox="0 0 20 20">
    <path
      d="M9.99992 18.3333C5.39754 18.3333 1.66658 14.6023 1.66658 9.99996C1.66658 5.39759 5.39754 1.66663 9.99992 1.66663C14.6023 1.66663 18.3333 5.39759 18.3333 9.99996C18.3333 14.6023 14.6023 18.3333 9.99992 18.3333Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.46678 6.46667L8.23345 11.7667L13.5334 13.5333L11.7668 8.23334L6.46678 6.46667Z"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.75"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
