import { errorCodes } from 'constants/errorCodes'

export const getErrorMessage = (
  errorCode?: string | number,
  errorList: { [key: string | number]: string } = errorCodes
) => {
  if (!errorCode) {
    return null
  }

  return errorList[errorCode]
}
