import { UpdateCompanyInfoRequest } from 'api/profile/types'
import { Cor } from 'types'
import { CustomFile, UploadFileProps } from 'ui/atoms/UploadFile/types'

export const initialFormValues: Omit<UpdateCompanyInfoRequest, 'logo'> & {
  logo?: CustomFile[]
  phoneCor?: Cor
} = {
  companyName: '',
  postalCode: '',
  companyCountryID: '',
  companyCurrencyID: '',
  contactPersonName: '',
  brandName: '',
  street: '',
  building: '',
  city: '',
  url: '',
  logo: [],
  email: '',
  state: '',
  phone: '',
  legalName: '',
  registrationNumber: '',
  bankName: '',
  bankAddress: '',
  swiftCode: '',
  bankAccountNumber: '',
  abaRoutingNumber: '',
  companyCurrencyName: '',
  accountingEmailAddress: '',
  vatNumber: '',
  phoneCor: undefined,
}

export const formats: UploadFileProps['formats'] = ['jpg', 'jpeg', 'png']
