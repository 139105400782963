import {
  Form,
  FormItem,
  Checkbox,
  Tooltip,
  Typography,
} from '@frontend/design_system'
import { Block } from '../Block'
import { IFormValues } from './types'
import {
  ADDED_SUCCESS,
  ADDITIONAL_EQUIPMENT_TOOLTIP,
  RENTAL_EXCLUDES,
  RENTAL_EXCLUDES_FORM_VALUES,
  UPDATED_SUCCESS,
} from 'texts/termsAndConditions'
import { useMemo } from 'react'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { CorBtn } from '../CorBtn'
import styles from './styles.module.scss'
import { FormChildren, FormItemChildren } from 'types/form'
import { InfoIcon } from 'ui/icons'
import { ActionButtons } from '../ActionButtons'
import { Row } from '../Row'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { useApiRequest } from 'hooks/useApiRequest'
import { termsApi } from 'api'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import {
  TERM_CONDITIONS_OPTIONS,
  TERM_CONDITIONS_TYPES,
} from 'constants/termsAndConditions'
import { prepareResponseItems, prepareCorForSelect } from 'utils/form'
import { useCompanyId } from 'hooks/useCompanyId'

export const RentalExcludes = ({ reload, data }: IContentProps) => {
  const dispatch = useDispatch()
  const companyId = useCompanyId()
  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )

  const initValues = useMemo(() => {
    const initData: IFormValues = {
      cor: COR_ITEMS,
      fines: true,
      additionalEquipment: true,
    }

    if (data) {
      initData.cor = prepareCorForSelect(data.countriesOfResidence)
      initData.fines =
        data.options?.includes(
          TERM_CONDITIONS_OPTIONS.FinesAndTollFeesAndParkingCharges
        ) || false
      initData.additionalEquipment =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.AdditionalEquipment) ||
        false
    }

    return initData
  }, [data])

  const onSubmit = async (values: IFormValues, validate: boolean) => {
    if (validate) {
      const options = []
      if (values.fines) {
        options.push(TERM_CONDITIONS_OPTIONS.FinesAndTollFeesAndParkingCharges)
      }
      if (values.additionalEquipment) {
        options.push(TERM_CONDITIONS_OPTIONS.AdditionalEquipment)
      }

      const body = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.RentalExcludes,
        companyId,
        countriesOfResidence: prepareResponseItems(values.cor),
        options,
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  return (
    <Block title={RENTAL_EXCLUDES}>
      <Form initValues={initValues} onSubmit={onSubmit} key={data?.id}>
        {({ isDirty }: FormChildren) => (
          <>
            <CorBtn />
            <Row isOffset>
              <div>
                <FormItem
                  id={RENTAL_EXCLUDES_FORM_VALUES.FINES_FEES_PARKING.ID}
                >
                  {({ value, onChange }: FormItemChildren) => (
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      variant="bcrm"
                      borderVariant="grey"
                      size="newFontsSecondary"
                      label={
                        RENTAL_EXCLUDES_FORM_VALUES.FINES_FEES_PARKING.LABEL
                      }
                    />
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem
                  id={RENTAL_EXCLUDES_FORM_VALUES.ADDITIONAL_EQUIPMENT.ID}
                  className={styles['additional-equipment']}
                >
                  {({ value, onChange }: FormItemChildren) => (
                    <>
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        variant="bcrm"
                        borderVariant="grey"
                        size="newFontsSecondary"
                        label={
                          RENTAL_EXCLUDES_FORM_VALUES.ADDITIONAL_EQUIPMENT.LABEL
                        }
                      />
                      <Tooltip
                        size="secondary"
                        popupContent={
                          <Typography
                            name="caption1WMedium"
                            className={styles['additional-equipment-tooltip']}
                            Tag="div"
                          >
                            {ADDITIONAL_EQUIPMENT_TOOLTIP}
                          </Typography>
                        }
                        placement="bottom"
                        arrowPosition="right"
                      >
                        <InfoIcon color="blue700" size="small" />
                      </Tooltip>
                    </>
                  )}
                </FormItem>
              </div>
            </Row>
            <ActionButtons
              isOffset
              disabled={data && !isDirty}
              onCancel={data && reload}
              loading={
                newTermConditionRequest.loading ||
                updateTermConditionRequest.loading
              }
            />
          </>
        )}
      </Form>
    </Block>
  )
}
