import { useEffect, useState } from 'react'
import { MenuItem } from 'ui/molecules/Menu/MenuItem'
import { SubmenuItem } from 'ui/molecules/Menu/SubmenuItem'
import {
  MENU_PATHS,
  OPERATOR_MENU_ITEMS,
  OPERATOR_SUBMENU_ITEMS,
  SUPPLIER_MENU_ITEMS,
} from './constants'
import { useAppSelector } from 'redux/hooks'
import { userDataSelector, userRoleSelector } from 'redux/login/selectors'
import { UserRoles } from 'constants/roles'
import { MenuItemsType } from './types'
import { useLocation } from 'react-router-dom'
import { useDisableMenuItems } from 'hooks/useDisableMenuItems'
import { MENU_NAMES } from 'texts/menuNames'
import { ACCOUNT_STATUSES } from 'api/auth/constants'
import styles from './styles.module.scss'

export const Menu = () => {
  const [activeMenu, setActiveMenu] = useState('')
  const menuItemToUpperCase = (menuItem: MenuItemsType[0]) =>
    menuItem.name.toUpperCase()
  const role = useAppSelector(userRoleSelector)
  const { accountStatus } = useAppSelector(userDataSelector)
  const location = useLocation()
  const { disableMenuItem } = useDisableMenuItems()
  const isApproved = accountStatus === ACCOUNT_STATUSES.APPROVED

  const MENU_CONFIG =
    role === UserRoles.Operator
      ? {
          MENU_ITEMS: OPERATOR_MENU_ITEMS,
          SUBMENU_ITEMS: OPERATOR_SUBMENU_ITEMS,
        }
      : {
          MENU_ITEMS: SUPPLIER_MENU_ITEMS(isApproved),
        }

  useEffect(() => {
    const activeMenu = Object.entries(MENU_PATHS).find(([, value]) =>
      location.pathname.includes(value)
    )?.[0]

    if (
      (activeMenu === MENU_NAMES.PROFILE_LIST.NAME ||
        activeMenu === MENU_NAMES.PROFILE_PENDING) &&
      role === UserRoles.Supplier
    ) {
      setActiveMenu(MENU_NAMES.PROFILE_DETAILS.NAME)
    } else {
      setActiveMenu(activeMenu || '')
    }
  }, [location.pathname])

  return (
    <nav className={styles.menu}>
      {role
        ? MENU_CONFIG.MENU_ITEMS.map((menuItem) => (
            <MenuItem
              name={menuItem.name}
              path={menuItem.path}
              key={menuItem.name}
              activeItem={activeMenu}
              disabled={disableMenuItem(menuItem.name)}
              iconSrc={menuItem.icon}
              onMenuItemClick={setActiveMenu}
              withSubmenu={menuItem.withSubmenu}
            >
              {menuItem.withSubmenu
                ? MENU_CONFIG.SUBMENU_ITEMS?.[
                    menuItemToUpperCase(menuItem)
                  ].map((submenuItem) => (
                    <SubmenuItem
                      path={submenuItem.path}
                      key={submenuItem.name}
                      name={submenuItem.name}
                      activeItem={activeMenu}
                      onMenuItemClick={setActiveMenu}
                    />
                  ))
                : null}
            </MenuItem>
          ))
        : null}
    </nav>
  )
}
