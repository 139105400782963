import {
  ADDED_SUCCESS,
  INSURANCE_TOOLTIP,
  MILLAGE,
  RENTAL_INCLUDES,
  RENTAL_INCLUDES_FORM_VALUES,
  UPDATED_SUCCESS,
} from 'texts/termsAndConditions'
import { Block } from '../Block'
import {
  Form,
  FormItem,
  Multiselect,
  Checkbox,
  Typography,
  RadioButton,
  Select,
  Input,
  Tooltip,
} from '@frontend/design_system'
import {
  DISTANCE_RULES,
  PERIOD_RULES,
  PICKUP_COUNTRY_RULES,
  RADIO_BUTTONS,
  UNIT_RULES,
} from './constants'
import { FormChildren, FormItemChildren } from 'types/form'
import { IFormValues } from './types'
import { ActionButtons } from '../ActionButtons'
import styles from './styles.module.scss'
import { useMemo } from 'react'
import { InfoIcon } from 'ui/icons'
import { CorBtn } from '../CorBtn'
import { COR_ITEMS } from 'constants/countryOfResidence'
import { Row } from '../Row'
import {
  CAR_TYPES,
  CAR_TYPES_OPTIONS,
  DISTANCE_UNIT_NAMES_OPTIONS,
  MILEAGE_TYPES,
  TERM_CONDITIONS_OPTIONS,
  TERM_CONDITIONS_TYPES,
  VEHICLE_PERIOD_UNIT_NAMES_OPTIONS,
} from 'constants/termsAndConditions'
import { useApiRequest } from 'hooks/useApiRequest'
import { termsApi } from 'api'
import { INewTermConditionBody } from 'api/terms/types'
import { prepareResponseItems, prepareCorForSelect } from 'utils/form'
import { useDispatch } from 'react-redux'
import { setNotificationMessage } from 'redux/notifications/slice'
import { IContentProps } from 'modules/TermsAndConditions/types'
import { useCompanyId } from 'hooks/useCompanyId'

export const RentalIncludes = ({ reload, data }: IContentProps) => {
  const dispatch = useDispatch()
  const companyId = useCompanyId()
  const newTermConditionRequest = useApiRequest(
    (body) => termsApi.newTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const updateTermConditionRequest = useApiRequest(
    (body) => termsApi.updateTermCondition(body),
    undefined,
    true,
    undefined,
    false
  )
  const initValues = useMemo(() => {
    const initData: IFormValues = {
      cor: COR_ITEMS,
      localTaxes: true,
      insurance: true,
      airportSurcharge: false,
      roadAssistance: false,
      mileageType: RENTAL_INCLUDES_FORM_VALUES.UNLIMITED.ID,
      exceptCarTypes: [],
      pickupCountry: COR_ITEMS,
      distance: undefined,
      unit: undefined,
      period: undefined,
    }

    if (data) {
      initData.cor = prepareCorForSelect(data.countriesOfResidence)
      initData.localTaxes =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.LocalTaxes) || false
      initData.airportSurcharge =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.Insurance) || false
      initData.airportSurcharge =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.AirportSurcharge) ||
        false
      initData.roadAssistance =
        data.options?.includes(TERM_CONDITIONS_OPTIONS.RoadAssistance24h) ||
        false
      initData.mileageType =
        data.mileageType || RENTAL_INCLUDES_FORM_VALUES.UNLIMITED.ID
      initData.exceptCarTypes = CAR_TYPES_OPTIONS.filter(({ value }) =>
        data.exceptCarTypes?.includes(value)
      )
      initData.pickupCountry = prepareCorForSelect(data.pickUpCountries)
      initData.distance = data.distance
      initData.unit = data.distanceUnit
      initData.period = data.period
    }

    return initData
  }, [data])

  const onSubmit = async (values: IFormValues, validate: boolean) => {
    if (validate) {
      const options = []
      if (values.localTaxes) {
        options.push(TERM_CONDITIONS_OPTIONS.LocalTaxes)
      }
      if (values.insurance) {
        options.push(TERM_CONDITIONS_OPTIONS.Insurance)
      }
      if (values.airportSurcharge) {
        options.push(TERM_CONDITIONS_OPTIONS.AirportSurcharge)
      }
      if (values.roadAssistance) {
        options.push(TERM_CONDITIONS_OPTIONS.RoadAssistance24h)
      }

      const body: INewTermConditionBody = {
        termConditionTypeId: TERM_CONDITIONS_TYPES.RentalIncludes,
        companyId,
        countriesOfResidence: prepareResponseItems(values.cor),
        mileageType: values.mileageType,
        exceptCarTypes: prepareResponseItems<CAR_TYPES>(values.exceptCarTypes),
        pickUpCountries: prepareResponseItems(values.pickupCountry),
        options,
      }

      if (values.mileageType === MILEAGE_TYPES.Limited) {
        body.distance = values.distance
        body.distanceUnit = values.unit
        body.period = values.period
      }

      if (data) {
        await updateTermConditionRequest.apiRequest({
          ...body,
          termConditionSettingId: data.id,
        })
        dispatch(
          setNotificationMessage({
            notificationMessage: UPDATED_SUCCESS,
          })
        )
      } else {
        await newTermConditionRequest.apiRequest(body)
        dispatch(
          setNotificationMessage({
            notificationMessage: ADDED_SUCCESS,
          })
        )
      }

      reload()
    }
  }

  return (
    <Block title={RENTAL_INCLUDES}>
      <Form initValues={initValues} onSubmit={onSubmit} key={data?.id}>
        {({ values, isDirty }: FormChildren) => (
          <>
            <CorBtn />
            <Row isOffset>
              <div>
                <FormItem id={RENTAL_INCLUDES_FORM_VALUES.LOCAL_TAXES.ID}>
                  {({ value, onChange }: FormItemChildren) => (
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      variant="bcrm"
                      borderVariant="grey"
                      size="newFontsSecondary"
                      label={RENTAL_INCLUDES_FORM_VALUES.LOCAL_TAXES.LABEL}
                    />
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem
                  id={RENTAL_INCLUDES_FORM_VALUES.INSURANCE.ID}
                  className={styles.insurance}
                >
                  {({ value, onChange }: FormItemChildren) => (
                    <>
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        variant="bcrm"
                        borderVariant="grey"
                        size="newFontsSecondary"
                        label={RENTAL_INCLUDES_FORM_VALUES.INSURANCE.LABEL}
                      />
                      <Tooltip
                        size="secondary"
                        popupContent={
                          <Typography
                            name="caption1WMedium"
                            className={styles['insurance-tooltip']}
                            Tag="div"
                          >
                            {INSURANCE_TOOLTIP}
                          </Typography>
                        }
                        placement="bottom"
                      >
                        <InfoIcon color="blue700" size="small" />
                      </Tooltip>
                    </>
                  )}
                </FormItem>
              </div>
            </Row>
            <Row>
              <div>
                <FormItem id={RENTAL_INCLUDES_FORM_VALUES.AIRPORT_SURCHARGE.ID}>
                  {({ value, onChange }: FormItemChildren) => (
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      variant="bcrm"
                      borderVariant="grey"
                      size="newFontsSecondary"
                      label={
                        RENTAL_INCLUDES_FORM_VALUES.AIRPORT_SURCHARGE.LABEL
                      }
                    />
                  )}
                </FormItem>
              </div>
              <div>
                <FormItem id={RENTAL_INCLUDES_FORM_VALUES.ROAD_ASSISTANCE.ID}>
                  {({ value, onChange }: FormItemChildren) => (
                    <Checkbox
                      checked={value}
                      onChange={onChange}
                      variant="bcrm"
                      borderVariant="grey"
                      size="newFontsSecondary"
                      label={RENTAL_INCLUDES_FORM_VALUES.ROAD_ASSISTANCE.LABEL}
                    />
                  )}
                </FormItem>
              </div>
            </Row>
            <Typography Tag="h4" name="body1WBold" className={styles.subtitle}>
              {MILLAGE}
            </Typography>
            <FormItem id={RENTAL_INCLUDES_FORM_VALUES.MILLAGE.ID}>
              {({ value, onChange }: FormItemChildren) => (
                <Row isOffset>
                  {RADIO_BUTTONS.map(({ label, id }) => (
                    <RadioButton
                      labelTypographyName="Button2"
                      key={id}
                      id={id}
                      label={label}
                      onChange={onChange}
                      checked={id === value}
                    />
                  ))}
                </Row>
              )}
            </FormItem>
            {values[RENTAL_INCLUDES_FORM_VALUES.MILLAGE.ID] ===
              MILEAGE_TYPES.Limited && (
              <Row>
                <FormItem
                  id={RENTAL_INCLUDES_FORM_VALUES.DISTANCE.ID}
                  rules={DISTANCE_RULES}
                >
                  {({ value, error, onChange }: FormItemChildren) => (
                    <Input
                      value={value}
                      size="large"
                      error={error}
                      spaceForError="auto"
                      onChange={onChange}
                      label={RENTAL_INCLUDES_FORM_VALUES.DISTANCE.LABEL}
                      isRequired
                      type="integer"
                    />
                  )}
                </FormItem>
                <FormItem
                  id={RENTAL_INCLUDES_FORM_VALUES.UNIT.ID}
                  rules={UNIT_RULES}
                >
                  {({ onChange, value, error }: FormItemChildren) => (
                    <Select
                      selectedValue={value}
                      items={DISTANCE_UNIT_NAMES_OPTIONS}
                      placeholder=""
                      size="large"
                      onChange={onChange}
                      label={RENTAL_INCLUDES_FORM_VALUES.UNIT.LABEL}
                      isRequired
                      error={error}
                    />
                  )}
                </FormItem>
                <FormItem
                  id={RENTAL_INCLUDES_FORM_VALUES.PERIOD.ID}
                  rules={PERIOD_RULES}
                >
                  {({ onChange, value, error }: FormItemChildren) => (
                    <Select
                      selectedValue={value}
                      items={VEHICLE_PERIOD_UNIT_NAMES_OPTIONS}
                      placeholder=""
                      size="large"
                      onChange={onChange}
                      label={RENTAL_INCLUDES_FORM_VALUES.PERIOD.LABEL}
                      isRequired
                      error={error}
                    />
                  )}
                </FormItem>
              </Row>
            )}
            <Row>
              <FormItem id={RENTAL_INCLUDES_FORM_VALUES.EXCEPT_CAR_TYPES.ID}>
                {({ value, error, onChange }: FormItemChildren) => (
                  <Multiselect
                    value={value}
                    placeholder=""
                    error={error}
                    items={CAR_TYPES_OPTIONS}
                    onChange={onChange}
                    label={RENTAL_INCLUDES_FORM_VALUES.EXCEPT_CAR_TYPES.LABEL}
                  />
                )}
              </FormItem>
              <FormItem
                id={RENTAL_INCLUDES_FORM_VALUES.PICKUP_COUNTRY.ID}
                rules={PICKUP_COUNTRY_RULES}
              >
                {({ value, error, onChange }: FormItemChildren) => (
                  <Multiselect
                    value={value}
                    placeholder=""
                    error={error}
                    items={COR_ITEMS}
                    onChange={onChange}
                    label={RENTAL_INCLUDES_FORM_VALUES.PICKUP_COUNTRY.LABEL}
                    isRequired
                  />
                )}
              </FormItem>
            </Row>
            <ActionButtons
              disabled={data && !isDirty}
              onCancel={data && reload}
              loading={
                newTermConditionRequest.loading ||
                updateTermConditionRequest.loading
              }
            />
          </>
        )}
      </Form>
    </Block>
  )
}
