import { VEHICLE_DETAILS } from 'texts/vehicleDetails'
import {
  DriverAgeRange,
  DriverAgeRangeItem,
  DriverAgeRangeType,
} from 'api/fleet/types'
import { Cor } from 'types'
import { Typography } from '@frontend/design_system'
import { CheckedIcon } from 'ui/icons'
import { ReservationBlockProps } from './types'
import { DRIVER_AGE_RANGE } from 'texts/driverAgeRange'
import { RESERVATION_FORM_FIELDS } from '../../constants'
import styles from './styles.module.scss'

export const ReservationBlock = ({ values }: ReservationBlockProps) => {
  const renderItem = (
    key: string,
    value?: string | Cor | DriverAgeRange | undefined
  ) => (
    <div
      className={styles['reservation-info']}
      key={RESERVATION_FORM_FIELDS[key]}
    >
      <div className={styles['reservation-info-icon']}>
        <CheckedIcon
          color={value ? 'green700Master' : 'grey500'}
          size="medium"
        />
      </div>

      <Typography name="body1WBold">
        {RESERVATION_FORM_FIELDS[key]}:
        <Typography
          name="body1WMedium"
          className={styles['reservation-info-value']}
        >
          {value || '-'}
        </Typography>
      </Typography>
    </div>
  )

  const checkPriceValue = (value: string | number | undefined) =>
    value || value === 0

  const renderDriverAge = (
    item: DriverAgeRangeItem,
    index: number,
    rangeType?: DriverAgeRangeType
  ) => {
    const checked =
      rangeType === DriverAgeRangeType.PerRental
        ? item.ageEnd && item.ageStart && checkPriceValue(item.price)
        : item.ageEnd &&
          item.ageStart &&
          checkPriceValue(item.price) &&
          checkPriceValue(item.maxPrice)
    return (
      <div className={styles['reservation-driver']} key={index}>
        <CheckedIcon
          color={checked ? 'green700Master' : 'grey500'}
          size="medium"
        />
        <div className={styles['reservation-driver-info']}>
          <Typography
            name="body1WBold"
            className={styles['reservation-driver-info-item']}
          >
            {`${DRIVER_AGE_RANGE.START.LABEL} - ${DRIVER_AGE_RANGE.END.LABEL}:`}
            <Typography
              name="body1WMedium"
              className={styles['reservation-info-value']}
            >
              {item.ageStart && item.ageEnd
                ? `${item.ageStart} - ${item.ageEnd}`
                : '-'}
            </Typography>
          </Typography>
          <Typography
            name="body1WBold"
            className={styles['reservation-driver-info-item']}
          >
            {DRIVER_AGE_RANGE.FEE.LABEL}:
            <Typography
              name="body1WMedium"
              className={styles['reservation-info-value']}
            >
              {item.price || '00.00'}
            </Typography>
          </Typography>
          <Typography
            name="body1WBold"
            className={styles['reservation-driver-info-item']}
          >
            {DRIVER_AGE_RANGE.MAX_PRICE.LABEL}:
            <Typography
              name="body1WMedium"
              className={styles['reservation-info-value']}
            >
              {item.maxPrice || '00.00'}
            </Typography>
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div>
      {Object.keys(RESERVATION_FORM_FIELDS).map((key: string) => {
        const value = values[key as keyof typeof values]
        return renderItem(key, value)
      })}
      {values.driverAgeRange?.items && (
        <div className={styles['reservation-driver-block']}>
          <Typography name="body1WBold" className={styles['reservation-title']}>
            {VEHICLE_DETAILS.DRIVER_AGE_RANGE_TITLE}
          </Typography>
          {values.driverAgeRange.items.map((item, index) =>
            renderDriverAge(item, index, values.driverAgeRange?.rangeType)
          )}
        </div>
      )}
    </div>
  )
}
