import { URLS } from 'constants/urls'
import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { authIsLoadedSelector } from 'redux/login/selectors'

interface IProtectRouteProps {
  children?: ReactNode | null
  isUnavailable?: boolean
}

export const ProtectedRoute = ({
  children,
  isUnavailable,
}: IProtectRouteProps) => {
  const isLoaded = useAppSelector(authIsLoadedSelector)

  if (!isLoaded) {
    return null
  }

  if (isUnavailable) {
    return <Navigate to={`${URLS.PROFILE}${URLS.DETAILS}`} />
  }

  return <>{children}</>
}
