import { HtmlTypography } from '@frontend/design_system'
import {
  DRIVER_CREDIT,
  DRIVER_CREDIT_DESCRIPTION,
} from 'texts/termsAndConditions'
import { Block } from '../Block'

export const DriverCreditCard = () => (
  <Block title={DRIVER_CREDIT}>
    <HtmlTypography name="body2WMedium" html={DRIVER_CREDIT_DESCRIPTION} />
  </Block>
)
