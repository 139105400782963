export const TOOLTIP =
  'A drop-off is charged for picking up a rental car at one location and returning it to another.'
export const TABLE = {
  PICK_UP: 'Pick-up',
  DROP_OFF: 'Drop-off',
  APPLY: 'Apply',
}

export const PRICE_INFO = {
  TITLE: 'Price',
  TOOLTIP: {
    TITLE: 'How to set the drop-off fee',
    VALUE_PRICE: 'Enter price if one-way rental is available',
    FREE_PRICE: 'Enter "0" if one-way rental is available for free',
    EMPTY_PRICE: 'Leave this field empty if one-way rental is not available',
  },
}

export const FILTER = {
  SEARCH: 'Search for location',
  NOT_FOUND: 'Location was not found...',
}
