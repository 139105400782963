import { TYPOGRAPHY_COLORS } from 'constants/TypographyColors'
import { SVGIcon } from './SvgIcon/SvgIcon'
import { IconProps } from './types'

export const ArrowLeftRightIcon = ({ size, color = 'grey400' }: IconProps) => (
  <SVGIcon size={size} color={color}>
    <path
      d="M5.06 1.38501L2.12 4.32501L5.06 7.26501"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.12 4.32501H13.88"
      fill="none"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.94 14.615L13.88 11.675L10.94 8.73499"
      fill="none"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.88 11.675H2.12"
      fill="none"
      stroke={TYPOGRAPHY_COLORS[color]}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVGIcon>
)
