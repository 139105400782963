import { DriverAgeRangeType } from 'api/fleet/types'
import { RADIO_BUTTON_LABELS } from './equipmentDetails'
import {
  CROSS_BORDER_POLICY_TYPES,
  FUEL_POLICY_TYPES,
  INSURANCE,
  MILEAGE_TYPES,
} from 'constants/termsAndConditions'

export const RENTAL_INCLUDES = 'Your rental includes'
export const RENTAL_EXCLUDES = 'Your rental excludes'
export const DRIVER_REQUIREMENTS =
  'Driving age and driving licence requirements'
export const FUEL_POLICY = 'Fuel policy'
export const CROSS_BORDER_POLICY = 'Cross border policy'
export const INSURANCE_INCLUDED = 'Insurance included'
export const DRIVER_CREDIT = 'Primary driver credit card pre-authorization'
export const EXCESS_DEPOSIT = 'Excess / deposit'
export const MILLAGE = 'Millage'
export const HISTORY_LIST_ACTION = 'View / Edit'
export const HISTORY_LIST_TITLE = 'T&C by Country of Residence'
export const INSURANCE_TOOLTIP =
  'Collision Damage Waiver, Theft Protection, Third Party Liability etc.'
export const ADDITIONAL_EQUIPMENT_TOOLTIP =
  'Child seat, navigation system (GPS) requested at the time of booking or purchased locally, extra services (e.g. additional driver, out of hours fees, one way rentals) etc.'
export const INSURANCE_INCLUDED_NOTE =
  'Please note, if insurance is not included, this block must be removed.'
export const INSURANCE_INCLUDED_DESCRIPTION = `
  <p>Your rental with Booking Group normally will include collision damage & theft protection waivers.</p>
  <p>Collision Damage Waiver will waive your liability to pay for any damage caused to the vehicle during the period of rental.</p>
  <p>Theft waiver provides coverage for the theft or damage resulting from attempted theft of the vehicle, its parts and accessories up to their full value and any loss that our car rental partner may occur. Both are subject to certain exclusions. </p>
  <p>Your liability is waived subject to you paying a charge, referred to as an excess which limits your liability to the cost of the excess only. In the event that the renter has behaved recklessly or negligently or driven under the influence of drugs or alcohol you may be liable for the full cost damage. </p>
  <p>Please note in common with most car rental policies there are exceptions to cover which may include windscreens, tires, the underside or interior of the vehicle, towing charges & replacement keys. </p>
  <p>Please check your agreement fully at the time of renting for exact exclusions. </p>
  <p>Please note a number of options may be available to buy locally, which reduce your risk or can provide further cover to you and your belongings.</p>
`
export const DRIVER_CREDIT_DESCRIPTION = `
  <p>At time of pick up you will be required to leave a deposit to cover the value of the insurance excess. Usually it takes the form of an amount (minimum: excess+fuel+vat) being blocked on an international credit card in the name of the primary driver (cash deposits, maestro, switch visa electron and debit cards are not accepted).</p>
  <p>This company does not accept prepaid, reloadable or virtual credit cards as well as cards not having embossed (raised) characters on the front.</p>
  <p>Please follow the link and enter first 6 digits of your card number to check the type of your card: <a href="http://www.binbase.com/search.html" target="_blank">http://www.binbase.com/search.html</a></p>
  <p>The blocked amount will be returned in full at the end of the rental provided the vehicle is returned in the same condition as rented.</p>
`
export const CROSS_BORDER_POLICY_DESCRIPTION =
  'The car is supplied with a full tank of fuel. It should full on return or refueling charges will be applied.'

export const COUNTRY_DUPLICATES_ERROR = 'Each country can only be listed once'
export const MIN_MAX_AGE_ERROR = 'Min age should be less than max age'
export const YOUNG_DRIVER_SURCHARGE = 'Young Driver Surcharge'
export const YOUNG_DRIVER_SURCHARGE_TOOLTIP =
  'The data is based on the driver age ranges set in the Reservation section for your locations.'
export const GO_TO_LOCATION = 'Go to Location'
export const CAR_TYPE = 'Car type'
export const EXCESS = 'Excess'
export const DEPOSIT = 'Deposit'

export const DRIVER_REQUIREMENTS_CONTENT = {
  TITLE: 'Driver Requirements',
  LIST: [
    {
      TITLE: "Driver's license",
      DESCRIPTION:
        "When renting a car, you must provide a driver's license valid at the start of the rental and throughout the rental period. If you are traveling outside your home country, you may need an international driver's license. Please note that it is only valid when presented with the original driver's license.",
    },
    {
      TITLE: 'Young driver surcharge',
      DESCRIPTION:
        'In car rentals, a young driver is usually someone below 25 years old. Young drivers are subject to an extra surcharge and must be at least 21 in most countries.',
    },
    {
      TITLE: 'Forms of identification',
      DESCRIPTION:
        'Commonly accepted forms of identification required to rent a car include passport and identity card.',
    },
  ],
}

export const ADDED_SUCCESS = 'Your T&C have been successfully added'
export const UPDATED_SUCCESS = 'Your T&C have been successfully updated'
export const DELETED_SUCCESS = 'Your T&C have been successfully deleted'
export const DELETE_MODAL_TITLE = 'Delete T&C by Country of Residence?'
export const DELETE_MODAL_DESCRIPTION =
  'Are you sure that you want to delete this T&C by Country of Residence?'

export const TERMS_AND_CONDITIONS_FORM_VALUES = {
  COR: {
    ID: 'cor',
    LABEL: 'Country of Residence',
  },
}

export const RENTAL_INCLUDES_FORM_VALUES = {
  LOCAL_TAXES: {
    ID: 'localTaxes',
    LABEL: 'Local taxes',
  },
  INSURANCE: {
    ID: 'insurance',
    LABEL: 'Insurance',
  },
  AIRPORT_SURCHARGE: {
    ID: 'airportSurcharge',
    LABEL: 'Airport surcharge',
  },
  ROAD_ASSISTANCE: {
    ID: 'roadAssistance',
    LABEL: '24H Road Assistance',
  },
  MILLAGE: {
    ID: 'mileageType',
  },
  UNLIMITED: {
    ID: MILEAGE_TYPES.Unlimited,
    LABEL: 'Unlimited',
  },
  LIMITED: {
    ID: MILEAGE_TYPES.Limited,
    LABEL: 'Limited',
  },
  EXCEPT_CAR_TYPES: {
    ID: 'exceptCarTypes',
    LABEL: 'Except car type(s)',
  },
  PICKUP_COUNTRY: {
    ID: 'pickupCountry',
    LABEL: 'Pick-up country',
  },
  DISTANCE: {
    ID: 'distance',
    LABEL: 'Distance',
  },
  UNIT: {
    ID: 'unit',
    LABEL: 'Unit',
  },
  PERIOD: {
    ID: 'period',
    LABEL: 'Duration',
  },
}

export const RENTAL_EXCLUDES_FORM_VALUES = {
  FINES_FEES_PARKING: {
    ID: 'fines',
    LABEL: 'Fines, Toll Fees & Parking charges',
  },
  ADDITIONAL_EQUIPMENT: {
    ID: 'additionalEquipment',
    LABEL: 'Additional equipment',
  },
}

export const FUEL_POLICY_FORM_VALUES = {
  FUEL: {
    ID: 'fuelPolicy',
  },
  FULL_TO_FULL: {
    ID: FUEL_POLICY_TYPES.FullToFull,
    LABEL: 'Full to Full',
    DESCRIPTION:
      'The vehicle is supplied with a full tank of fuel. It should full on return or refueling charges will be applied.',
  },
  SAME_TO_SAME: {
    ID: FUEL_POLICY_TYPES.FullToEmpty,
    LABEL: 'Same to same',
    DESCRIPTION:
      'Same to Same means that if you pick up the car with a fixed amount of fuel in the tank, you should also return the car with the same amount of fuel.',
  },
}

export const INSURANCE_INCLUDED_FORM_VALUES = {
  INSURANCE: {
    ID: 'insurance',
  },
  INCLUDED: {
    ID: INSURANCE.Include,
    LABEL: 'Included',
  },
  EXCLUDED: {
    ID: INSURANCE.Exclude,
    LABEL: 'Excluded',
  },
}

export const CROSS_BORDER_POLICY_FORM_VALUES = {
  ALLOWED: {
    ID: 'crossBorderPolicy',
  },
  ALLOWED_TO: {
    ID: CROSS_BORDER_POLICY_TYPES.AllowedTo,
    LABEL: 'Allowed to',
  },
  NOT_ALLOWED: {
    ID: CROSS_BORDER_POLICY_TYPES.NotAllowed,
    LABEL: 'Not allowed',
  },
  COUNTRIES: {
    ID: 'countries',
  },
  COUNTRY_LIST: {
    ID: 'countryList',
    LABEL: 'Country',
    PLACEHOLDER: 'Add country',
  },
  PRICE: {
    ID: 'price',
    LABEL: 'Price for additional fee',
    PLACEHOLDER: 'Add fee',
  },
}

export const DRIVER_REQUIREMENTS_FORM_VALUES = {
  MIN_AGE: {
    ID: 'minDrivedAge',
    LABEL: 'MIN driving age',
    PLACEHOLDER: 'Enter age',
  },
  MAX_AGE: {
    ID: 'maxDrivedAge',
    LABEL: 'MAX driving age',
    PLACEHOLDER: 'Enter age',
  },
  LICENSE_YEARS: {
    ID: 'driversLicenceYear',
    LABEL: "Driver's license must be held for at least (years)",
    PLACEHOLDER: 'Enter the number of years',
  },
  LOCATION: {
    ID: 'locationContractId',
    LABEL: 'Location',
    PLACEHOLDER: 'Select location',
    SEARCH_PLACEHOLDER: 'Search for location',
  },
  FROM: {
    ID: 'from',
    LABEL: 'From',
  },
  TO: {
    ID: 'to',
    LABEL: 'To',
  },
  FEE: {
    ID: 'fee',
    LABEL: 'Fee',
  },
  MAX_PRICE: {
    ID: 'maxPrice',
    LABEL: 'Max price',
  },
  PER_RENTAL: {
    ID: DriverAgeRangeType.PerRental,
    LABEL: RADIO_BUTTON_LABELS.RENT,
  },
  PER_DAY: {
    ID: DriverAgeRangeType.PerDay,
    LABEL: RADIO_BUTTON_LABELS.DAY,
  },
}
