import { useDispatch, useSelector } from 'react-redux'
import { FilterProps } from 'ui/atoms/Filter/types'
import { toggleFilter } from 'utils/filters'
import { selectAllFiltersByType, setFilterItems } from 'redux/filters/slice'
import { sortingSelector } from 'redux/sorting/selectors'
import { setSorting } from 'redux/sorting/slice'
import { filterItemsSelector } from 'redux/filters/selectors'
import { FiltersState } from 'redux/filters/types'
import { SortingState } from 'redux/sorting/types'
import {
  ITEMS_WITH_SEARCH,
  ITEMS_WITH_SORTING,
  ITEMS_WITHOUTH_FILTERING,
} from 'constants/filters'

export const useFilterSettings = (
  filterKey: keyof FiltersState['filters'],
  handleChangePage?: (page: number) => void,
  sortingKey?: keyof SortingState['sortingData']
) => {
  const dispatch = useDispatch()
  const sortingData = useSelector(sortingSelector)
  const filterItems = useSelector(filterItemsSelector)

  const getFilterSettings = (key: string): FilterProps => ({
    onSelectAllItems: (isAllSelected) =>
      dispatch(
        selectAllFiltersByType({ filterKey, key, isSelect: !isAllSelected })
      ),
    listItemWidth: '240px',
    withSearch: ITEMS_WITH_SEARCH.includes(key),
    withSorting: ITEMS_WITH_SORTING.includes(key),
    withFiltering: !ITEMS_WITHOUTH_FILTERING.includes(key),
    onReset: () =>
      dispatch(selectAllFiltersByType({ filterKey, key, isSelect: false })),
    onSort: (type) => dispatch(setSorting({ filterKey, item: { key, type } })),
    sortingType:
      sortingKey && sortingData[sortingKey].find((el) => el.key === key)?.type,
    headItem: key,
    items: filterItems[filterKey][key]?.map((el) => ({
      value: el.value,
      selected: el.selected,
      onClick: () => {
        handleChangePage?.(1)
        dispatch(
          setFilterItems({
            items: {
              ...filterItems[filterKey],
              ...toggleFilter(key, el.value, filterItems[filterKey]),
            },
            key: filterKey,
          })
        )
      },
    })),
  })

  return getFilterSettings
}
