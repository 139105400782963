import React, { RefObject, forwardRef, useRef } from 'react'
import { joinClasses } from 'utils/joinClasses'
import * as icons from '../../icons'
import { InputProps } from './types'
import { Label } from '@frontend/design_system'
import { Typography } from '../Typography'
import { FormAlertLabel } from '../FormAlertLabel'
import styles from './styles.module.scss'

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      placeholder,
      size = 'small',
      typographyName = 'body2WMedium',
      iconLeft = '',
      iconRight = '',
      iconOnClickRight,
      iconSize,
      error = '',
      type = 'text',
      readonly = false,
      onChange,
      onClick,
      onBlur,
      label,
      autoComplete,
      borderVariant = 'grey',
      innerLabel,
      maxLength,
      isActive = false,
      isError,
      borderRadius,
      iconSpace = 'medium',
      leftOffsetVariant = 'medium',
      placeholderColorVariant = 'primary',
      noBorder,
      leftEl,
      spaceForError = 'default',
      paddingLeft,
      caretColorVariant = 'primary',
      isRequired,
      iconColor,
      autoFocus,
      hint,
      isAlertBottomOffset = true,
      disabled = false,
      id,
      tooltip,
      labelRight,
      alignText,
    },
    ref
  ) => {
    const initInputRef = useRef<HTMLInputElement>(null)
    const inputRef = ref || initInputRef

    const InputIconLeft = icons[iconLeft as keyof typeof icons]
    const InputIconRight = icons[iconRight as keyof typeof icons]
    const heightClass = `input-${size}`

    const handlerOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value)
    }

    const handlerRightIcon = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      if (iconOnClickRight) {
        iconOnClickRight()
      }
    }

    const handleClick = () => {
      if (disabled) return
      onClick?.()
    }

    const onFocus = () =>
      (inputRef as RefObject<HTMLInputElement>).current?.focus()

    return (
      <div
        className={joinClasses(
          styles.input,
          styles[heightClass],
          [styles[`input-align-${alignText}`], alignText],
          [styles['input-error'], !!error || isError],
          [styles['input-with-icon'], !!InputIconLeft || !!InputIconRight],
          [styles['input-with-icon-left'], !!InputIconLeft],
          [styles['input-with-icon-right'], !!InputIconRight],
          [styles['input-with-icon-right-click'], iconOnClickRight],
          [styles['input-innerText'], innerLabel],
          [styles['input-active'], isActive],
          styles[`input-border-${borderVariant}`],
          styles[`input-icon-space-${iconSpace}`],
          styles[`input-placeholder-color-${placeholderColorVariant}`],
          [styles[`input-no-border-${noBorder}`], noBorder],
          styles[`input-error-space-${spaceForError}`],
          styles[`input-caret-color-${caretColorVariant}`]
        )}
      >
        {label && (
          <Label
            typographyName="Button2"
            label={label}
            isRequired={isRequired}
            tooltip={tooltip}
            labelRight={labelRight}
          />
        )}
        <div
          className={joinClasses(styles['input-inner'], [
            styles['input-clicked'],
            !!onClick,
          ])}
          onClick={handleClick}
        >
          {innerLabel && (
            <Typography
              Tag="div"
              name={typographyName}
              color="grey500"
              className={joinClasses(styles['input-innerText-label'], [
                styles['no-left-icon'],
                !iconLeft,
              ])}
              onClick={onFocus}
            >
              {innerLabel}
            </Typography>
          )}
          {leftEl}
          {InputIconLeft && (
            <div className={styles['input-svg-left']} onClick={onFocus}>
              <InputIconLeft size={iconSize} color={iconColor} />
            </div>
          )}
          {InputIconRight && (
            <div
              className={styles['input-svg-right']}
              onClick={handlerRightIcon}
            >
              <InputIconRight size={iconSize} color={iconColor} />
            </div>
          )}
          <input
            ref={inputRef}
            value={value}
            type={type}
            placeholder={disabled ? '' : placeholder}
            className={joinClasses(
              [styles[typographyName], typographyName],
              styles[`input-left-offset-${leftOffsetVariant}`],
              [styles.disabled, disabled]
            )}
            onChange={handlerOnChange}
            autoComplete={autoComplete}
            maxLength={maxLength}
            disabled={disabled}
            readOnly={readonly}
            style={{
              borderRadius,
              paddingLeft,
            }}
            autoFocus={autoFocus}
            onBlur={onBlur}
            id={id}
          />
        </div>
        {(error || hint) && (
          <div className={styles['input-error-label']}>
            <FormAlertLabel
              label={error || hint}
              position={
                spaceForError === 'none' || spaceForError === 'auto'
                  ? 'static'
                  : 'absolute'
              }
              type={error ? 'error' : 'hint'}
              isBottomOffset={isAlertBottomOffset}
            />
          </div>
        )}
      </div>
    )
  }
)
