import { Container } from 'ui/molecules/Container'
import { MENU_NAMES } from 'texts/menuNames'
import { isOperatorSelector } from 'redux/login/selectors'
import { useAppSelector } from 'redux/hooks'
import { Breadcrumb } from 'ui/atoms/Breadcrumbs/types'
import styles from './styles.module.scss'
import { Navigation } from './components/Navigation'
import { ReactNode, useState } from 'react'
import { HistoryList } from '../HistoryList'
import { TERM_CONDITIONS_TYPES } from 'constants/termsAndConditions'
import { ITermsAndConditionsItem } from 'api/terms/types'

export const RENTAL_INCLUDES_TYPE = 2

interface TermsAndConditionsProps {
  breadcrumbs?: Breadcrumb[]
  current: string
  children: ReactNode | ((data?: ITermsAndConditionsItem) => ReactNode)
  termConditionTypesId?: TERM_CONDITIONS_TYPES
}

export const TermsAndConditionsContent = ({
  breadcrumbs,
  current,
  children,
  termConditionTypesId,
}: TermsAndConditionsProps) => {
  const isOperator = useAppSelector(isOperatorSelector)
  const [editData, setEditData] = useState<ITermsAndConditionsItem>()

  return (
    <Container
      title={MENU_NAMES.TERMS_AND_CONDITIONS}
      breadcrumbList={breadcrumbs}
      currentPageLabel={MENU_NAMES.TERMS_AND_CONDITIONS}
      withNavigation={isOperator}
    >
      <div className={styles.container}>
        <Navigation current={current} />
        <div className={styles.content}>
          {typeof children === 'function' ? children(editData) : children}
          {termConditionTypesId && (
            <HistoryList
              termConditionTypesId={termConditionTypesId}
              setEditData={setEditData}
              editDataId={editData?.id}
            />
          )}
        </div>
      </div>
    </Container>
  )
}
