import { Typography, Button } from '@frontend/design_system'
import styles from './styles.module.scss'
import { REVIEW } from 'texts/notifications'
import { notificationsApi } from 'api'
import { useApiRequest } from 'hooks/useApiRequest'
import { useNavigate } from 'react-router-dom'
import { URLS } from 'constants/urls'

interface NotificationProps {
  onToggle: () => void
  title: string
  message: string
  companyName: string
  id: number
  companyId: number
  forceUpdateCount: () => void
}

export const Notification = ({
  onToggle,
  title,
  message,
  companyName,
  companyId,
  forceUpdateCount,
}: NotificationProps) => {
  const navigate = useNavigate()
  const { apiRequest: deleteNotification, loading } = useApiRequest(
    () => notificationsApi.deleteNotification(companyId),
    undefined,
    undefined,
    {
      isThrowError: false,
      withoutNotification: false,
    },
    false
  )

  const onReview = async () => {
    const res = await deleteNotification()

    if (res) {
      navigate(`${URLS.PROFILE}${URLS.PROFILE_LIST}/${companyId}/edit`)
      forceUpdateCount()
      onToggle()
    }
  }

  return (
    <div className={styles.notification}>
      <Typography
        name="caption1WBold"
        className={styles.supplier}
        color="blue700"
      >
        {companyName}
      </Typography>
      <Typography name="body1WBold" className={styles.status}>
        {title}
      </Typography>
      <Typography name="body2WMedium" className={styles.message}>
        {message}
      </Typography>
      <Button
        onClick={onReview}
        variant="secondary-border"
        label={REVIEW}
        typographyName="body1WMedium"
        size="large"
        loading={loading}
      />
    </div>
  )
}
