import { Input as InputDS, OriginButton } from '@frontend/design_system'
import { Input } from 'ui/atoms/Input'
import { InfoIcon } from 'ui/icons'
import { Tooltip } from 'ui/atoms/Tooltip'
import arrowRight from 'assets/img/arrowRight.webp'
import { joinClasses } from 'utils/joinClasses'
import {
  LOCATION_TABS,
  NEXT_BUTTON_TITLE,
  LOCATION_ADDING_FORM_VALUES,
} from 'texts/locationDetails'
import { RESERVATION_FORM_FIELDS } from '../../constants'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { VEHICLE_DETAILS } from 'texts/vehicleDetails'
import { DriveAgeRanges } from 'ui/components/DriverAgeRanges'
import { FormContext } from 'ui/molecules/Form/Form'
import { ContextType } from 'ui/molecules/Form/types'
import { Typography } from 'ui/atoms/Typography'
import { getRequiredRule } from 'utils/getValidationRules'
import { useMemo, useContext } from 'react'
import { useReservationRules } from './hooks/useReservationRules'
import { ReservationFormProps } from './types'
import { allCharactersExceptNumbers } from 'constants/regex'
import { scrollTop } from 'utils/scrollTop'
import styles from './styles.module.scss'

export const ReservationForm = ({
  driverAgeRangeError,
  resetDriverAgeError,
  formValues,
  changeActiveKey,
  validateDriverAgeRange,
  validateWorkingHours,
}: ReservationFormProps) => {
  const rules = useReservationRules(formValues)
  const { values, checkValidation } = useContext<ContextType>(FormContext)

  const validationRules = (message: string) =>
    useMemo(() => getRequiredRule(message), [])

  const goNextTab = () => {
    const { newErrors } = checkValidation()
    const preparedWorkingHours = validateWorkingHours()
    const error = validateDriverAgeRange(values)

    const filteredErrors = Object.keys(RESERVATION_FORM_FIELDS).reduce(
      (acc: Record<string, unknown>, item) => {
        if (newErrors[item]) {
          acc[item] = newErrors[item]
        }
        return acc
      },
      {}
    )

    if (
      !Object.keys(filteredErrors).length &&
      !error &&
      preparedWorkingHours.length
    ) {
      scrollTop()
      changeActiveKey(LOCATION_TABS.WAITING_HOURS)
    }
  }

  const renderTooltip = (value: string) => (
    <div className={styles['form-tooltip-wrapper']}>
      <div className={styles['form-tooltip']}>
        <Tooltip
          size="secondary"
          arrowPosition="right"
          popupContent={<Typography name="Button2">{value}</Typography>}
          placement="bottom"
        >
          <InfoIcon color="blue700" size="small" />
        </Tooltip>
      </div>
    </div>
  )

  return (
    <>
      <div className={styles['form-items-row']}>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.ID}
          className={joinClasses(styles['form-item'], styles['form-item-left'])}
          rules={rules.minimumPeriodDay}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={
                LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.PLACEHOLDER
              }
              size="large"
              error={error}
              spaceForError="auto"
              isRequired
              onChange={(value: string) =>
                onChange(value.replace(allCharactersExceptNumbers, ''))
              }
              label={LOCATION_ADDING_FORM_VALUES.MINIMUM_PERIOD_DAY.LABEL}
            />
          )}
        </FormItem>
        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.MAXIMUM_PERIOD_DAY.ID}
          className={styles['form-item']}
          rules={rules.minMaxPeriodDay}
        >
          {({ value, error, onChange }) => (
            <Input
              value={value}
              placeholder={
                LOCATION_ADDING_FORM_VALUES.MAXIMUM_PERIOD_DAY.PLACEHOLDER
              }
              size="large"
              type="number"
              error={error}
              spaceForError="auto"
              onChange={(value: string) =>
                onChange(value.replace(allCharactersExceptNumbers, ''))
              }
              label={LOCATION_ADDING_FORM_VALUES.MAXIMUM_PERIOD_DAY.LABEL}
            />
          )}
        </FormItem>
      </div>
      <FormItem
        id={LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.ID}
        className={joinClasses(styles['form-item'], styles['form-item-left'])}
        rules={validationRules(
          LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.REQUIRED_MESSAGE
        )}
      >
        {({ value, error, onChange }) => (
          <InputDS
            value={value}
            placeholder={
              LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.PLACEHOLDER
            }
            size="large"
            error={error}
            iconRight={renderTooltip(
              LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.TOOLTIP
            )}
            spaceForError="auto"
            onChange={(value: string) =>
              onChange(value.replace(allCharactersExceptNumbers, ''))
            }
            isRequired
            label={LOCATION_ADDING_FORM_VALUES.START_RESERVATION_HOUR.LABEL}
          />
        )}
      </FormItem>
      <div>
        <Typography
          className={styles['driver-age-range-header']}
          markColor="red600Master"
          Tag="h5"
          name="H5"
        >
          {VEHICLE_DETAILS.DRIVER_AGE_RANGE_TITLE} <mark>*</mark>
        </Typography>

        <FormItem
          id={LOCATION_ADDING_FORM_VALUES.DRIVER_AGE_RANGE.ID}
          rules={rules.dailyRangeRules}
        >
          {({ onChange, value }) => (
            <DriveAgeRanges
              driverAgeRangeData={value}
              error={driverAgeRangeError}
              onDriverAgeRangeDataChange={(value) => {
                onChange(value)
                resetDriverAgeError()
              }}
              FooterComponent={
                <div className={styles['form-next-button']}>
                  <OriginButton
                    variant="link"
                    size="large"
                    onClick={goNextTab}
                    label={NEXT_BUTTON_TITLE}
                    typographyName="Button1"
                    iconRight={<img src={arrowRight} width={16} height={16} />}
                  />
                </div>
              }
            />
          )}
        </FormItem>
      </div>
    </>
  )
}
