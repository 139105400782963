import { Input } from '@frontend/design_system'
import { TrashIconWithBg } from 'ui/icons'
import { DriverAgeRangeType } from 'api/fleet/types'
import { DriverAgeRangeProps } from './types'
import { onlyNumbersWithOneDotRegex } from 'constants/regex'
import styles from './styles.module.scss'
import { DRIVER_AGE_RANGE } from 'texts/driverAgeRange'

export const DriverAgeRange = ({
  range,
  handleChange,
  index,
  rangeType,
  handleRemoveRange,
}: DriverAgeRangeProps) => {
  const onChange = (value: string, key: string) => {
    const newValue = value.replace(onlyNumbersWithOneDotRegex, '')
    handleChange(index, { ...range, [key]: newValue })
  }

  const isPerRentalRange = rangeType === DriverAgeRangeType.PerRental

  return (
    <div className={styles.range}>
      <Input
        size="large"
        placeholder={DRIVER_AGE_RANGE.START.PLACEHOLDER}
        value={String(range.ageStart)}
        type="integer"
        onChange={(value: string) => onChange(value, 'ageStart')}
        spaceForError="auto"
        label={DRIVER_AGE_RANGE.START.LABEL}
      />
      <Input
        size="large"
        placeholder={DRIVER_AGE_RANGE.END.PLACEHOLDER}
        value={String(range.ageEnd)}
        type="integer"
        onChange={(value: string) => onChange(value, 'ageEnd')}
        spaceForError="auto"
        label={DRIVER_AGE_RANGE.END.LABEL}
      />
      <Input
        size="large"
        placeholder={DRIVER_AGE_RANGE.FEE.PLACEHOLDER}
        value={String(range.price)}
        onChange={(value: string) => onChange(value, 'price')}
        spaceForError="auto"
        label={DRIVER_AGE_RANGE.FEE.LABEL}
      />
      <Input
        size="large"
        value={isPerRentalRange ? '' : range.maxPrice}
        placeholder={
          isPerRentalRange ? '' : DRIVER_AGE_RANGE.MAX_PRICE.PLACEHOLDER
        }
        onChange={(value: string) => onChange(value, 'maxPrice')}
        spaceForError="auto"
        readonly={isPerRentalRange}
        isDisabled={isPerRentalRange}
        label={DRIVER_AGE_RANGE.MAX_PRICE.LABEL}
      />
      <TrashIconWithBg
        color="blue700"
        size="large"
        className={styles.icon}
        onClick={handleRemoveRange}
      />
    </div>
  )
}
