import { URLS } from './../../../constants/urls'
import userIconSrc from 'assets/icons/grey400/grey400_profile_20x20.webp'
import locationIconSrc from 'assets/icons/grey400/grey400_location_20x20.webp'
import archiveIconSrc from 'assets/icons/grey400/grey400_archive_20x20.webp'
import applicationsIconSrc from 'assets/icons/grey400/grey400_applications_20x20.webp'
import activityHistoryIconSrc from 'assets/icons/grey400/grey400_activityHistory_20x20.webp'
import carIconSrc from 'assets/icons/grey400/grey400_car_20x20.webp'
import equipmentIconSrc from 'assets/icons/grey400/grey400_equipment_20x20.webp'
import priceListIconSrc from 'assets/icons/grey400/grey400_priceList_20x20.webp'
import stopSaleIconSrc from 'assets/icons/grey400/grey400_stopSale_20x20.webp'
import dropoffFeeIconSrc from 'assets/icons/grey400/grey400_droppoffFee_20x20.webp'
import termsIconSrc from 'assets/icons/grey400/grey400_terms_20x20.webp'
import dashboardIconSrc from 'assets/icons/grey400/grey400_dashboard_20x20.webp'
import applicationsArchiveIconSrc from 'assets/icons/grey400/grey400_applicationArchive_20x20.webp'
import { MENU_NAMES } from 'texts/menuNames'
import { MenuItemsType, SubmenuItemsType } from './types'

export const MENU_PATHS = {
  [MENU_NAMES.ACTIVITY_HISTORY]: URLS.ACTIVITY_HISTORY,
  [MENU_NAMES.PROFILE_LIST.NAME]: URLS.PROFILE + URLS.PROFILE_LIST,
  [MENU_NAMES.PROFILE_ARCHIVE.NAME]: URLS.PROFILE + URLS.ARCHIVE,
  [MENU_NAMES.PROFILE_DETAILS.NAME]: URLS.PROFILE + URLS.DETAILS,
  [MENU_NAMES.VEHICLES]: URLS.FLEET + URLS.VEHICLES,
  [MENU_NAMES.EQUIPMENTS]: URLS.FLEET + URLS.EQUIPMENTS,
  [MENU_NAMES.PRICE_LISTS]: URLS.FLEET + URLS.PRICE_LIST,
  [MENU_NAMES.STOP_SALES]: URLS.FLEET + URLS.STOP_SALES,
  [MENU_NAMES.LOCATIONS.NAME]: URLS.LOCATIONS + URLS.LIST_OF_LOCATIONS,
  [MENU_NAMES.TERMS_AND_CONDITIONS]: URLS.TERMS_AND_CONDITIONS,
  [MENU_NAMES.APPLICATIONS]: URLS.APPLICATIONS + URLS.APPLICATIONS_LIST,
  [MENU_NAMES.APPLICATION_ARCHIVE]:
    URLS.APPLICATIONS + URLS.APPLICATIONS_ARCHIVE,
  [MENU_NAMES.DASHBOARD]: URLS.DASHBOARD,
  [MENU_NAMES.DROP_OFF_FEE]: URLS.DROP_OFF_FEE,
  [MENU_NAMES.BOOKING_HISTORY]: URLS.BOOKING_HISTORY,
  [MENU_NAMES.PROFILE_PENDING]: URLS.PROFILE + URLS.PENDING,
}

export const OPERATOR_MENU_ITEMS: MenuItemsType = [
  {
    name: MENU_NAMES.PROFILE_LIST.NAME,
    path: MENU_PATHS[MENU_NAMES.PROFILE_LIST.NAME],
    icon: userIconSrc,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.PROFILE_ARCHIVE.NAME,
    icon: archiveIconSrc,
    path: MENU_PATHS[MENU_NAMES.PROFILE_ARCHIVE.NAME],
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.APPLICATIONS,
    icon: applicationsIconSrc,
    path: MENU_PATHS[MENU_NAMES.APPLICATIONS],
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.APPLICATION_ARCHIVE,
    path: MENU_PATHS[MENU_NAMES.APPLICATION_ARCHIVE],
    icon: applicationsArchiveIconSrc,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.ACTIVITY_HISTORY,
    path: MENU_PATHS[MENU_NAMES.ACTIVITY_HISTORY],
    icon: activityHistoryIconSrc,
    withSubmenu: false,
  },
]

export const MENU_ITEM_HEIGHT = 45

export const OPERATOR_SUBMENU_ITEMS: SubmenuItemsType = {
  APPLICATIONS: [
    {
      name: MENU_NAMES.APPLICATION_ARCHIVE,
      path: MENU_PATHS[MENU_NAMES.APPLICATIONS],
    },
    {
      name: MENU_NAMES.APPLICATION_ARCHIVE,
      path: MENU_PATHS[MENU_NAMES.APPLICATION_ARCHIVE],
    },
  ],
}

export const SUPPLIER_MENU_ITEMS = (isApproved?: boolean) => [
  {
    name: MENU_NAMES.DASHBOARD,
    icon: dashboardIconSrc,
    path: MENU_PATHS[MENU_NAMES.DASHBOARD],
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.PROFILE_DETAILS.NAME,
    icon: userIconSrc,
    withSubmenu: false,
    path: isApproved
      ? MENU_PATHS[MENU_NAMES.PROFILE_DETAILS.NAME]
      : MENU_PATHS[MENU_NAMES.PROFILE_PENDING],
  },
  {
    name: MENU_NAMES.BOOKING_HISTORY,
    icon: activityHistoryIconSrc,
    withSubmenu: false,
    path: MENU_PATHS[MENU_NAMES.BOOKING_HISTORY],
  },
  {
    name: MENU_NAMES.LOCATIONS.NAME,
    path: MENU_PATHS[MENU_NAMES.LOCATIONS.NAME],
    icon: locationIconSrc,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.VEHICLES,
    path: MENU_PATHS[MENU_NAMES.VEHICLES],
    icon: carIconSrc,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.EQUIPMENTS,
    path: MENU_PATHS[MENU_NAMES.EQUIPMENTS],
    icon: equipmentIconSrc,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.PRICE_LISTS,
    path: MENU_PATHS[MENU_NAMES.PRICE_LISTS],
    icon: priceListIconSrc,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.DROP_OFF_FEE,
    path: MENU_PATHS[MENU_NAMES.DROP_OFF_FEE],
    icon: dropoffFeeIconSrc,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.STOP_SALES,
    path: MENU_PATHS[MENU_NAMES.STOP_SALES],
    icon: stopSaleIconSrc,
    withSubmenu: false,
  },
  {
    name: MENU_NAMES.TERMS_AND_CONDITIONS,
    path: `${URLS.TERMS_AND_CONDITIONS}${URLS.RENTAL_INCLUDES}`,
    icon: termsIconSrc,
    withSubmenu: false,
  },
]
