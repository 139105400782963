import { useEffect, useState } from 'react'
import { startOfMonth, endOfMonth, subMonths, format } from 'date-fns'
import { INIT_FINANCIAL_PERIOD } from '../DashboardList/constants'
import { STOP_SALES_DATE_FORMAT } from 'constants/date'
import { dashboardApi, DashboardApiTypes } from 'api'
import { useApiRequest } from 'hooks/useApiRequest'

export const useFinancialData = (companyId: string | number) => {
  const today = new Date()
  const startFinancialDate = startOfMonth(
    subMonths(today, INIT_FINANCIAL_PERIOD)
  )
  const endFinancialDate = endOfMonth(subMonths(today, INIT_FINANCIAL_PERIOD))

  const [perUnitData, setPerInitData] =
    useState<DashboardApiTypes.RevenuePerUnitResponse | null>(null)
  const [perDaysData, setPerDaysData] =
    useState<DashboardApiTypes.RevenuePerDayResponse | null>(null)

  const [initialFinancialDate, setInitialFinancialDate] = useState({
    start: startFinancialDate,
    end: endFinancialDate,
  })
  const [selectedFinancialRange, setSelectedFinancialRange] = useState({
    from: startFinancialDate,
    to: endFinancialDate,
  })

  const revenuePerDayRequest = useApiRequest((companyId, dateStart, dateEnd) =>
    dashboardApi.getRevenuePerDay({
      companyId: String(companyId),
      dateStart,
      dateEnd,
    })
  )

  const revenuePerUnitRequest = useApiRequest((companyId, dateStart, dateEnd) =>
    dashboardApi.getRevenuePerUnit({
      companyId: String(companyId),
      dateStart,
      dateEnd,
    })
  )

  useEffect(() => {
    if (selectedFinancialRange.from && selectedFinancialRange.to && companyId) {
      const dateStart = format(
        new Date(selectedFinancialRange.from),
        STOP_SALES_DATE_FORMAT
      )
      const dateEnd = format(
        new Date(selectedFinancialRange.to),
        STOP_SALES_DATE_FORMAT
      )
      const getRevenuePerUnit = async () => {
        const response = await revenuePerUnitRequest.apiRequest(
          companyId,
          dateStart,
          dateEnd
        )
        if (response && response.data) {
          const sorted = response.data.items?.sort((a, b) =>
            a.carType.localeCompare(b.carType)
          )
          setPerInitData({ ...response.data, items: sorted })
        }
      }

      const getRevenuePerDays = async () => {
        const response = await revenuePerDayRequest.apiRequest(
          companyId,
          dateStart,
          dateEnd
        )
        if (response && response.data) {
          setPerDaysData(response.data)
        }
      }

      getRevenuePerUnit()
      getRevenuePerDays()
    }
  }, [companyId, selectedFinancialRange.from, selectedFinancialRange.to])

  return {
    perUnitData,
    perDaysData,
    initialFinancialDate,
    setInitialFinancialDate,
    selectedFinancialRange,
    setSelectedFinancialRange,
  }
}
