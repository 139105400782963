const statusColumnIndex = 2
const prepaidExtrasColumnIndex = 13
const supplierExtrasColumnIndex = 14
const extrasColumnWidth = '240px'
const statusColumnWidth = '80px'
const itemsCount = 17

export const prepareColumnsWidth = () => {
  const columns = new Array(itemsCount).fill('')

  columns[statusColumnIndex] = statusColumnWidth
  columns[prepaidExtrasColumnIndex] = extrasColumnWidth
  columns[supplierExtrasColumnIndex] = extrasColumnWidth
  return columns
}
