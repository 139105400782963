import { PaymentPeriod } from 'api/fleet/types'
import { FormItem } from 'ui/molecules/Form/components/FormItem'
import { Input } from 'ui/atoms/Input'
import { EQUIPMENT_DETAILS_FORM_VALUES } from 'texts/equipmentDetails'
import { PriceFormProps } from './types'
import { EMPTY_RULES } from 'constants/form'
import { usePriceValidation } from '../PriceForm/hooks/usePriceValidation'
import styles from '../../styles.module.scss'
import { onlyNumbersWithOneDotRegex } from 'constants/regex'

export const PriceForm = ({ formValues }: PriceFormProps) => {
  const rules = usePriceValidation(formValues)

  return (
    <div className={styles['form-items-row']}>
      <FormItem
        id={EQUIPMENT_DETAILS_FORM_VALUES.PRICE.ID}
        className={styles['form-item']}
        rules={rules.price}
      >
        {({ value, error, onChange }) => (
          <Input
            size="large"
            error={error}
            spaceForError="auto"
            placeholder={EQUIPMENT_DETAILS_FORM_VALUES.PRICE.PLACEHOLDER}
            onChange={(value: string) =>
              onChange(value.replace(onlyNumbersWithOneDotRegex, ''))
            }
            label={EQUIPMENT_DETAILS_FORM_VALUES.PRICE.LABEL}
            value={value}
            isRequired
          />
        )}
      </FormItem>
      <FormItem
        id={EQUIPMENT_DETAILS_FORM_VALUES.MAX_AMOUNT.ID}
        className={styles['form-item']}
        rules={
          Number(formValues.priceFor) !== PaymentPeriod.Rent
            ? rules.maxPrice
            : EMPTY_RULES
        }
      >
        {({ value, error, onChange }) => (
          <Input
            size="large"
            error={error}
            spaceForError="auto"
            disabled={Number(formValues.priceFor) === PaymentPeriod.Rent}
            placeholder={EQUIPMENT_DETAILS_FORM_VALUES.MAX_AMOUNT.PLACEHOLDER}
            onChange={(value: string) =>
              onChange(value.replace(onlyNumbersWithOneDotRegex, ''))
            }
            label={EQUIPMENT_DETAILS_FORM_VALUES.MAX_AMOUNT.LABEL}
            value={value}
            isRequired={Number(formValues.priceFor) !== PaymentPeriod.Rent}
          />
        )}
      </FormItem>
    </div>
  )
}
