import { LocationsApiTypes } from 'api'
import { Typography } from '@frontend/design_system'
import { CheckedIcon } from 'ui/icons'
import { WorkingHoursBlockProps } from './types'
import { formatTime } from 'utils/dateFormat'
import styles from './styles.module.scss'

export const WorkingHoursBlock = ({ workingHours }: WorkingHoursBlockProps) => {
  const renderItem = (item: LocationsApiTypes.WorkingHour) => {
    const allCheck = item.dayStart && item.dayEnd

    return (
      <div className={styles['working-item-info']} key={item.dayOfWeek}>
        <div className={styles['working-item-info-icon']}>
          <CheckedIcon
            color={allCheck ? 'green700Master' : 'grey500'}
            size="medium"
          />
        </div>
        <div>
          <Typography
            name="body1WBold"
            className={styles['working-item-info-label']}
          >
            {item.dayOfWeek}:
          </Typography>
          <Typography
            name="body1WMedium"
            className={styles['working-item-info-value']}
          >
            {item.dayStart && item.dayEnd
              ? `${formatTime(item.dayStart)} - ${formatTime(item.dayEnd)}`
              : '--:--'}
            {' | '}
            {item.dayStart2 && item.dayEnd2
              ? `${formatTime(item.dayStart2)} - ${formatTime(item.dayEnd2)}`
              : '--:--'}
          </Typography>
        </div>
      </div>
    )
  }

  return <div>{workingHours.map((item) => renderItem(item))}</div>
}
