import React, { useState, useEffect, useRef, useCallback } from 'react'
import { joinClasses } from 'utils/joinClasses'
import { useDispatch } from 'react-redux'
import { Typography } from '../Typography'
import { getBase64 } from './utils'
import { CustomFile, UploadFileProps } from './types'
import { DeleteIcon } from 'ui/icons'
import { useDidUpdate } from 'hooks/useDidUpdate'
import { LOGO_HEIGHT, LOGO_WIDTH, MAX_FILES_SIZE } from 'constants/files'
import { setNotificationMessage } from 'redux/notifications/slice'
import {
  UPLOAD_FILE_DIMENSION_ERROR,
  UPLOAD_FILE_SIZE_ERROR,
} from 'texts/uiTexts'
import styles from './styles.module.scss'

export const UploadFile = ({
  formats,
  onUpload,
  multiple,
  initFiles = [],
  label,
  error,
  disabled,
  renderConfirmationModal,
}: UploadFileProps) => {
  const [dragging, setDragging] = useState(false)
  const [files, setFiles] = useState(initFiles)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const drag = useRef<HTMLDivElement>(null)
  const drop = useRef<HTMLDivElement>(null)
  const input = useRef<HTMLInputElement>(null)

  const dispatch = useDispatch()

  useDidUpdate(() => {
    if (files) {
      onUpload(files)
    }
  }, [files])

  const loadReaderEvent = (
    event: ProgressEvent<FileReader>,
    resolve: (value: boolean | PromiseLike<boolean>) => void
  ) => {
    const _loadedImageUrl = event.target?.result
    const image = document.createElement('img')
    image.src = _loadedImageUrl as string

    image.addEventListener('load', () => {
      const { width, height } = image

      if (width !== LOGO_WIDTH || height !== LOGO_HEIGHT) {
        resolve(false)
      }

      resolve(true)
    })
  }

  const checkImageDimension = (file: CustomFile): Promise<boolean> =>
    new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.addEventListener('load', (event) =>
        loadReaderEvent(event, resolve)
      )
    })

  const handleUpload = async (newFiles: CustomFile[] | FileList) => {
    if (!multiple && files?.length) {
      return
    }

    let validFiles = Array.from(newFiles)
    if (formats) {
      validFiles = validFiles.filter((file) =>
        formats.some((format) => file.name.toLowerCase().endsWith(format))
      )
    }

    const allFiles = [...files, ...validFiles]
    const fullSize = allFiles.reduce((size, file) => size + file.size, 0)

    if (fullSize > MAX_FILES_SIZE) {
      dispatch(
        setNotificationMessage({
          notificationMessage: UPLOAD_FILE_SIZE_ERROR,
          type: 'error',
        })
      )
      return
    }

    if (!multiple) {
      const res = await checkImageDimension(validFiles[0])
      if (!res) {
        dispatch(
          setNotificationMessage({
            notificationMessage: UPLOAD_FILE_DIMENSION_ERROR,
            type: 'error',
          })
        )
        return
      }
    }

    if (validFiles?.length) {
      for (const validFile of validFiles) {
        const file = validFile as CustomFile
        file.url = await getBase64(file)
      }

      setFiles((prevFiles) => [...prevFiles, ...validFiles])
    }
  }

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleDrop = useCallback(
    (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()

      setDragging(false)

      handleUpload(e.dataTransfer ? [...e.dataTransfer.files] : [])
    },
    [files]
  )

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target !== drag.current) {
      setDragging(true)
    }
    if (input.current) {
      input.current.value = ''
    }
  }

  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (e.target === drag.current) {
      setDragging(false)
    }
  }

  const handleSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleUpload(e.target.files)
    }
    if (input.current) {
      input.current.value = ''
    }
  }

  const handleDelete = () => {
    renderConfirmationModal ? setIsConfirmationModalOpen(true) : setFiles([])
  }

  const openFileDialog = () => {
    if (input.current && !disabled) {
      input.current.click()
    }
  }

  useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener('dragover', handleDragOver)
      drop.current.addEventListener('drop', handleDrop)
      drop.current.addEventListener('dragenter', handleDragEnter)
      drop.current.addEventListener('dragleave', handleDragLeave)

      return () => {
        if (drop.current) {
          drop.current.removeEventListener('dragover', handleDragOver)
          drop.current.removeEventListener('drop', handleDrop)
          drop.current.removeEventListener('dragenter', handleDragEnter)
          drop.current.removeEventListener('dragleave', handleDragLeave)
        }
      }
    }
  }, [handleDrop])

  const handleModalConfirm = () => {
    setFiles([])
    setIsConfirmationModalOpen(false)
  }

  const handleModalClose = () => {
    setIsConfirmationModalOpen(false)
  }

  return (
    <>
      {isConfirmationModalOpen &&
        renderConfirmationModal?.(handleModalClose, handleModalConfirm)}
      <div className={styles.upload}>
        {!disabled && !!files.length && (
          <DeleteIcon
            size="large"
            className={styles['delete-icon']}
            onClick={handleDelete}
          />
        )}
        {files?.map(({ url }, index: number) => {
          const key = index
          return (
            <div key={key} className={styles['upload-preview']}>
              <img src={url} alt="preview" />
            </div>
          )
        })}
        {(!files?.length || multiple) && (
          <div
            ref={drop}
            onClick={openFileDialog}
            className={joinClasses(
              styles['upload-box'],
              [styles.dragging, dragging],
              [styles.error, !!error],
              [styles.disabled, disabled]
            )}
          >
            <input
              ref={input}
              type="file"
              accept={
                formats
                  ? formats.map((format) => `.${format}`).join(', ')
                  : undefined
              }
              onChange={handleSelectFiles}
            />
            {!disabled && (
              <Typography
                name="Subtitle4"
                color="grey400"
                className={styles.label}
              >
                {label}
              </Typography>
            )}
            {dragging && (
              <div ref={drag} className={styles['upload-overlay']} />
            )}
          </div>
        )}
      </div>
    </>
  )
}
